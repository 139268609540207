import React from "react";
import { GRPantheonRankingIndex } from "../../GRPantheonRankingIndex";
import { Vertical } from "../../../../../../types/verticalTypes";

export const GRMovies = () => {
  return (
    <GRPantheonRankingIndex
      vertical={Vertical.Movies}
      title="Movies"
    />
  );
};
