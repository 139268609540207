import React from "react";
import { createBrowserRouter } from "react-router-dom";
import AuthGuard from "../auth/AuthGuard";
import { ErrorLayout } from "../components/ErrorLayout";
import CommonLayout from "../layouts/common/CommonLayout";
import OrganizationsListPage from "../modules/account-manaement/organizations";
import PermissionsListPage from "../modules/account-manaement/permissions";
import RoleListPage from "../modules/account-manaement/roles";
import UserListPage from "../modules/account-manaement/users";
import AuditHistory from "../modules/audit-history";
import IssueList from "../modules/bug-reporting";
import ViewIssuePage from "../modules/bug-reporting/view";
import { AmazonVendorCentralPage } from "../modules/consumer-products/amazon-vendor-central";
import IdDataMappingIndex from "../modules/data-mapping/id-mapping";
import DiscoverIndex from "../modules/discovery/discovery";
import ExploreHomePage from "../modules/explore";
import ExploreCompanies from "../modules/explore/pages/companies";
import ExploreTalent from "../modules/explore/pages/talent";
import ExploreFranchise from "../modules/explore/pages/franchise";
import ExploreGames from "../modules/explore/pages/games";
import ExploreMovies from "../modules/explore/pages/movies";
import ExploreTelevision from "../modules/explore/pages/television";
import { ForgotPasswordPage } from "../modules/forgot-password";
import GenreDashboard from "../modules/genre-dashboard";
import { GoogleTrendsRawDataPage } from "../modules/google-trends/google-trends-raw-data-page";
import GooglePage from "../modules/googletrendsv2";
import DashboardWizard from "../modules/home/wizard/DashboardWizard";
import IpManagement from "../modules/ip_management";
import PantheonManagement from "../modules/ip_management/pantheonList";
import Item from "../modules/item/item";
import ItemSearch from "../modules/item/pages/search/index";
import { LoginPage } from "../modules/login";
import PlatformJobState from "../modules/monitoring/job-states";
import GlobalTrendsChartsIndexPage from "../modules/platforms/global";
import IMDbTrendsChartsIndexPage from "../modules/platforms/imdb";
import PiracyTrendsChartsIndexPage from "../modules/platforms/piracy";
import RTTrendsChartsIndexPage from "../modules/platforms/rottenTomatoes";
import SteamTrendsChartsIndexPage from "../modules/platforms/steam";
import TwitchTrendsChartsIndexPage from "../modules/platforms/twitch";
import WikipediaTrendsChartsIndexPage from "../modules/platforms/wikipedia";
import YoutubeTrendsChartsIndexPage from "../modules/platforms/youtube";
import GRHomePage from "../modules/ranking/global-ranking";
import { GRGaming } from "../modules/ranking/global-ranking/pantheon-ranking/pages/gaming/GRGaming";
import { GRMovies } from "../modules/ranking/global-ranking/pantheon-ranking/pages/movies/GRMovies";
import { GRSeries } from "../modules/ranking/global-ranking/pantheon-ranking/pages/series/GRSeries";
import GRRawDataIndexPage from "../modules/ranking/global-ranking/raw-data";
import TrackedHomePage from "../modules/ranking/trending";
import { TrendingPantheonRankingIndex } from "../modules/ranking/trending/pantheon-ranking/TrendingPantheonRankingIndex";
import { RankingRawDataPageIndex } from "../modules/ranking/trending/raw-data/RankingRawDataIndex";
import { ResetPasswordPage } from "../modules/reset-password";
import { OptionsEditPage } from "../modules/settings/option-edit-page";
import { RankingPage } from "../modules/settings/ranking-page";
import CreateRankPage from "../modules/settings/wizard/create-rank-page";
// import TitleAvailibility from "../modules/tar/movie";
// import TarSeriesWikiViewGenrePage from "../modules/tar/series/wiki";
// import TitleSeriesAvailibility from "../modules/tar/television";
// import { TiktokRawDataPage } from "../modules/tiktok/tiktok-raw-page";
import { BoxOfficeHomePage } from "../modules/tools/box-office";
import { BoByStudioPage } from "../modules/tools/box-office/bo-by-studio";
import { BoxOfficeGrossTimeseriesPage } from "../modules/tools/box-office/grosstimeseries";
import { BoxOfficeRankingPage } from "../modules/tools/box-office/ranking";
import { CompareTool } from "../modules/tools/compare";
import GoogleRealtimeSearch from "../modules/tools/google-realtime";
import GoogleRealtimeSearchReport from "../modules/tools/google-realtime/GoogleRealtimeSearchReport";
import JWStreamingChartsIndexPage from "../modules/tools/jwstreamingcharts";
import TwitterQueryTool from "../modules/tools/twitter/query-tool";
import TwitterQueryToolResults from "../modules/tools/twitter/query-tool/results";
import OfflineReportsIndexPage from "../modules/tools/offline-reports";
import TopicModelingPantheon from "../modules/topic-modeling/pantheon";
import TopicModelingPantheonTopicPage from "../modules/topic-modeling/pantheon/components/TopicModelingPantheonTopicPage";
import TopicModelingTrueCrimeV2 from "../modules/topic-modeling/true-crime-v2";
import TopicModelingTrueCrimeNamePageV2 from "../modules/topic-modeling/true-crime-v2/name-page";
import TopicModelingTrueCrimeTopicPageV2 from "../modules/topic-modeling/true-crime-v2/topic-page";
import WikipediaPage from "../modules/wikipedia";
import { RawDataPage } from "../modules/wikipedia/raw-data";
import YoutubeChannelItemPage from "../modules/youtube/channelPage";
import { YoutubeChannels } from "../modules/youtube/channelsList";
import { YoutubeHomePage } from "../modules/youtube/home";
import YoutubeVideoItemPage from "../modules/youtube/videoPages";
import { YoutubeVideos } from "../modules/youtube/videos";
import NonOTTDashboardPage from "../modules/dashboards/nonott-dashboard"
import { GoogleDashboard } from "../modules/platforms/google";
import NetflixDashboardPage from "../modules/dashboards/netflix-dashboard"
import YouTubeTrendsDashboardPage from "../modules/youtube/youtube-trends"
import TalentPage from "../modules/talent/cast"
import NewsRiverDashboard from "../modules/dashboards/news-dashboard";

const navigation = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorLayout />,
    element: (
      <AuthGuard>
        <CommonLayout title="Dashboard" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <GRHomePage />,
      },
      {
        path: "/ranking/trending",
        element: <TrackedHomePage />,
      },
      {
        path: "/ranking/global",
        element: <GRHomePage />,
      },
      {
        path: "/dashboards/globalranking/movies",
        element: <GRMovies />,
      },
      {
        path: "/dashboards/globalranking/gaming",
        element: <GRGaming />,
      },
      {
        path: "/dashboards/globalranking/series",
        element: <GRSeries />,
      },
      {
        path: "/dashboards/wizard",
        element: <DashboardWizard />,
      },
      {
        path: "/dashboards/genres",
        element: <GenreDashboard />,
      },
      {
        path: "/dashboards/netflix",
        element: <NetflixDashboardPage />
      },
      {
        path: "/dashboards/pantheonranking",
        element: <TrendingPantheonRankingIndex />,
      },
      {
        path: "/dashboards/raw-data",

        element: <RankingRawDataPageIndex />,
      },
      {
        path: "/dashboards/jwstreaming",
        element: <JWStreamingChartsIndexPage />,
      },

      {
        path: "/ranking/global/datatable",
        element: <GRRawDataIndexPage />,
      },
      {
        path: "/dashboards/nonott",
        element: <NonOTTDashboardPage />,
      },
      {
        path: "/dashboards/news",
        element: <NewsRiverDashboard />,
      },
    ],
  },
  {
    path: "/settings/nbcu-weightage/ranking",
    element: (
      <AuthGuard>
        <CommonLayout title="Dashboard" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/settings/nbcu-weightage/ranking",
        element: <RankingPage />,
      },
      {
        path: "/settings/nbcu-weightage/ranking/create",
        element: <CreateRankPage />,
      },
      {
        path: "/settings/nbcu-weightage/ranking/:id",
        element: <OptionsEditPage />,
      },
    ],
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/reset-password",
    element: <ResetPasswordPage />,
  },

  {
    path: "users",
    element: (
      <AuthGuard>
        <CommonLayout title="user management" />
      </AuthGuard>
    ),

    children: [
      {
        path: "/users/",
        element: <UserListPage />,
      },
    ],
  },
  {
    path: "permissions",
    element: (
      <AuthGuard>
        <CommonLayout title="Permissions management" />
      </AuthGuard>
    ),

    children: [
      {
        path: "/permissions/",
        element: <PermissionsListPage />,
      },
    ],
  },
  {
    path: "roles",
    element: (
      <AuthGuard>
        <CommonLayout title="role management" />
      </AuthGuard>
    ),

    children: [
      {
        path: "/roles/",
        element: <RoleListPage />,
      },
    ],
  },
  {
    path: "teams",
    element: (
      <AuthGuard>
        <CommonLayout title="Teams management" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/teams/",
        element: <OrganizationsListPage />,
      },
    ],
  },
  // {
  //   path: "tools",
  //   element: (
  //     <AuthGuard>
  //       <CommonLayout title="Tools" />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: "/tools/imdbvotegraph",
  //       element: <IMDbDashboardPage />,
  //     },
  //   ],
  // },
  {
    path: "platforms",
    element: (
      <AuthGuard>
        <CommonLayout title="Platforms" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/platforms/global",
        element: <GlobalTrendsChartsIndexPage />,
      },
      {
        path: "/platforms/imdb",
        element: <IMDbTrendsChartsIndexPage />,
      },
      {
        path: "/platforms/wikipedia",
        element: <WikipediaTrendsChartsIndexPage />,
      },
      {
        path: "/platforms/google",
        element: <GoogleDashboard />,
      },
      {
        path: "/platforms/rottentomatoes",
        element: <RTTrendsChartsIndexPage />,
      },
      {
        path: "/platforms/twitch",
        element: <TwitchTrendsChartsIndexPage />,
      },
      {
        path: "/platforms/steam",
        element: <SteamTrendsChartsIndexPage />,
      },
      {
        path: "/platforms/piracy",
        element: <PiracyTrendsChartsIndexPage />,
      },
      {
        path: "/platforms/youtube",
        element: <YoutubeTrendsChartsIndexPage />,
      },
      {
        path: "/platforms/wikipedia/summary",
        element: <WikipediaPage />,
      },
      {
        path: "/platforms/wikipedia/raw-data",
        element: <RawDataPage />,
      },
      {
        path: "/platforms/googletrends",
        element: <GooglePage />,
      },
      {
        path: "/platforms/google/raw-data",
        element: <GoogleTrendsRawDataPage />,
      },
      // {
      //   path: "/platforms/fan-sites/fandom/raw-data",
      //   element: <FandomRawDataPage />,
      // },
      // {
      //   path: "/platforms/fan-sites/ao3/raw-data",
      //   element: <Ao3RawDataPage />,
      // },
      // {
      //   path: "/platforms/fan-sites/reddit/raw-data",
      //   element: <RedditRawDataPage />,
      // },
      // {
      //   path: "/platforms/fan-sites/wattpad/raw-data",
      //   element: <WattpadRawDataPage />,
      // },
      // {
      //   path: "/platforms/content-consumption/twitter/raw-data",
      //   element: <TwitterRawDataPage />,
      // },
      // {
      //   path: "/platforms/content-consumption/tiktok/raw-data",
      //   element: <TiktokRawDataPage />,
      // },
      // {
      //   path: "/platforms/content-consumption/instagram/raw-data",
      //   element: <InstagramRawDataPage />,
      // },
      {
        path: "/platforms/consumer-products/amazon-search",
        element: <AmazonVendorCentralPage />,
      },
    ],
  },
  {
    path: "ipmanagement",
    element: (
      <AuthGuard>
        <CommonLayout title="IP Management" />
      </AuthGuard>
    ),

    children: [
      {
        path: "/ipmanagement/",
        element: <IpManagement />,
      },
      {
        path: "/ipmanagement/pantheon",
        element: <PantheonManagement />,
      },
    ],
  },
  {
    path: "datamapping",
    element: (
      <AuthGuard>
        <CommonLayout title="Data Mapping" />
      </AuthGuard>
    ),

    children: [
      {
        path: "/datamapping/idmanagement",
        element: <IdDataMappingIndex />,
      },
      {
        path: "/datamapping/discover",
        element: <DiscoverIndex />,
      },
    ],
  },
  {
    path: "platformjobstate",
    element: (
      <AuthGuard>
        <CommonLayout title="Platform Job State" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/platformjobstate/",
        element: <PlatformJobState />,
      },
    ],
  },
  {
    path: "issues",
    element: (
      <AuthGuard>
        <CommonLayout title="Issues" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/issues/",
        element: <IssueList />, // Component to list all issues
      },
      {
        path: "/issues/:id",
        element: <ViewIssuePage />, // Component to list all issues
      },
    ],
  },
  // {
  //   path: "tar",
  //   element: (
  //     <AuthGuard>
  //       <CommonLayout title="Explore" />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: "/tar/movie/titleavailability/",
  //       element: <TitleAvailibility />,
  //     },
  //     {
  //       path: "/tar/television/titleavailability/",
  //       element: <TitleSeriesAvailibility />,
  //     },
  //     {
  //       path: "/tar/series/wiki/view/genre",
  //       element: <TarSeriesWikiViewGenrePage />,
  //     },
  //     {
  //       path: "/tar/series/wiki/view/genre/platform",
  //       element: <TarSeriesWikiViewGenrePlatformPage />,
  //     },
  //     {
  //       path: "/tar/series/wiki/view/genre/platform/marketshare",
  //       element: <TarSeriesWikiViewGenrePlatformMarketsharePage />,
  //     },
  //   ],
  // },
  // {
  //   path: "peacock-dashboard",
  //   element: (
  //     <AuthGuard>
  //       <CommonLayout title="Peacock Dashboard" />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: "/peacock-dashboard/",
  //       element: <PeacockDashboardHomePage />,
  //     },
  //     {
  //       path: "/peacock-dashboard/series/wiki/genre",
  //       element: <TarSeriesWikiViewGenrePage />,
  //     },
  //     {
  //       path: "/peacock-dashboard/series/wiki/genre-platform",
  //       element: <TarSeriesWikiViewGenrePlatformPage />,
  //     },
  //     {
  //       path: "/peacock-dashboard/series/wiki/genre-marketshare",
  //       element: <TarSeriesWikiViewGenrePlatformMarketsharePage />,
  //     },
  //     {
  //       path: "/peacock-dashboard/non-ott-titles/movies",
  //       element: <MoviesNonOTTIndex />,
  //     },
  //     {
  //       path: "/peacock-dashboard/non-ott-titles/series",
  //       element: <SeriesNonOTTIndex />,
  //     },
  //   ],
  // },
  {
    path: "item",
    element: (
      <AuthGuard>
        <CommonLayout title="Item" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/item/search",
        element: <ItemSearch />,
      },
      {
        path: "/item/:id",
        element: <Item />,
      },
      {
        path: "/item/talent/:id",
        element: <TalentPage />,
      },
    ],
  },
  {
    path: "explore",
    element: (
      <AuthGuard>
        <CommonLayout title="Explore" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/explore/",
        element: <ExploreHomePage />,
      },
      {
        path: "/explore/movies",
        element: <ExploreMovies />,
      },
      {
        path: "/explore/television",
        element: <ExploreTelevision />,
      },
      {
        path: "/explore/games",
        element: <ExploreGames />,
      },
      {
        path: "/explore/franchise",
        element: <ExploreFranchise />,
      },
      {
        path: "/explore/companies",
        element: <ExploreCompanies />,
      },
      {
        path: "/explore/talent",
        element: <ExploreTalent />,
      },
    ],
  },
  {
    path: "youtube",
    element: (
      <AuthGuard>
        <CommonLayout title="YouTube" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/youtube/",
        element: <YoutubeHomePage />,
      },
      {
        path: "/youtube/trends",
        element: <YouTubeTrendsDashboardPage />,
      },
      {
        path: "/youtube/channels",
        element: <YoutubeChannels />,
      },
      {
        path: "/youtube/channels/:id",
        element: <YoutubeChannelItemPage />,
      },
      {
        path: "/youtube/videos/:id",
        element: <YoutubeVideoItemPage />,
      },
      {
        path: "/youtube/movietrailers",
        element: <YoutubeVideos type="film" />,
      },
      {
        path: "/youtube/seriestrailers",
        element: <YoutubeVideos type="series" />,
      },
      {
        path: "/youtube/gamestrailers",
        element: <YoutubeVideos type="game" />,
      },
      {
        path: "/youtube/unmappedtrailers",
        element: <YoutubeVideos type="unmapped" />,
      },
      {
        path: "/youtube/unofficialtrailers",
        element: <YoutubeVideos type="unofficial" />,
      },
      {
        path: "/youtube/kidstrailers",
        element: <YoutubeVideos type="kids" />,
      },
      {
        path: "/youtube/alltrailers",
        element: <YoutubeVideos type="all" />,
      },
    ],
  },
  {
    path: "audit",
    element: (
      <AuthGuard>
        <CommonLayout title="Audit Logs" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/audit/",
        element: <AuditHistory />,
      },
    ],
  },
  {
    path: "tools",
    element: (
      <AuthGuard>
        <CommonLayout title="Tools" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/tools/compare",
        element: <CompareTool />,
      },
      {
        path: "/tools/google-search-trends",
        element: <GoogleRealtimeSearch />,
      },
      {
        path: "/tools/google-search-trends/searches/:id",
        element: <GoogleRealtimeSearchReport />,
      },
      {
        path: "/tools/twitter-query-trends",
        element: <TwitterQueryTool />,
      },
      {
        path: "/tools/twitter-query-trends/searches/:id",
        element: <TwitterQueryToolResults />,
      },
      {
        path: "/tools/offline-reports",
        element: <OfflineReportsIndexPage />,
      },
    ],
  },
  {
    path: "box-office",
    element: (
      <AuthGuard>
        <CommonLayout title="Box Office" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/box-office",
        element: <BoxOfficeHomePage />,
      },
      {
        path: "/box-office/grosstimeseries",
        element: <BoxOfficeGrossTimeseriesPage />,
      },
      {
        path: "/box-office/ranking",
        element: <BoxOfficeRankingPage />,
      },
      {
        path: "/box-office/bo-by-studio",
        element: <BoByStudioPage />,
      },
    ],
  },
  {
    path: "topic-modeling",
    element: (
      <AuthGuard>
        <CommonLayout title="Topic Modeling" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/topic-modeling/true-crime",
        element: <TopicModelingTrueCrimeV2 />,
      },
      {
        path: "/topic-modeling/true-crime/person/:name",
        element: <TopicModelingTrueCrimeNamePageV2 />,
      },
      {
        path: "/topic-modeling/true-crime/topic/:topicId",
        element: <TopicModelingTrueCrimeTopicPageV2 />,
      },
      {
        path: "/topic-modeling/pantheon",
        element: <TopicModelingPantheon />,
      },
      {
        path: "/topic-modeling/pantheon/topic/:topicId",
        element: <TopicModelingPantheonTopicPage />,
      },
    ],
  },
]);

export default navigation;
