import { Button, ButtonDropdown, Drawer, SpaceBetween } from '@cloudscape-design/components';
import React, { useEffect, useMemo, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import { SetFilter } from '../../../components/filter-options/components/SetFilter';
import _ from 'lodash';
import { useGetGenericPreference_V2 } from '../../../services/generic_v2/hooks/useGetGenericPreference_V2';
import { usePutGenericPreference_V2 } from '../../../services/generic_v2/hooks/usePutGenericPreference_V2';
import { usePostGenericPreference_V2 } from '../../../services/generic_v2/hooks/usePostGenericPreference_V2';
import { invalidatePreferenceData } from '../../../services/generic_v2/utils/serviceUtils';
import { EditableTextAttribute } from './EditableTextAttribute';
import { MessageBox } from '../../../components/MessageBox';
import { ApiURLParams } from '../../../services/generic_v2/requests';
import { DateFilter } from '../../../components/filter-options/components/DateFilter';
import { Filter, FilterControlType, FilterObject } from '../../../types/filterTypes';
import { TextFilter } from '../../../components/filter-options/components/TextFilter';
import { convertFilterObjectsCompat, sanitizeFilterObject } from '../../../utils/filters/filterUtils';
import { useFilterPreferences } from '../../../services/filterPreference/useFilterPreferences';

interface FiltersDrawerProps {
  prefsApiParams: ApiURLParams;
  filterObject: FilterObject;
  setFilterObject: (filterObject: FilterObject) => void;
  defaultFilterObject: FilterObject;
}

type FiltersDrawerHandle = {
  setCurrentFilterObject: (filterObject: FilterObject) => void;
};

export const FiltersDrawer = forwardRef<FiltersDrawerHandle, FiltersDrawerProps>(({
  prefsApiParams,
  filterObject,
  setFilterObject,
  defaultFilterObject,
}, ref) => {

  const messageBoxRef = useRef(null);

  const {
    primaryFilter,
    loadedFilters,
    savePrimaryFilter,
    saveLoadedFilters,
  } = useFilterPreferences({ apiParams: prefsApiParams, defaultFilterObject });

  const [unsanitizedFilterObject, setUnsanitizedFilterObject] = useState<FilterObject>(filterObject);
  const currentFilterObject = useMemo(() => sanitizeFilterObject(unsanitizedFilterObject), [unsanitizedFilterObject]);
  const filterGroups = useMemo(() => _.groupBy(currentFilterObject.filters, 'group'), [currentFilterObject.filters]);
  
  const setFilterListItem = (filterListItem: Filter) => {
    const newFilters = currentFilterObject.filters.map(f => filterListItem.field === f.field ? filterListItem : f).flat();
    setUnsanitizedFilterObject({ ...currentFilterObject, filters: newFilters });
  }

  const currentFiltersAreSaved = useMemo(() => {
    if (_.isEqual(currentFilterObject, defaultFilterObject)) {
      return true;
    }
    return loadedFilters?.map(w => _.isEqual(w, currentFilterObject)).includes(true);
  }, [loadedFilters, currentFilterObject]);

  useEffect(() => {
    if (primaryFilter === null) return;
    if (primaryFilter.name === currentFilterObject.name && primaryFilter.category === currentFilterObject.category) return;
    setUnsanitizedFilterObject(primaryFilter);
  }, [primaryFilter]);

  useEffect(() => {
    if (filterObject.advancedFilter === currentFilterObject.advancedFilter) return;
    const newFilterObject = { 
      ...currentFilterObject,
      advancedFilter: filterObject.advancedFilter,
    };
    setUnsanitizedFilterObject(newFilterObject);
  }, [filterObject.advancedFilter]);

  useImperativeHandle(ref, () => ({
    setCurrentFilterObject: (filterObject) => {
      setUnsanitizedFilterObject(filterObject);
    },
  }), []);

  return (
    <Drawer header="Filters">
      <SpaceBetween direction="vertical" size="m">
        <div className="flex flex-col gap-2 border-b border-1 pb-4 mb-2 border-slate-600">
          <div className="flex justify-between items-center w-full">
            <div className="min-w-0">
              <EditableTextAttribute
                value={currentFilterObject.name}
                onChange={(value) => setUnsanitizedFilterObject({ ...currentFilterObject, name: value })}
              />
            </div>
            <ButtonDropdown
              expandableGroups
              expandToViewport
              items={[
                { text: "Set as primary filter", id: "set_primary" },
                { text: "Load defaults", id: "reset", disabled: (_.isEqual(currentFilterObject, defaultFilterObject)) },
                {
                  text: "Load filter",
                  id: "load",
                  disabled: loadedFilters?.filter(w => w.category === filterObject.category)?.length === 0,
                  items: loadedFilters?.filter(w => w.category === filterObject.category)?.map(w => (
                    {
                      text: w.name,
                      id: `load_${w.name}`,
                      disabled: false,
                    }
                  ))
                },
                { text: "Save filter", id: "save", disabled: currentFiltersAreSaved },
                { text: "Delete filter", id: "delete", disabled: (loadedFilters.filter(w => w.name === currentFilterObject.name && w.category === currentFilterObject.category).length === 0) },
              ]}
              variant="icon"
              onItemClick={({ detail }) => {
                switch (detail.id) {
                  case "save":
                    const save = () => {
                      const newLoadedFilters = [...loadedFilters.filter(w => !(w.name === currentFilterObject.name && w.category === currentFilterObject.category)), currentFilterObject];
                      saveLoadedFilters(newLoadedFilters);
                    };
                    if (loadedFilters.filter(w => w.name === currentFilterObject.name && w.category === currentFilterObject.category).length > 0) {
                      messageBoxRef.current.open({
                        headerText: "Overwrite filter?",
                        messageBody: (<div>A filter named <b>{currentFilterObject.name}</b> already exists. Overwrite it?</div>),
                        primaryButtonText: "Yes",
                        secondaryButtonText: "No",
                        onPrimaryButtonClick: save,
                      });
                    } else {
                      save();
                    }
                    break;
                  case "delete":
                    messageBoxRef.current.open({
                      headerText: "Delete filter?",
                      messageBody: (<div>Are you sure you want to delete filter <b>{currentFilterObject.name}</b>?</div>),
                      primaryButtonText: "Delete",
                      secondaryButtonText: "Cancel",
                      onPrimaryButtonClick: () => {
                        const newLoadedFilters2 = loadedFilters.filter(w => !(w.name === currentFilterObject.name && w.category === currentFilterObject.category));
                        saveLoadedFilters(newLoadedFilters2);
                        setUnsanitizedFilterObject(defaultFilterObject);
                        if (primaryFilter && primaryFilter.name === currentFilterObject.name && primaryFilter.category === currentFilterObject.category) {
                          savePrimaryFilter(null);
                        }
                        setFilterObject(defaultFilterObject);
                      },
                    });
                    break;
                  case "reset":
                    setUnsanitizedFilterObject(defaultFilterObject);
                    break;
                  case "set_primary":
                    messageBoxRef.current.open({
                      headerText: "Set as primary filter?",
                      messageBody: (<div>Set <b>{currentFilterObject.name}</b> as your primary filter? It will automatically be applied the next time you visit this page.</div>),
                      primaryButtonText: "Yes",
                      secondaryButtonText: "No",
                      onPrimaryButtonClick: () => {
                        if (_.isEqual(currentFilterObject, defaultFilterObject)) {
                          savePrimaryFilter(null);
                        } else {
                          savePrimaryFilter(currentFilterObject);
                        }
                      },
                    });
                    break;
                  default:
                    if (detail.id.startsWith("load_")) {
                      const newFilters = loadedFilters.find(w => w.name === detail.id.replace("load_", "") && w.category === currentFilterObject.category);
                      if (newFilters) {
                        const defaultFilterObjectFilterKeys = defaultFilterObject.filters.map(f => f.field);
                        const newFiltersWithSubset = { ...newFilters, filters: defaultFilterObjectFilterKeys.map(x => newFilters.filters.find(f => f.field === x) ?? defaultFilterObject.filters.find(f => f.field === x)) };
                        setUnsanitizedFilterObject(newFiltersWithSubset);
                      }
                    }
                }
              }}
            />
          </div>
          <div className="flex w-full justify-items-stretch gap-x-2">
            <Button
              fullWidth
              onClick={() => {
                setUnsanitizedFilterObject(defaultFilterObject);
              }}
              disabled={_.isEqual(currentFilterObject, defaultFilterObject)}
            >
              Reset
            </Button>
            <Button
              fullWidth
              variant="primary"
              onClick={() => {
                setFilterObject(currentFilterObject);
              }}
              disabled={_.isEqual(filterObject, currentFilterObject)}
            >
              Apply
            </Button>
          </div>
          <div className="flex w-full">
            {!currentFiltersAreSaved && (
              <div className="text-xs text-slate-400">You have unsaved changes</div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-y-3">
          {Object.keys(filterGroups).map(filterGroup => (
            <div className="flex flex-col gap-y-3">
              {filterGroup !== "undefined" && (
                <div className="text-lg font-semibold border-t border-slate-600 pt-3">{filterGroup}</div>
              )}
              {filterGroups[filterGroup]?.map(filter => (
                <div key={filter.field}>
                  {filter.controlOptions.type === FilterControlType.Set ? (
                    <SetFilter
                      filterObject={filter}
                      setFilterObject={setFilterListItem}
                    />
                  ) : (filter.controlOptions.type === FilterControlType.Date) ? (
                    <DateFilter
                      filterObject={filter}
                      setFilterObject={setFilterListItem}
                    />
                  ) : filter.controlOptions.type === FilterControlType.Text ? (
                    <TextFilter
                      filterObject={filter}
                      setFilterObject={setFilterListItem}
                    />
                  ) : null}
                </div>
              ))}
            </div>
          )).flat()}
        </div>
      </SpaceBetween>
      <MessageBox ref={messageBoxRef} />
    </Drawer>
  );
});