import React, { useRef, useState } from 'react';
import { TileData } from './types';
import { Vertical } from '../../types/verticalTypes';
import { renderTrendSimple } from '../../utils/trendIcon';
import { VerticalIcon } from '../VerticalIcon';
import { Tooltip } from "react-tooltip";
import moment from 'moment';

const getImageUrl = (imageUrl: string, vertical: Vertical) => {
  switch (vertical) {
    case Vertical.Movies:
    case Vertical.Series:
      return `https://image.tmdb.org/t/p/w300/${imageUrl}`;
    case Vertical.Games:
      return `https://images.igdb.com/igdb/image/upload/t_cover_big/${imageUrl}.png`;
    default:
      return null;
  }
}

const Tile: React.FC<TileData> = ({
  ipId,
  title,
  imageUrl,
  vertical,
  releaseDate,
  action,
  content,
  rank,
  rankChange,
  alwaysShowAction=false,
}) => {

  const ref = useRef(null);

  const [onRightSide, setOnRightSide] = useState(false);
  const [imageErrored, setImageErrored] = useState(false);

  const fullImageUrl = getImageUrl(imageUrl, vertical);

  const checkIfOnRightSide = () => {
    const parentWidth = ref?.current?.parentElement?.getBoundingClientRect().width;
    const parentPos = ref?.current?.parentElement?.getBoundingClientRect().x;
    const relativeXPos = ref?.current?.getBoundingClientRect().x - parentPos;
    setOnRightSide(relativeXPos > parentWidth / 2);
  };

  const invertSides = (className) => {
    if (!onRightSide) return className;
    return className.replaceAll("left", "__tmp__").replaceAll("right", "left").replaceAll("__tmp__", "right");
  };

  return (
    <div 
      ref={ref} 
      className="min-w-22 min-h-32 max-w-64 aspect-[44/64] bg-slate-600 rounded-lg shadow-md relative group hover:scale-110 ease-out duration-300 z-0 hover:z-10 grow"
      onMouseEnter={checkIfOnRightSide}
    >
      <div className={content ? invertSides("h-full aspect-[44/64] bg-black bg-opacity-70 backdrop-blur-md absolute top-0 left-0 group-hover:aspect-[88/64] ease-out duration-300 rounded-lg pointer-events-none") : ""}>
        <div 
          className={invertSides("relative top-0 left-0 float-left h-full aspect-[44/64] z-20 pointer-events-auto cursor-pointer" + ` cover-image-${ipId}`)}
          onClick={(event: any) => {
            if (!event.target.classList.contains("clickable-item")) return;
            window.location.href = `/item/${ipId}`;
          }}
          onMouseDown={(event: any) => {
            if (!event.target.classList.contains("clickable-item")) return;
            if (event.which === 2 || event.button === 1) {
              event.preventDefault();
              window.open(`/item/${ipId}`, '_blank').focus();
            }
          }}
        >
          {(!imageErrored && fullImageUrl) ? (
            <img 
              src={fullImageUrl} 
              alt={title} 
              className="w-full h-full object-cover rounded-lg clickable-item" 
              loading="lazy" 
              onError={() => {
                if (!imageErrored) {
                  setImageErrored(true);
                }
              }}
            />
          ) : (
            <div className="flex items-center justify-center w-full h-full text-white text-sm clickable-item bg-slate-600 rounded-lg">
              {title}
            </div>
          )}
          {rank && (
            <div className="absolute top-1 left-1 bg-sidebarLow bg-opacity-70 backdrop-blur-md rounded-full min-w-6 px-2 h-6 flex items-center justify-center text-amber-300 font-bold">
              {rankChange != null ? renderTrendSimple(rank, rankChange) : rank}
            </div>
          )}
          {vertical && (
            <div className={"absolute top-1 right-1 bg-sidebarLow bg-opacity-70 backdrop-blur-md rounded-full min-w-6 px-2 h-6 flex items-center justify-center text-slate-200 " + (action ? (alwaysShowAction ? "hidden": "group-hover:hidden") : "")}>
              <VerticalIcon vertical={vertical} className="w-4 h-4" />
            </div>
          )}
          {releaseDate && (
            <div className="absolute bottom-1 right-1 bg-sidebarLow bg-opacity-70 backdrop-blur-md rounded-full min-w-6 px-2 h-6 flex items-center justify-center text-slate-200 text-xs font-bold">
              {moment(releaseDate).format("YYYY")}
            </div>
          )}
          {action && (
            <div className={`absolute top-1 right-1 z-50 group-hover:block ${alwaysShowAction ? "block" : "hidden"}`}>
              {action}
            </div>
          )}
          <Tooltip
            anchorSelect={`.cover-image-${ipId}`}
            content={title}
            place="top"
            opacity={1.0}
            style={{ backgroundColor: "#333", borderRadius: "0.5rem", maxWidth: "100%" }}
          />
        </div>
        <div className={invertSides("ease-out duration-300 absolute top-0 right-0 z-0 whitespace-nowrap h-full p-4 aspect-[44/64] flex flex-col gap-y-2 items-center hidden group-hover:block")}>
          {content}
        </div>
      </div>
    </div>
  );
};

export default Tile;