import { Drawer, HelpPanel } from '@cloudscape-design/components';
import React from 'react';

export const TrendingInfoDrawer = () => {

  return (
    <Drawer header="Information">
      <div className="flex flex-col gap-1">
        <div className="text-base font-bold">Tracked ranking</div>
        <p>Titles in <b>tracked</b> ranking include a mix of titles automatically selected for their current relevance and titles manually chosen by users of the platform.</p>
        <p>These tracked titles are added to an <b>IP list</b> designed to collect a more comprehensive set of platform metrics, unlike the limited set used for global ranking.</p>
        <div className="text-base font-bold mt-4">How ranks are calculated</div>
        <p>For each platform, a percentile rank is calculated for tracked titles in the table, where a value of 1 indicates the top performing title and 0 indicates a low-performing title. A blank value signifies that no data is available for that title on the platform.</p>
        <p>The final <b>Pantheon Score</b> is determined by taking a weighted average of the scores across all platforms for each title, with ranks assigned based on this score.</p>
      </div>
    </Drawer>
  );
};