import {
  ContentLayout,
  Header,
  Multiselect,
  Select,
  SpaceBetween,
  Tabs,
} from "@cloudscape-design/components";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TabActions } from "../../../../components/TabActions";
import { SERVICES, TYPES } from "../../../../config-global";
import { navItems } from "../../../../layouts/common/menu/side-menu";
import { useGetGenericPreference_V2 } from "../../../../services/generic_v2/hooks/useGetGenericPreference_V2";
import { CHART_LABELS } from "../../../platforms/hooks/useGridConfig";
import {
  CATEGORIES,
  getCategories,
} from "../../../platforms/utils/categoryUtils";
import {
  DEFAULT_FILTER_OBJECTS,
  DEFAULT_WEIGHTS_OBJECTS,
} from "../constants";
import { Layout } from "../../Layout";
import { BasicParameters } from "./components/BasicParameters";
import { CompareContainer } from "./components/CompareContainer";
import { DataTable } from "./components/DataTable/DataTable";
import { GRPantheonRanking } from "./components/GRPantheonRanking";
import _ from "lodash";
import { weightsObjectToWeightageValues } from "./utils";
import { GlobalInfoDrawer } from "../../components/GlobalInfoDrawer";
import { ExportButton } from "./components/ExportButton";
import { Vertical } from "../../../../types/verticalTypes";
import { useWeightDrawer } from "../../../../hooks/WeightDrawer/useWeightDrawer";
import { useFilterDrawer } from "../../../../hooks/FilterDrawer/useFilterDrawer";
import { useGenericPreference_V2 } from "../../../../services/generic_v2/hooks/useGenericPreference_V2";

export const additionalColumns = {
  [Vertical.Movies]: [
    CHART_LABELS.SCORE_CHANGE,
    ...Object.values(CHART_LABELS).filter(
      (label) =>
        !label.toLowerCase().includes("trend") &&
        getCategories(label)?.includes(CATEGORIES.MOVIES),
    ),
  ],
  [Vertical.Series]: [
    CHART_LABELS.SCORE_CHANGE,
    ...Object.values(CHART_LABELS).filter(
      (label) =>
        !label.toLowerCase().includes("trend") &&
        getCategories(label)?.includes(CATEGORIES.SERIES),
    ),
  ],
  [Vertical.Games]: [
    CHART_LABELS.SCORE_CHANGE,
    ...Object.values(CHART_LABELS).filter(
      (label) =>
        !label.toLowerCase().includes("trend") &&
        getCategories(label)?.includes(CATEGORIES.GAMING),
    ),
  ],
};

const sortOptions = [
  { label: "Top", value: "top", field: "score", order: "desc" },
  { label: "Rising", value: "rising", field: "score_change", order: "desc" },
  { label: "Falling", value: "falling", field: "score_change", order: "asc" },
  { label: "Wikipedia Z-Score", value: "wikipedia", field: "wiki", order: "desc", verticals: [Vertical.Movies, Vertical.Series, Vertical.Games] },
  { label: "YouTube Z-Score", value: "youtube", field: "youtube", order: "desc", verticals: [Vertical.Movies, Vertical.Series, Vertical.Games] },
  { label: "IMDb Z-Score", value: "imdb", field: "imdb", order: "desc", verticals: [Vertical.Movies, Vertical.Series] },
  { label: "Rotten Tomatoes Z-Score", value: "rotten_tomatoes", field: "rt", order: "desc", verticals: [Vertical.Movies, Vertical.Series] },
  { label: "Piracy Z-Score", value: "piracy", field: "piracy", order: "desc", verticals: [Vertical.Movies, Vertical.Series] },
  { label: "Steam Z-Score", value: "steam", field: "steam", order: "desc", verticals: [Vertical.Games] },
  { label: "Twitch Z-Score", value: "twitch", field: "twitch", order: "desc", verticals: [Vertical.Games] },
];

interface GRPantheonRankingIndexProps {
  vertical: Vertical;
  title: string;
}

export const GRPantheonRankingIndex: React.FC<GRPantheonRankingIndexProps> = ({
  vertical,
  title,
}) => {

  const apiParams = {
    type: TYPES.PERSONAL,
    service: SERVICES.RANKING,
    module: "global",
  };
  const breadcrumbs = [
    { text: "Rankings" },
    { text: title },
  ];

  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedIpIds, setSelectedIpIds] = useState([]);
  const [splitPanelStatus, setSplitPanelStatus] = useState(false);
  const [activeTabId, setActiveTabId] = useState(searchParams.get("tab") ?? "tile");
  const [activeVertical, setActiveVertical] = useState(null);
  const [dateRange, setDateRange] = useState((searchParams.get("startDate") || searchParams.get("endDate")) ? {
    type: "absolute",
    startDate: searchParams.get("startDate") ?? moment().subtract(1, "weeks").format("YYYY-MM-DD"),
    endDate: searchParams.get("endDate") ?? moment().format("YYYY-MM-DD"),
  } : {
    type: "relative",
    startDate: moment().subtract(1, "weeks").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    unit: "day",
    amount: 7,
  });
  const [selectedAdditionalColumns, setSelectedAdditionalColumns] = useState(
    [],
  );
  const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0]);

  const handlePreferenceFirstLoad = (preferenceData) => {
    if (preferenceData) {
      if (preferenceData.primaryTab && !searchParams.get("tab")) {
        setActiveTabId(preferenceData.primaryTab);
      }
      if (preferenceData[`selectedAdditionalColumns_${vertical}`] && activeVertical !== vertical) {
        setSelectedAdditionalColumns(additionalColumnsOptions.filter(o => preferenceData[`selectedAdditionalColumns_${vertical}`].includes(o.value)));
        setActiveVertical(vertical);
      }
    }
  };

  const { preferenceData, savePreference } = useGenericPreference_V2({ apiParams, onPreferenceFirstLoad: handlePreferenceFirstLoad });

  const {
    filterDrawerConfig,
    filterObject,
    filterQuery,
    setFilterValue,
  } = useFilterDrawer({
    defaultFilterObject: DEFAULT_FILTER_OBJECTS[vertical],
    prefsApiParams: apiParams,
  });

  const {
    weightDrawerConfig,
    weightsObject,
  } = useWeightDrawer({
    defaultWeightObject: DEFAULT_WEIGHTS_OBJECTS[vertical],
    prefsApiParams: apiParams,
  });

  const additionalColumnsOptions = useMemo(() => {
    return additionalColumns[vertical].map((column) => ({
      label: column,
      value: column,
    }));
  }, [vertical]);

  const weightageValues = useMemo(() => {
    return weightsObjectToWeightageValues(weightsObject);
  }, [weightsObject]);

  const infoDrawerConfig = {
    id: "infoDrawer",
    content: (
      <GlobalInfoDrawer />
    ),
    trigger: {
      iconName: "status-info",
    },
    ariaLabels: {
      drawerName: "My Drawer",
      closeButton: "Close",
      triggerButton: "Open",
      resizeHandle: "Resize",
    },
    resizable: true,
    defaultSize: 290,
  };

  const getTab = ({ id, label }) => {
    return {
      label: label,
      id: id,
      action: <TabActions tabId={id} preferencesApiParams={apiParams} />,
    };
  };

  return (
    <Layout
      title={title}
      breadcrumbs={breadcrumbs}
      drawers={[infoDrawerConfig, filterDrawerConfig, weightDrawerConfig] as any}
      navItems={navItems as any}
      content={
        <ContentLayout
          disableOverlap
          header={
            <Header
              variant="h2"
              description="View top and trending titles based on weighted platform metrics"
            >
              {title}
            </Header>
          }
        >
          <SpaceBetween direction="vertical" size="m">
            <Tabs
              disableContentPaddings
              onChange={({ detail }) => {
                setActiveTabId(detail.activeTabId);
                setSearchParams({ tab: detail.activeTabId });
              }}
              activeTabId={activeTabId}
              tabs={[
                getTab({ id: "tile", label: "Tile View" }),
                getTab({ id: "table", label: "Table View" }),
                //getTab({ id: "trending", label: "Trending" }),
              ]}
            />
            <BasicParameters
              dateRange={dateRange}
              setDateRange={setDateRange}
              titleSearchQuery={filterObject.filters.find(f => f.field === "ip")?.value?.[0] || ""}
              setTitleSearchQuery={(value) => setFilterValue("ip", value)}
              showAdvancedFilter={false}
              additionalFilters={
                <>
                  <ExportButton
                    filterQuery={filterQuery}
                    vertical={vertical}
                    sort={selectedSortOption.field}
                    sortOrder={selectedSortOption.order}
                    startTs={moment.utc(dateRange.startDate).unix()}
                    endTs={moment.utc(dateRange.endDate).unix()}
                    weightageValues={weightageValues}
                  />
                  {activeTabId === "table" ? (
                    <Multiselect
                      options={additionalColumnsOptions}
                      selectedOptions={selectedAdditionalColumns}
                      onChange={({ detail }) => {
                        setSelectedAdditionalColumns([
                          ...detail.selectedOptions,
                        ]);
                        savePreference({
                          [`selectedAdditionalColumns_${vertical}`]: detail.selectedOptions.map(o => o.value),
                        });
                      }}
                      placeholder="Additional columns"
                      hideTokens
                    />
                  ) : null}
                  <Select
                    options={sortOptions.filter(o => o.verticals != null ? o.verticals.includes(vertical) : true)}
                    selectedOption={selectedSortOption}
                    onChange={({ detail }) =>
                      setSelectedSortOption(detail.selectedOption as any)
                    }
                  />
                </>
              }
            />
            {activeTabId === "tile" ? (
              <GRPantheonRanking
                vertical={vertical}
                selectedIpIds={selectedIpIds}
                setSelectedIpIds={setSelectedIpIds}
                setSplitPanelStatus={setSplitPanelStatus}
                filterQuery={filterQuery}
                weightageValues={weightageValues}
                dateRange={dateRange}
                sortField={selectedSortOption.field}
                sortOrder={selectedSortOption.order}
              />
            ) : activeTabId === "table" ? (
              <DataTable
                vertical={vertical}
                selectedIpIds={selectedIpIds}
                setSelectedIpIds={setSelectedIpIds}
                setSplitPanelStatus={setSplitPanelStatus}
                filterQuery={filterQuery}
                weightageValues={weightageValues}
                dateRange={dateRange}
                additionalColumns={additionalColumns[vertical]}
                selectedAdditionalColumns={selectedAdditionalColumns}
                sortField={selectedSortOption.field}
                sortOrder={selectedSortOption.order}
              />
            ) : null}
          </SpaceBetween>
        </ContentLayout>
      }
      contentType="table"
      splitPanel={
        <CompareContainer
          vertical={vertical}
          ipIds={selectedIpIds}
          dateRange={dateRange}
          weightsObject={weightsObject}
          onParametersChanged={(parameters) => {
            const newTitleIds = parameters[0].titleIds;
            if (!_.isEqual(newTitleIds.sort(), selectedIpIds.sort())) {
              setSelectedIpIds(newTitleIds);
            }
          }}
        />
      }
      splitPanelStatus={splitPanelStatus}
      setSplitPanelStatus={setSplitPanelStatus}
    />
  );
};
