import { Mode } from "@cloudscape-design/global-styles";
import { useThemeContext } from "../../app/ThemeProvider";
import {
  agChartDarkTheme,
  agChartLightTheme,
  agChartPolychromaDarkTheme,
  agChartVividDarkTheme,
} from "./themes";
import { isSome } from "../../utils/sugarUtils";
import _ from "lodash";

export const CHART_TYPES = {
  DEFAULT: "DEFAULT",
  POLYCHROMA: "POLYCHROMA",
  VIVID: "VIVID",
};

export const useGetChartTheme = (chartType?: string, options?: any) => {
  const { mode } = useThemeContext();
  let theme = agChartLightTheme;

  if (mode === Mode.Dark) {
    switch (chartType) {
      case CHART_TYPES.VIVID:
        theme = agChartVividDarkTheme;
        break;
      case CHART_TYPES.POLYCHROMA:
        theme = agChartPolychromaDarkTheme;
        break;
      case CHART_TYPES.DEFAULT:
      default:
        theme = agChartDarkTheme;
        break;
    }
  }

  if (isSome(options)) {
    theme = _.merge(theme, options);
  }

  return { theme };
};
