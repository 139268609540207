import React from 'react';
import { TileData } from './types';
import Tile from './Tile';

interface TitleTilesProps {
  itemData: Array<TileData>;
  variant?: 'grid' | 'row';
}

const TitleTiles: React.FC<TitleTilesProps> = ({
  itemData,
  variant = 'grid',
}) => {
  return (
    <div className="@container">
      <div className={
        variant === "row" ?
        "gap-4 h-full flex flex-row flex-nowrap overflow-x-auto overflow-y-hidden py-2" :
        "gap-4 w-full h-full grid grid-cols-1 @sm:grid-cols-2 @lg:grid-cols-3 @2xl:grid-cols-4 @4xl:grid-cols-5 @6xl:grid-cols-6 @7xl:grid-cols-7 @8xl:grid-cols-8 @9xl:grid-cols-9 @10xl:grid-cols-10 @11xl:grid-cols-11 @12xl:grid-cols-12 @13xl:grid-cols-13 @14xl:grid-cols-14"
      }>
        {!Array.isArray(itemData) || itemData.length === 0 ? (
          <div></div>
        ) : (
          itemData.map(item => (
            <Tile key={item.ipId} {...item} />
          ))
        )}
      </div>
    </div>
  );
};

export default TitleTiles;