import { dispatch } from "../../../../../app/store";
import {
  fetchFilteredTalentData,
  fetchTalentData,
} from "./talent_slice";

export async function getTalentData(from, size, range) {
  await dispatch(fetchTalentData({ from, size, range }));
}

export async function getFilteredTalentData(from, size, range, body) {
  await dispatch(fetchFilteredTalentData({ from, size, range, body }));
}
