import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-enterprise"
import { useGetGridTheme } from "../../../../hooks/UseTheme/useGetGridTheme";

const DetailCellRenderer = (props: any) => {
    const { episodes } = props.data;
    const { theme } = useGetGridTheme();

    const rowHeight = 35;
    const headerHeight = 50;

    // Sort episodes by season and episode number
    const sortedEpisodes = episodes ? [...episodes].sort((a, b) => {
        if (a.season_number === b.season_number) {
            return a.episode_number - b.episode_number;
        }
        return a.season_number - b.season_number;
    }) : [];

    const calculateTableHeight = (data: any[]) => {
        const dataLength = data?.length || 0;
        return (dataLength * rowHeight) + headerHeight;
    };

    const columnDefs = [
        {
            field: 'season_number',
            rowGroup: true,
            hide: true,
            cellRenderer: (params: any) => `Season ${params.value}`,
        },
        {
            field: 'episode_number',
            headerName: 'Episode',
            aggFunc: 'count',
            valueFormatter: (params: any) => {
                if (!params.node.group) {
                    return `Episode ${params.value}`;
                }
                return params.value;
            },
            width: 100,
        },
        {
            field: 'name',
            headerName: 'Episode Name',
            flex: 1
        },
        {
            field: 'imdb_vote_average',
            headerName: 'IMDB Rating',
            flex: 1,
            aggFunc: 'avg',
            valueFormatter: (params: any) => {
                if (params.node.group) {
                    return Number(params.value).toFixed(2);
                }
                return params.value;
            }
        },
        {
            field: 'imdb_vote_count',
            headerName: 'IMDB Votes',
            flex: 1,
            aggFunc: 'sum'
        },
        {
            field: 'release_date',
            headerName: 'Release Date',
            cellRenderer: (params: any) => {
                if (!params.node.group) {
                    return new Date(params.value).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
                }
                return '';
            },
            width: 120
        }
    ];

    const defaultColDef = {
        flex: 1,
        sortable: true,
        suppressHeaderMenuButton: true,
    };

    return (
        <div className={`${theme} p-4`} style={{ height: `${episodes?.length < 5 ? 300 : calculateTableHeight(episodes) + 35}px` }}>
            <AgGridReact
                columnDefs={columnDefs}
                rowData={sortedEpisodes}
                defaultColDef={defaultColDef}
                animateRows={true}
                rowHeight={rowHeight}
                headerHeight={headerHeight}
                groupDefaultExpanded={1}
                suppressAggFuncInHeader={true}
                autoGroupColumnDef={{
                    headerName: 'Season',
                    cellRenderer: 'agGroupCellRenderer',
                    cellRendererParams: {
                        suppressCount: true
                    }
                }}
            />
        </div>
    );
};

export default DetailCellRenderer;