import React from "react";
import {
  NavItemsWithId,
  navItems,
} from "../../../../layouts/common/menu/side-menu";
import { Layout } from "../../Layout";
import { MediaHome } from "../media/components/MediaHome";

export default function ExploreTelevision() {
  const breadcrumbs = [
    { text: "Explore", href: "/explore" },
    { text: "Series" },
  ];

  return (
    <Layout
      title="Series"
      navItems={navItems as NavItemsWithId}
      breadcrumbs={breadcrumbs}
      contentType="table"
      content={
        <MediaHome
          vertical="series"
          header="Series"
          headerAddButtonText="Add series"
          modalTitle="Add Series"
          modalPlaceholder="Search for a series or enter an IMDb ID"
          modalAutoCompletePlatform="imdb_series"
        />
      }
    />
  );
}
