import { Link, Spinner } from "@cloudscape-design/components";
import React, { FC, useCallback, useContext, useMemo, useState } from "react";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";
import { MdModeEdit } from "react-icons/md";
import { YoutubeVideosContext } from "../../Layout";
import { useUpdateVideos } from "../../api/hooks/useUpdateVideos";
import { AutoSuggestEditor } from "../../videos/components/AutoSuggestEditor";
import { ValueWithLabel } from "./ValueWithLabel";
import { VerticalIcon } from "../../../../components/VerticalIcon";
import { Vertical } from "../../../../types/verticalTypes";

type IPContainerProps = {
  ip: string;
  ipId: string;
  videoId: string;
};

export const IPContainer: FC<IPContainerProps> = ({ ip, ipId, videoId }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { mutateAsync: updateVideos } = useUpdateVideos();
  const { modifiedData } = useContext(YoutubeVideosContext);
  const [loading, setLoading] = useState<boolean>(false);

  const getIPType = (ipId: string) => {
    if (ipId.startsWith('film')) return Vertical.Movies;
    if (ipId.startsWith('series')) return Vertical.Series;
    if (ipId.startsWith('game')) return Vertical.Games;
    return '';
  };

  const handleSave = useCallback(() => {
    const updatedData = modifiedData[videoId];
    if (!updatedData || !updatedData.label || !updatedData.value) {
      console.error('No valid IP selected');
      return;
    }

    setLoading(true);
    updateVideos({
      videoId,
      payload: {
        ip: updatedData.label,
        ip_id: updatedData.value,
      },
    }).then(() => {
      setIsEdit(false);
      setLoading(false);
    }).catch((error) => {
      console.error('Error updating video:', error);
      setLoading(false);
    });
  }, [modifiedData, updateVideos, videoId]);

  const canSave = useMemo(() => {
    const updatedData = modifiedData[videoId];
    return updatedData && updatedData.label && updatedData.value;
  }, [modifiedData, videoId]);

  const displayIpId = modifiedData[videoId]?.value || ipId;
  const displayIp = modifiedData[videoId]?.label || ip;

  return (
    <ValueWithLabel label="Associated Title">
      <div className="flex items-center">
        {isEdit ? (
          <>
            <AutoSuggestEditor videoId={videoId} />
            {loading ? (
              <span className="ml-2">
                <Spinner />
              </span>
            ) : (
              <span className="ml-2 cursor-pointer text-blue-500 flex gap-2">
                <IoMdCheckmark 
                  onClick={handleSave} 
                  className={canSave ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'}
                />
                <IoMdClose onClick={() => setIsEdit(false)} />
              </span>
            )}
          </>
        ) : (
          <>
            {displayIpId ? (
              <>
                <span className="mr-1 h-4"><VerticalIcon vertical={getIPType(displayIpId)}/></span>
                <Link href={`/item/${displayIpId}`}>{displayIp}</Link>
              </>
            ) : (
              <span className="text-gray-400">Not Associated</span>
            )}
            <span className="ml-2 cursor-pointer" onClick={() => setIsEdit(true)}>
              <MdModeEdit className="text-blue-500" />
            </span>
          </>
        )}
      </div>
    </ValueWithLabel>
  );
};
