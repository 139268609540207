import { useQuery } from "react-query";
import { isSome } from "../../../../../../utils/sugarUtils";
import { QUERY_KEYS } from "../constants";
import { getTrendingRankingDataV2 } from "../request";

export type UseGetTrendingRankingProps = {
  startTs: number;
  endTs: number;
};

export const useGetTrendingRanking = (params: UseGetTrendingRankingProps) => {
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_TRENDING_RANKING_DATA, { ...params }],
    () => getTrendingRankingDataV2(params),
    {
      enabled:
        isSome(params.startTs) &&
        isSome(params.endTs),
    },
  );

  return { data, isLoading, error: error as any };
};
