import {
  Link,
  Multiselect,
  SpaceBetween
} from "@cloudscape-design/components";
import { ColDef, GridApi, TooltipRendererParams } from "ag-grid-community";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { rgb } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { format } from "date-fns";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CustomLoadingOverlay from "../../../../components/PantheonLoading";
import VideoTitleRenderer from "../../../../components/VideoTitleRenderer";
import { YOUTUBE_CATEGORIES } from "../../../../config-global";
import { useGetGridTheme } from "../../../../hooks/UseTheme/useGetGridTheme";
import shortenInteger from "../../../../utils/shortenInteger";
import { useGetVideoData } from "../api/hooks/useGetVideoData";
import { YouTubeVideoTrendsResponse } from "../api/types";

interface ProcessedVideoData extends YouTubeVideoTrendsResponse {
  rankTrendData: (number | null)[];
  viewCountChangeData: number[];
}

function createTooltipRenderer(type: 'rank' | 'view') {
  return function tooltipRenderer(params: TooltipRendererParams) {
    const { context, xValue } = params;
    const rowData = context.data;
    const dateStrings = Object.keys(rowData.trend_data).sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime()
    );
    const dateString = dateStrings[xValue];
    const date = new Date(dateString);
    const formattedDate = format(date, "dd MMM, yyyy");

    let tooltipContent = `<div class='tooltip-title'>${formattedDate}</div><div class='tooltip-content'>`;

    if (type === 'rank') {
      const rank = rowData.rankTrendData[xValue] === 0 ? "Not in top 200" : 201 - rowData.rankTrendData[xValue];
      tooltipContent += `<div>Rank: ${rank}</div>`;
    } else {
      const viewCount = rowData.trend_data[dateString].view_count;
      const viewChange = rowData.viewCountChangeData[xValue];
      tooltipContent += `
        <div>View Count: ${shortenInteger(viewCount)}</div>
        <div>View Change: ${viewChange > 0 ? "+" + shortenInteger(viewChange) : "No increase"}</div>
      `;
    }

    tooltipContent += `</div>`;

    return `
      <div class='dark:bg-sky-100 bg-sky-900 rounded-md p-2 text-slate-100 dark:text-slate-900'>
        ${tooltipContent}
      </div>
    `;
  };
}

const tooltipRankRenderer = createTooltipRenderer('rank');
const tooltipViewRenderer = createTooltipRenderer('view');

const VideoComponent: React.FC = () => {
  const [interval, setInterval] = useState<string>("weekly");
  const { data: videoData, isLoading } = useGetVideoData(interval);
  const { theme } = useGetGridTheme();
  const isDarkTheme = theme === 'ag-theme-quartz-dark';

  const gridApiRef = useRef<GridApi | null>(null);
  const [selectedCategories, setSelectedCategories] = useState([
    { label: "All", value: "All" },
  ]);
  const [processedVideoData, setProcessedVideoData] = useState<
    ProcessedVideoData[]
  >([]);
  const [isDataProcessing, setIsDataProcessing] = useState(false);
  const [maxViewCount, setMaxViewCount] = useState<number>(0);

  const processVideoData = useCallback(
    (data: YouTubeVideoTrendsResponse[]): ProcessedVideoData[] => {
      const processedData = data.map((video) => {
        const trendEntries = video?.trend_data
          ? Object.entries(video.trend_data).sort(
            ([dateA], [dateB]) =>
              new Date(dateA).getTime() - new Date(dateB).getTime(),
          )
          : [];
        const rankTrendData: number[] = [];
        const viewCountChangeData: number[] = [];
        let previousViewCount: number | null = null;

        trendEntries.forEach(([_, trend]) => {
          rankTrendData.push(trend.daily_rank > 0 ? 201 - trend.daily_rank : 0);

          if (previousViewCount !== null) {
            const change = Math.max(0, trend.view_count - previousViewCount);
            viewCountChangeData.push(change);
          } else {
            viewCountChangeData.push(0);
          }
          previousViewCount = trend.view_count;
        });

        return {
          ...video,
          avg_daily_rank: video.avg_daily_rank || null,
          rankTrendData,
          viewCountChangeData,
        };
      });

      const totalViews = processedData.reduce((sum, video) => sum + (video.view_count || 0), 0);

      const maxViews = Math.max(...processedData.map(video => video.view_count || 0));
      setMaxViewCount(maxViews);

      return processedData.map(video => ({
        ...video,
        viewPercentage: totalViews > 0 ? (video.view_count / totalViews) * 100 : 0
      }));
    },
    []
  );

  const getHeatmapColor = useCallback((value: number, max: number, startColor: string, endColor: string) => {
    const colorScale = interpolateRgb(startColor, endColor);
    const interpolatedColor = rgb(colorScale(value / max));
    return `rgb(${interpolatedColor.r}, ${interpolatedColor.g}, ${interpolatedColor.b})`;
  }, []);

  const getViewHeatmapColor = useCallback((viewCount: number) => {
    return getHeatmapColor(
      viewCount,
      maxViewCount,
      isDarkTheme ? "#0A211A" : "#e6f4ea",  
      isDarkTheme ? "#2d9474" : "#059669"
    );
  }, [maxViewCount, getHeatmapColor, isDarkTheme]);

  const getTextColor = useCallback((value: number, max: number) => {
    const intensity = value / max;
    return isDarkTheme 
      ? intensity > 0.2 ? '#ffffff' : '#d1d5db'
      : intensity > 0.4 ? '#ffffff' : '#000000';
  }, [isDarkTheme]);

  const handleCategoryChange = useCallback(({ detail }) => {
    const newSelectedOptions = detail.selectedOptions;

    // If "All" is being selected
    if (newSelectedOptions.some(option => option.value === "All") &&
      !selectedCategories.some(option => option.value === "All")) {
      setSelectedCategories([{ label: "All", value: "All" }]);
    }
    // If "All" is being deselected
    else if (!newSelectedOptions.some(option => option.value === "All") &&
      selectedCategories.some(option => option.value === "All")) {
      setSelectedCategories([]);
    }
    // If other categories are being selected/deselected
    else {
      const filteredOptions = newSelectedOptions.filter(option => option.value !== "All");
      setSelectedCategories(filteredOptions.length > 0 ? filteredOptions : [{ label: "All", value: "All" }]);
    }
  }, [selectedCategories]);

  useEffect(() => {
    if (Array.isArray(videoData) && videoData.length > 0) {
      setIsDataProcessing(true);
      let processedData = processVideoData(
        videoData as YouTubeVideoTrendsResponse[],
      );

      if (!selectedCategories.some((cat) => cat.value === "All")) {
        processedData = processedData.filter((video) =>
          selectedCategories.some((cat) => cat.value === video.category),
        );
      }

      processedData.sort((a, b) => {
        if (b.doc_count !== a.doc_count) {
          return b.doc_count - a.doc_count;
        }
        return (b.view_count || 0) - (a.view_count || 0);
      });

      setProcessedVideoData(processedData);
      setIsDataProcessing(false);
    }
  }, [videoData, selectedCategories, processVideoData]);

  const columnDefs = useMemo<ColDef[]>(
    () => [
      { field: "doc_count", headerName: "Times in top 200", maxWidth: 80 },
      {
        field: "avg_daily_rank",
        headerName: "Avg Daily Rank",
        valueFormatter: (params) =>
          params.value != null ? params.value.toFixed(0) : "N/A",
        maxWidth: 80,
      },
      {
        field: "title",
        headerName: "Video Title",
        flex: 2,
        minWidth: 300,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: [
            "contains",
            "notContains",
            "equals",
            "notEqual",
            "startsWith",
            "endsWith",
          ],
          defaultOption: "contains",
        },
        autoHeight: true,
        cellRenderer: VideoTitleRenderer,
      },
      {
        field: "channelTitle",
        headerName: "Channel",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: [
            "contains",
            "notContains",
            "equals",
            "notEqual",
            "startsWith",
            "endsWith",
          ],
          defaultOption: "contains",
        },
        cellRenderer: (params: any) => (
          <Link
            href={`https://www.youtube.com/channel/${params.data.channelId}`}
            external
          >
            {params.value}
          </Link>
        ),
      },
      { field: "category", headerName: "Category", maxWidth: 150 },
      {
        field: "view_count",
        headerName: "Views",
        valueFormatter: (params) => String(shortenInteger(params.value)),
        maxWidth: 100,
        cellStyle: (params) => ({
          backgroundColor: getViewHeatmapColor(params.value),
          color: getTextColor(params.value, maxViewCount)
        }),
      },
      {
        field: "like_count",
        headerName: "Likes",
        valueFormatter: (params) => shortenInteger(params.value),
        maxWidth: 100,
      },
      {
        field: "comment_count",
        headerName: "Comments",
        valueFormatter: (params) => shortenInteger(params.value),
        maxWidth: 110,
      },
      {
        field: "rankTrendData",
        headerName: "Rank Trend",
        cellRenderer: "agSparklineCellRenderer",
        sortable: false,
        cellRendererParams: {
          sparklineOptions: {
            type: "column",
            fill: "#1976d2",
            stroke: "#1976d2",
            paddingInner: 0.1,
            axis: { stroke: "#e0e0e0" },
            tooltip: { renderer: tooltipRankRenderer },
            yAxis: { type: "number", min: 0, max: 200 },
          },
        },
        minWidth: 150,
      },
      {
        field: "viewCountChangeData",
        headerName: "View Count Change",
        cellRenderer: "agSparklineCellRenderer",
        sortable: false,
        cellRendererParams: {
          sparklineOptions: {
            type: "column",
            fill: "#4caf50",
            stroke: "#4caf50",
            paddingInner: 0.3,
            padding: { top: 5, bottom: 5 },
            axis: { stroke: "#e0e0e0" },
            tooltip: { renderer: tooltipViewRenderer },
          },
        },
        minWidth: 150,
      },
      {
        field: "publishedOn",
        cellDataType: "date",
        cellRenderer: (params: any) => {
          const date = new Date(params.value);
          return format(date, "dd MMM, yyyy");
        },
      },
      {
        field: "viewPercentage",
        headerName: "% of Views",
        valueFormatter: (params) => `${params.value.toFixed(2)}%`,
        cellStyle: (params) => ({
          backgroundColor: getViewHeatmapColor(params.data.view_count),
          color: getTextColor(params.data.view_count, maxViewCount)
        }),
        maxWidth: 120,
      },
    ],
    [getViewHeatmapColor, getTextColor, maxViewCount]
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      filter: false,
      suppressHeaderMenuButton: true,
      autoHeaderHeight: true,
      wrapHeaderText: true,
      floatingFilter: true,
    }),
    [],
  );

  const handleIntervalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInterval(event.target.value);
    setIsDataProcessing(true);
  };

  const IntervalOptions = [
    { id: "half_yearly", title: "6 months" },
    { id: "quarterly", title: "3 months" },
    { id: "monthly", title: "1 month" },
    { id: "weekly", title: "7 days" },
  ];

  return (
    <SpaceBetween size="m" direction="vertical">
      <div className="flex flex-row justify-between bg-gradient-to-r from-slate-200 to-white dark:from-slate-800 dark:to-[#161D26] p-2 px-8 rounded-lg">
        <fieldset>
          <legend className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
            Interval
          </legend>
          <div className=" space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
            {IntervalOptions.map((intervalOption) => (
              <div key={intervalOption.id} className="flex items-center">
                <input
                  id={intervalOption.id}
                  name="interval-option"
                  type="radio"
                  value={intervalOption.id}
                  checked={interval === intervalOption.id}
                  onChange={handleIntervalChange}
                  className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                />
                <label
                  htmlFor={intervalOption.id}
                  className="ml-3 block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                >
                  {intervalOption.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <fieldset>
          <legend className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
            Category
          </legend>
          <Multiselect
            selectedOptions={selectedCategories}
            onChange={handleCategoryChange}
            options={YOUTUBE_CATEGORIES}
            placeholder="Filter by category"
            hideTokens
          />
        </fieldset>
      </div>
      <div className={`${theme} h-[75vh]`}>
        <AgGridReact
          rowData={processedVideoData}
          loading={isLoading || isDataProcessing}
          loadingOverlayComponent={CustomLoadingOverlay}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={(params) => (gridApiRef.current = params.api)}
          pagination={true}
          paginationPageSize={20}
        />
      </div>
    </SpaceBetween>
  );
};

export default VideoComponent;
