export const COLOR_MAP = {
  blue: {
    border: {
      light: "border-blue-600",
      dark: "border-blue-400",
    },
    text: {
      light: "text-blue-600",
      dark: "text-blue-400",
    },
    background: {
      light: "bg-blue-600",
      dark: "bg-blue-400",
    },
  },
  red: {
    border: {
      light: "border-red-600",
      dark: "border-red-400",
    },
    text: {
      light: "text-red-600",
      dark: "text-red-400",
    },
    background: {
      light: "bg-red-600",
      dark: "bg-red-400",
    },
  },
  green: {
    border: {
      light: "border-green-600",
      dark: "border-green-400",
    },
    text: {
      light: "text-green-600",
      dark: "text-green-400",
    },
    background: {
      light: "bg-green-600",
      dark: "bg-green-400",
    },
  },
  yellow: {
    border: {
      light: "border-yellow-600",
      dark: "border-yellow-400",
    },
    text: {
      light: "text-yellow-600",
      dark: "text-yellow-400",
    },
    background: {
      light: "bg-yellow-600",
      dark: "bg-yellow-400",
    },
  },
  purple: {
    border: {
      light: "border-purple-600",
      dark: "border-purple-400",
    },
    text: {
      light: "text-purple-600",
      dark: "text-purple-400",
    },
    background: {
      light: "bg-purple-600",
      dark: "bg-purple-400",
    },
  },
};