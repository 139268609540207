import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getTopSearchDataV2 } from "../request";

export const useGetTopSearchDataV2 = ({
  q="",
  verticals,
  limit,
}) => {
  const params = {
    q,
    verticals,
    limit,
  };

  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_TOP_SEARCH_DATA_V2, { ...params }],
    () => getTopSearchDataV2(params),
  );

  return {
    data: data?.data,
    isLoading,
    ...error,
  };
};
