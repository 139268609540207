export const idMap = new Map([
  ["name", "ip.keyword"],
  ["status", "status"],
  ["tracked", "tracked"],
  ["release_date", "release_date"],
  ["total_ips", "total_ips"],
  ["film_count", "film_count"],
  ["series_count", "series_count"],
  ["game_count", "game_count"],
  ["searchable", "searchable"],
]);

export const formatFilter = (token, operator) => {
  if (!token.propertyKey) {
    return {
      match_phrase: {
        ip: `*${token.value}*`,
      },
    };
  }

  switch (token.propertyKey) {
    case "name":
      return operator === "contain"
        ? {
            query_string: {
              default_field: "ip",
              query: `*${token.value}*`,
            },
          }
        : {
            match: {
              ip: {
                query: token.value,
                auto_generate_synonyms_phrase_query: false,
              },
            },
          };

    case "tracked":
      return {
        query_string: {
          default_field: "tracked",
          query: token.value,
        },
      };

    case "status":
      return {
        match: { status: token.value },
      };

    default:
      return null;
  }
};
