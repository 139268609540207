import { ContentLayout, Header } from "@cloudscape-design/components";
import React from "react";
import {
  NavItemsWithId,
  navItems,
} from "../../../layouts/common/menu/side-menu";
import { Layout } from "../Layout";
import { TrendingPageCards } from "./TrendingPageCards";

function TrackedHomePage() {
  const title = "Tracked Titles";
  const breadcrumbs = [{ text: "Ranking" }, { text: title }];

  return (
    <Layout
      title={title}
      breadcrumbs={breadcrumbs}
      navItems={navItems as NavItemsWithId}
      content={
        <ContentLayout
          disableOverlap
          header={
            <Header
              variant="h2"
              description="A collection of dashboards that provide insights into trending titles and their performance."
            >
              {title} 
            </Header>
          }
        >
          <TrendingPageCards />
        </ContentLayout>
      }
      contentType="cards"
    />
  );
}

export default TrackedHomePage;
