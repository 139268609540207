import moment from "moment";
import { useMemo, useState } from "react";
import {
  CHART_TYPES,
  useGetChartTheme,
} from "../../../../../hooks/UseTheme/useGetChartTheme";
import { utcTimestampToLocal } from "../../../../../utils/dateFormatter";
import {
  DEFAULT_DARK_STROKES,
  PLACEHOLDER_CROSSLINE_TYPE,
} from "../../../constants";
import { useCompareContext } from "../../../hooks/useCompareContext";
import { numberShortFormatter } from "../../../utils";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { CrosslineNote } from "../../../types";

const defaultOverrides = {
  overrides: {
    common: {
      axes: {
        number: {
          label: {
            fontFamily: "Open Sans",
          },
        },
        time: {
          label: {
            fontFamily: "Open Sans",
          },
        },
      },
      series: {
        highlightStyle: {
          series: {
            dimOpacity: 0.2,
            strokeWidth: 2,
          },
        },
      },
      navigator: {
        miniChart: {
          label: {
            fontFamily: "Open Sans",
          },
        },
      },
      legend: {
        item: {
          label: {
            fontFamily: "Open Sans",
          },
          line: { strokeWidth: 12 },
        },
      },
    },
  },
};

const groupSeries = (seriesData, groupBy) => {
  const grouped = {};

  seriesData.forEach((item) => {
    const key = moment.utc(item.timestamp_0).startOf(groupBy).unix();

    const timestampKeys = Object.keys(item).filter((k) =>
      k.startsWith("timestamp"),
    );
    const otherValueKeys = Object.keys(item).filter(
      (k) => !k.startsWith("timestamp"),
    );
    if (!grouped[key]) {
      grouped[key] = {
        ...otherValueKeys.reduce((acc, k) => ({ ...acc, [k]: 0 }), {}),
      };
      timestampKeys.forEach((k) => {
        grouped[key][k] = item[k];
      });
    }
    otherValueKeys.forEach((k) => {
      grouped[key][k] += item[k] ?? 0;
    });
  });

  const newSeriesData = Object.values(grouped).sort(
    (a: any, b: any) => a.timestamp_0 - b.timestamp_0,
  );

  return newSeriesData;
};

interface UseChartConfigProps {
  titleMetricInfo: Array<any>;
  setTitleMetricInfo: (titleMetricInfo: any) => void;
  crosslineData: Array<any>;
  selectedCrosslineTypes: Array<OptionDefinition>;
  selectedGranularity: OptionDefinition;
  selectedAxisType: OptionDefinition;
  chartHeight?: number;
  unitSuffix?: string;
  decimalDigits?: number;
  reverseYAxis?: boolean;
  chartBaseTheme?: string;
}

export const useChartConfig = ({
  titleMetricInfo,
  setTitleMetricInfo,
  crosslineData,
  selectedCrosslineTypes,
  selectedGranularity,
  selectedAxisType,
  chartHeight = 450,
  unitSuffix = "",
  decimalDigits = 2,
  reverseYAxis = false,
  chartBaseTheme,
}: UseChartConfigProps) => {
  const { theme } = useGetChartTheme(CHART_TYPES.DEFAULT, defaultOverrides);
  const {
    titles,
    activeSelectedMetrics,
    multiParamInfo,
    timeseriesData,
    state,
  } = useCompareContext();

  const [titleColors, setTitleColors] = useState<Record<string, string>>({});

  const dayMultiplier =
    selectedGranularity.value === "day"
      ? 1
      : selectedGranularity.value === "week"
      ? 7
      : 30;

  const seriesDef = useMemo<Array<any>>(() => {
    if (titles?.length === 0 || activeSelectedMetrics?.length === 0)
      return null;

    const titleMap = titles
      .map((title) => ({ [title.ip_id]: title }))
      .reduce((acc, obj) => ({ ...acc, ...obj }), {});
    const titleObjects = Object.values(titleMap);

    let i = 0;
    const titleColorMap = multiParamInfo
      .map((paramInfo, paramIndex) =>
        titleObjects
          .filter((title) => paramInfo.title_ids.includes(title.ip_id))
          .map((title) => ({
            [`${title.ip_id}_${paramIndex}`]:
              DEFAULT_DARK_STROKES[i++ % DEFAULT_DARK_STROKES.length],
          }))
          .reduce((acc, obj) => ({ ...acc, ...obj }), {}),
      )
      .reduce((acc, obj) => ({ ...acc, ...obj }), {});
    setTitleColors(titleColorMap);

    const series = multiParamInfo
      .map((paramInfo, i) =>
        titleObjects
          .filter((title) => paramInfo.title_ids.includes(title.ip_id))
          .map((title) =>
            activeSelectedMetrics.map((metric) => ({
              type: "line",
              xKey: `timestamp_${i}_chart`,
              yKey: `${title.ip_id}_${metric.key}`,
              yName: `${title.ip}`,
              marker: { enabled: false },
              stroke: titleColorMap[`${title.ip_id}_${i}`],
              metadata: { title, metric },
              tooltip: { enabled: false },
            })),
          )
          .flat(),
      )
      .flat();

    const titleMetricKeys = Object.values(
      series
        .map((s) => ({
          [s.metadata.title.ip_id]: {
            label: s.yName,
            value: s.yKey,
            metadata: s.metadata,
          },
        }))
        .reduce((acc, obj) => ({ ...acc, ...obj }), {}),
    );
    setTitleMetricInfo(titleMetricKeys);

    return series;
  }, [activeSelectedMetrics, titles, multiParamInfo]);

  const seriesData = useMemo<Array<any>>(() => {
    if (timeseriesData?.length === 0 || activeSelectedMetrics?.length === 0)
      return [];

    const newSeriesData = timeseriesData.map((d) => {
      const data = {};
      let validKeys = [];
      multiParamInfo.forEach((paramInfo, i) => {
        data[`timestamp_${i}`] = d.timestamp;
        data[`timestamp_${i}_chart`] = utcTimestampToLocal(d.timestamp, true);
        validKeys = [
          ...validKeys,
          ...titleMetricInfo
            .filter(
              (t) =>
                paramInfo.title_ids.includes(t.metadata.title.ip_id) &&
                d.timestamp >= paramInfo.start_ts &&
                d.timestamp <= paramInfo.end_ts,
            )
            .map((t) => t.value),
        ];
      });
      return {
        ...validKeys.reduce((acc, k) => ({ ...acc, [k]: d[k] }), {}),
        ...data,
      };
    });

    const groupedSeriesData = groupSeries(
      newSeriesData,
      selectedGranularity.value,
    );

    return groupedSeriesData;
  }, [
    timeseriesData,
    multiParamInfo,
    titleMetricInfo,
    activeSelectedMetrics?.length,
    selectedGranularity,
  ]);

  const crosslines = useMemo<Array<CrosslineNote>>(
    () =>
      multiParamInfo
        .map((paramInfo, paramIndex) =>
          crosslineData
            .filter(
              (c) =>
                paramInfo.title_ids.includes(c.ip_id) ||
                (paramIndex === 0 &&
                  c.type === PLACEHOLDER_CROSSLINE_TYPE.value),
            )
            .map((crossline) => ({
              type: "line",
              enabled: true,
              value: utcTimestampToLocal(crossline.value, true),
              trueValue: crossline.value,
              lineDash: [5, 5],
              strokeOpacity: 0.7,
              stroke:
                crossline.type === PLACEHOLDER_CROSSLINE_TYPE.value
                  ? "lightgray"
                  : titleColors[`${crossline.ip_id}_${paramIndex}`] ??
                    "lightgray",
              text: crossline.label,
              title_id: crossline.ip_id,
              title_name: crossline.ip_name,
              event_type: crossline.type,
              param_index: paramIndex,
              label: {
                enabled: false,
                text: `${crossline.ip_name}: ${crossline.label}`,
              },
              created_by: crossline.created_by,
              created_at: crossline.created_at,
            })),
        )
        .flat(),
    [crosslineData, titleColors],
  );

  // Very hacky to display multiple titles and crosslines in a tooltip using the crosshair label
  const crosshairTooltipRenderer = (params) => {
    const hoveredPointData = [];
    const hoveredMasterTimestamp = utcTimestampToLocal(
      params.value,
      false,
      selectedGranularity.value,
    );

    multiParamInfo.forEach((paramInfo, infoIndex) => {
      const hoveredTimestamp = hoveredMasterTimestamp + paramInfo.offset;
      const dataAtTimestamp = seriesData.find(
        (d) =>
          d[`timestamp_${infoIndex}_chart`] >= hoveredTimestamp &&
          d[`timestamp_${infoIndex}_chart`] <
            hoveredTimestamp + 86400000 * dayMultiplier,
      );

      if (!dataAtTimestamp) return;
      const dataAtPreviousTimestamp = seriesData.find(
        (d) =>
          d[`timestamp_${infoIndex}_chart`] ===
          hoveredTimestamp - 86400000 * dayMultiplier,
      );
      const isOnlyTimestampKeys =
        Object.keys(dataAtTimestamp).filter((k) => k.startsWith("timestamp"))
          .length === Object.keys(dataAtTimestamp).length;

      if (isOnlyTimestampKeys) return;
      const crosslinesAtTimestamp = crosslines.filter(
        (c: CrosslineNote) =>
          c.value >= hoveredTimestamp &&
          c.value < hoveredTimestamp + 86400000 * dayMultiplier &&
          paramInfo.title_ids.includes(c.title_id) &&
          selectedCrosslineTypes.map((c) => c.value).includes(c.event_type) &&
          c.param_index === infoIndex,
      );

      const labelDataAtTimestamp = titleMetricInfo
        .filter((t) => paramInfo.title_ids.includes(t.metadata.title.ip_id))
        .map((t) => ({
          title: t.metadata.title,
          value: dataAtTimestamp[t.value] ?? 0,
          difference: reverseYAxis
            ? 0 -
              ((dataAtTimestamp[t.value] ?? 0) -
                (dataAtPreviousTimestamp?.[t.value] ?? 0))
            : (dataAtTimestamp[t.value] ?? 0) -
              (dataAtPreviousTimestamp?.[t.value] ?? 0),
          color: titleColors[`${t.metadata.title.ip_id}_${infoIndex}`],
          metric: t.metadata.metric,
        }));

      hoveredPointData.push({
        timestamp: hoveredTimestamp,
        labelData: labelDataAtTimestamp,
        crosslines: crosslinesAtTimestamp,
      });
    });

    state.hoveredPointData = hoveredPointData;

    if (hoveredPointData.length === 0) return "";

    return `
      <div class="p-2 rounded-md flex flex-col space-y-4" style="background-color: rgba(51, 65, 85, 0.8); font-family: 'Open Sans';">
        ${hoveredPointData
          .map((hoveredPoint) => {
            return `
          <div>
            <div class="mb-1">${
              selectedGranularity.value === "week"
                ? moment.utc(hoveredPoint.timestamp).format("LL") +
                  " - " +
                  moment.utc(hoveredPoint.timestamp).endOf("week").format("LL")
                : selectedGranularity.value === "month"
                ? moment
                    .utc(hoveredPoint.timestamp)
                    .endOf("month")
                    .format("MMMM YYYY")
                : moment.utc(hoveredPoint.timestamp).format("LL")
            }</div>
            ${hoveredPoint.labelData
              .map((d) => {
                const roundedDiff =
                  Math.round(d.difference * (10.0 * decimalDigits)) /
                  (10.0 * decimalDigits);
                const trendIcon =
                  roundedDiff > 0
                    ? "&#9650;"
                    : roundedDiff < 0
                    ? "&#9660;"
                    : "&#8210;";
                const trendColor =
                  roundedDiff > 0
                    ? "text-green-500"
                    : roundedDiff < 0
                    ? "text-red-500"
                    : "text-neutral-300";
                return `<div key="${
                  d.title.ip
                }" class="w-full flex justify-between">
                <div class="flex items-center space-x-1">
                  <div style="background-color: ${
                    d.color
                  }; width: 10px; height: 3px; border-radius: 2px;"></div>
                  <b>${d.title.ip}</b> 
                </div>
                <div class="ml-3 flex space-x-2">
                  <span class="${trendColor}">
                    ${trendIcon}
                    ${Math.abs(d.difference).toLocaleString(undefined, {
                      maximumFractionDigits: decimalDigits,
                    })}
                  </span>
                  <span>${d.value.toLocaleString(undefined, {
                    maximumFractionDigits: decimalDigits,
                  })}${d.metric.unit ? ` ${d.metric.unit}` : ""}</span>
                </div>
              </div>`;
              })
              .join("")}
            ${
              hoveredPoint.crosslines?.length > 0
                ? `<div class="mt-2">
              ${hoveredPoint.crosslines
                .map(
                  (crossline) =>
                    `<div class="text-xs text-slate-300">${crossline.title_name}: <i>${crossline.text}</i></div>`,
                )
                .join("")}
              </div>`
                : ""
            }
          </div>
          `;
          })
          .join("")}
      </div>
    `;
  };

  const chartOptions = useMemo(
    () => ({
      height: chartHeight,
      series: seriesDef,
      data: seriesData,
      axes: [
        ...multiParamInfo.map((paramInfo, infoIndex) => {
          const endTsPadding =
            Math.max(
              ...multiParamInfo.map((inf) => inf.end_ts - inf.start_ts),
            ) -
            (paramInfo.end_ts - paramInfo.start_ts);
          const isEarliestAxis = paramInfo.offset === 0;
          const isFirstAxis = infoIndex === 0;
          return {
            type: "time",
            position: "bottom",
            title: { enabled: false },
            nice: false,
            label: {
              enabled: multiParamInfo.length === 1,
              format: "%b %-d, %Y",
            },
            tick: {
              enabled: multiParamInfo.length === 1,
            },
            interval: {
              minSpacing: 50,
              maxSpacing: 175,
            },
            line: { enabled: isFirstAxis },
            thickness: multiParamInfo.length === 1 ? 25 : 0,
            min: paramInfo.start_ts,
            max: paramInfo.end_ts + endTsPadding,
            keys: [`timestamp_${infoIndex}_chart`],
            crossLines: crosslines.filter(
              (c) =>
                (paramInfo.title_ids.includes(c.title_id) ||
                  (infoIndex === 0 &&
                    c.event_type === PLACEHOLDER_CROSSLINE_TYPE.value)) &&
                selectedCrosslineTypes
                  .map((c) => c.value)
                  .includes(c.event_type) &&
                c.param_index === infoIndex &&
                c.trueValue >= paramInfo.start_ts &&
                c.trueValue <= paramInfo.end_ts,
            ),
            crosshair: {
              enabled: isEarliestAxis,
              snap: false,
              label: {
                enabled: true,
                yOffset: -275,
                renderer: crosshairTooltipRenderer,
              },
            },
            //gridLine: {
            //  enabled: isFirstAxis,
            //  style: [
            //    {
            //      stroke: "rgba(255, 255, 255, 0.1)",
            //    },
            //  ],
            //},
          };
        }),
        {
          type: selectedAxisType.value,
          position: "left",
          reverse: reverseYAxis,
          label: {
            formatter: (params) => {
              const n = params.value;
              return numberShortFormatter(n, 2) + unitSuffix;
            },
          },
          crosshair: { enabled: false },
        },
      ],
      theme: {
        ...theme,
        baseTheme: chartBaseTheme ?? theme.baseTheme,
        ...(chartBaseTheme === "ag-default"
          ? {
              overrides: {
                common: {
                  background: { fill: "#FFFFFF" },
                },
              },
            }
          : {}),
      },
      navigator: {
        enabled: true,
        miniChart: {
          enabled: true,
          label: {
            enabled: multiParamInfo.length === 1,
          },
        },
      },
      annotations: {
        enabled: false,
        axesButtons: { enabled: false },
      },
      initialState: {
        annotations: crosslines
          .filter((c) =>
            selectedCrosslineTypes.map((c) => c.value).includes(c.event_type),
          )
          .map((c) => ({
            type: "vertical-line",
            value: {
              __type: "date",
              value: c.value,
            },
            axisLabel: {
              enabled: true,
              formatter: (params) => {
                return "rarg";
              },
              position: "top",
            },
            locked: true,
          })),
      },
      padding: {
        top: 30,
      },
    }),
    [
      seriesDef,
      seriesData,
      multiParamInfo,
      selectedAxisType,
      unitSuffix,
      reverseYAxis,
      crosslines,
      theme,
      chartBaseTheme,
    ],
  );

  return {
    chartOptions,
    crosslines,
  };
};
