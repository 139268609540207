import "ag-grid-enterprise";
import React from "react";
import { PlatformTrendsCharts } from "../../components/PlatformTrendsCharts";
import { getGoogleTrendsData } from "../../google/api/request";
import {
  defaultCategory as defCategory,
  googleCategoryOptions,
  googleApiParams,
} from "../../google/constants";
import {
  additionalPlatformColumns,
  corePlatformColumns,
  defaultColumn,
} from "../utils/gridConfig";

export const GoogleTrendsCharts = (
  {
    defaultCategory=defCategory,
    setSelectedVertical,
  }
) => {
  return (
    <PlatformTrendsCharts
      apiParams={googleApiParams}
      defaultColumn={defaultColumn}
      defaultCategory={defaultCategory}
      categoryOptions={googleCategoryOptions}
      corePlatformColumns={corePlatformColumns}
      fetchMethod={getGoogleTrendsData}
      setSelectedVertical={setSelectedVertical}
      additionalPlatformColumns={additionalPlatformColumns}
    />
  );
};
