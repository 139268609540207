import React from "react";
import { GRPantheonRankingIndex } from "../../GRPantheonRankingIndex";
import { Vertical } from "../../../../../../types/verticalTypes";

export const GRGaming = () => {
  return (
    <GRPantheonRankingIndex
      vertical={Vertical.Games}
      title="Games"
    />
  );
};
