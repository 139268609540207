import React from "react";
import {
  NavItemsWithId,
  navItems,
} from "../../../../layouts/common/menu/side-menu";
import { Layout } from "../../Layout";
import { TalentHome } from "./components/TalentHome";

const breadcrumbs = [
  { text: "Explore", href: "/explore" },
  { text: "Talent" },
];

export default function ExploreTalent({
  itemData,
  onTalentCreateSubmit,
  ...props
}) {
  return (
    <Layout
      title="Explore Talent"
      navItems={navItems as NavItemsWithId}
      breadcrumbs={breadcrumbs}
      contentType="table"
      content={<TalentHome itemData={itemData} />}
    />
  );
}
