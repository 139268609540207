import moment from "moment";
import { DatapointComparisonOptions, MetricGroup } from "./types";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";

export const PREFERENCE_PATH = "datapointComparison.preferences";

export const CROSSLINE_TYPE_OPTIONS: Array<OptionDefinition> = [
  { label: "Release date", value: "released", description: "A title was first released" },
  { label: "Added to or removed from a platform", value: "added_removed_service", description: "A title was added to or removed from a streaming service or gaming platform" },
  { label: "Season released", value: "season_released", description: "A season of a television series was released" },
  { label: "Game DLC/expansion released", value: "dlc_expansion_released", description: "A game's DLC or expansion was released" },
  { label: "Custom events", value: "custom_event", description: "Events created by users of the platform" },
];

export const PLACEHOLDER_CROSSLINE_TYPE: OptionDefinition = {
  label: "Placeholder",
  value: "_placeholder",
};

export const METRIC_GROUPS: Record<string, MetricGroup> = {
  ZSCORE: {
    name: "Z-Score Metrics",
    key: "zscore",
  },
  PLATFORM: {
    name: "Platform Metrics",
    key: "platform",
  },
  CUSTOM: {
    name: "Custom Metrics",
    key: "custom",
  },
  GOOGLE: {
    name: "Google Search Metrics",
    key: "google",
  },
};

export const DEFAULT_PREFERENCES = {
  timeseriesChart: {
    notesEnabled: true,
    axisType: "number",
    enabledCrosslineTypes: CROSSLINE_TYPE_OPTIONS.map(option => option.value),
  },
};

export const DEFAULT_OPTIONS: DatapointComparisonOptions = {
  table: {
    enabled: true,
  },
  parameters: {
    enabled: true,
    variant: "full",
    showDateRanges: true,
  },
  chart: {
    enabled: true,
    height: 450,
    chartNotes: {
      enabled: true,
    },
    addTimelineEvents: {
      enabled: true,
    },
  },
  container: {
    disablePadding: false,
    disableBorder: false,
  },
  summary: {
    enabled: false,
  },
};

export const DEFAULT_RESPONSE_STATE = {
  data: null,
  loading: false,
  completed: false,
  error: null,
};

export const DEFAULT_DATE_RANGE: DateRange = {
  type: "relative",
  startDate: moment()
    .subtract(1, "year")
    .startOf("day")
    .format("YYYY-MM-DD"),
  endDate: moment().format("YYYY-MM-DD"),
  unit: "year",
  amount: 1,
};

export const DEFAULT_DARK_STROKES = [
  '#74a8e6',
  '#ffbe70',
  '#6cb176',
  '#75d4ef',
  '#f6e559',
  '#aa86d8',
  '#a1a1a1',
  '#ce7ab9',
  '#997b52',
  '#ff7872',
];