import { CHART_LABELS } from "../../hooks/useGridConfig";

export const defaultColumn = CHART_LABELS.SEARCH_TREND;

export const additionalPlatformColumns = [
  CHART_LABELS.FRANCHISE,
  CHART_LABELS.PLATFORM,
  CHART_LABELS.GENRE,
  CHART_LABELS.THEME,
  CHART_LABELS.CUSTOM_TAGS,
  CHART_LABELS.DEVELOPER,
  CHART_LABELS.PUBLISHER,
  CHART_LABELS.AGE_RATINGS,
  CHART_LABELS.COUNTRIES,
  CHART_LABELS.PLAYER_PERSPECTIVE,
  CHART_LABELS.GAME_MODE,
  CHART_LABELS.DISTRIBUTORS,
  CHART_LABELS.PRODUCTION,
  CHART_LABELS.OTT,
  CHART_LABELS.SEARCH_TREND,
];

export const corePlatformColumns = [
  CHART_LABELS.SEARCH_VOLUME,
  CHART_LABELS.WEEKLY_SEARCH_VOLUME,
  CHART_LABELS.MONTHLY_SEARCH_VOLUME,
];
