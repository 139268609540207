import { Icon, Link } from "@cloudscape-design/components";
import React from "react";
import { VerticalIcon } from "../../../../../../../components/VerticalIcon";

export const useGridConfig = () => {
  const defaultColDef = {
    sortable: true,
    flex: 1,
    suppressHeaderMenuButton: true,
    suppressHeaderFilterButton: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    minWidth: 100,
    suppressMovable: true,
  };

  const columnDefs = [
    {
      field: "ip",
      headerName: "Title",
      minWidth: 300,
      flex: 1,
      pinned: "left",
      suppressHeaderMenuButton: true,
      cellRenderer: (params) => {
        if (!params.value) return null;
        const ipId = params.data.ip_id;
        const release_year = params.data.release_date ? new Date(Date.parse(params.data.release_date)).getFullYear() : "N/A";
        return (
          <div className="flex gap-2 mt-2.5 align-middle">
            <span className="text-slate-500 flex justify-center">
              <VerticalIcon vertical={params.data.vertical} />
            </span>
            <Link href={`/item/${ipId}`}>
              <span>{params.data.ip}</span>
            </Link>
            <span className="text-xs text-slate-500 mt-0.5">
              {release_year}
            </span>
          </div>
        );
      },
    },
    {
      field: "variants",
      headerName: "Variants",
      cellDataType: "text",
      valueGetter: (params) => params.data.variants.join(","),
      valueSetter: (params) => {
        params.data.variants = params.newValue.split(",");
        return true;
      },
      cellRenderer: (params) => (
        <div className="flex gap-2 items-center h-full leading-none">
          {params.data?.variants?.map(variant => (
            <div className="px-2 py-1 border border-blue-400 text-blue-400 rounded-full text-xs">{variant}</div>
          ))}
        </div>
      ),
      editable: true,
    },
  ] as any;

  return {
    defaultColDef,
    columnDefs
  };
};