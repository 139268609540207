import {
  Box,
  Button,
  FormField,
  Input,
  Modal,
  Multiselect,
  SpaceBetween,
  Textarea,
} from "@cloudscape-design/components";
import { yupResolver } from "@hookform/resolvers/yup";
import DOMPurify from "dompurify";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import FormProvider from "../../../../../components/forms/FormProvider";
import { Vertical } from "../../../../../types/verticalTypes";
import { useGetTopSearchDataV2 } from "../../../../../components/CommonSearch/api/hooks/useGetTopSearchDataV2";
import { convertData } from "../../../../../components/CommonSearch/CommonSearch";

const franchiseCreateFormValidationSchema = Yup.object().shape({
  title: Yup.string().required("Title field is required"),
  description: Yup.string().required("Description field is required"),
});

export const FranchiseCreateModal = ({
  visible = false,
  onDiscard = () => {},
  title = "",
  description = "",
  onSubmit = () => {},
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [query, setQuery] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data, isLoading } = useGetTopSearchDataV2({
    q: query,
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games],
  });

  const methods = useForm({
    defaultValues: {
      title,
      description,
    },
    mode: "all",
    reValidateMode: "all",
    resolver: yupResolver(franchiseCreateFormValidationSchema),
  });
  const { control, handleSubmit, setValue, reset } = methods;

  const onFormSubmit = async (data) => {
    setIsSubmitting(true);
    const payload = {
      name: data.title,
      summary: DOMPurify.sanitize(data.description),
      added: selectedItems.map((item) => item.value),
    };
    try {
      await onSubmit(payload);
    } catch (error) {
      console.error("Form submission failed:", error);
    }
    reset();
  };

  const handleButtonClick = () => {
    handleSubmit(onFormSubmit)();
  };

  return (
    <Modal
      size="large"
      visible={visible}
      onDismiss={onDiscard}
      header={"Create Franchise"}
      closeAriaLabel="Close dialog"
      footer={
        <div className="py-2 flex flex-row w-full justify-end">
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={(e) => {
                  e.preventDefault();
                  onDiscard();
                }}
              >
                {" "}
                Cancel{" "}
              </Button>
              <Button
                variant="primary"
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={!isSubmitting ? handleButtonClick : undefined}
              >
                {isSubmitting ? "Submitting" : "Submit"}
              </Button>
            </SpaceBetween>
          </Box>
        </div>
      }
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onFormSubmit)}>
        <SpaceBetween direction="vertical" size="m">
          <Controller
            name={"title"}
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <FormField label="Title" stretch errorText={error?.message}>
                <Input
                  {...field}
                  value={field.value}
                  invalid={invalid}
                  onChange={({ detail }) => field.onChange(detail.value)}
                  placeholder="Enter franchise name"
                />
              </FormField>
            )}
          />

          <FormField stretch label="Associate Titles">
            <Multiselect
              selectedOptions={selectedItems}
              options={(data && data.results?.length > 0) ? convertData(data.results) : []}
              onLoadItems={({ detail }) => setQuery(detail.filteringText)}
              onChange={({ detail }) => setSelectedItems(detail.selectedOptions)}
              statusType={isLoading ? "loading" : "finished"}
              loadingText="Loading"
              placeholder="Search for titles"
              filteringType="manual"
              filteringResultsText={(count) => `${count} results found`}
            />
          </FormField>

          <Controller
            name={"description"}
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <FormField stretch label="Description" errorText={error?.message}>
                <Textarea
                  {...field}
                  value={field.value}
                  invalid={invalid}
                  onChange={({ detail }) => field.onChange(detail.value)}
                  autoComplete="on"
                  rows={10}
                  spellcheck={true}
                />
              </FormField>
            )}
          />

        </SpaceBetween>
      </FormProvider>
    </Modal>
  );
};
