import { Icon, Link } from "@cloudscape-design/components";
import React from "react";

export const columnDefinitions = [
  {
    id: "image",
    header: "",
    width: 120,
    minWidth: 120,
    cell: (e) => {
      if (!e.image) {
        return (
          <div style={{
            width: '100px',
            height: '100px',
            borderRadius: '4px',
            backgroundColor: '#f2f3f3',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Icon name="status-info" variant="subtle" size="big" />
          </div>
        );
      }
      return (
        <img 
          src={e.image} 
          alt={e.name} 
          style={{
            width: '100px',
            height: '100px',
            borderRadius: '4px',
            objectFit: 'cover'
          }}
          loading="lazy"
        />
      );
    },
  },
  {
    id: "name",
    header: "Name",
    width: 450,
    minWidth: 300,
    cell: (e) => {
      return (
        <div className="flex items-center gap-4">
          <div className="flex flex-col gap-1">
            <div className="flex gap-2 align-top items-center">
              <Link
                href={`${window.location.origin}/item/talent/${e.nameId}`}
                
                variant="secondary"
              >
                <span>{e.name}</span>
              </Link>
            </div>
          </div>
        </div>
      );
    },
    sortingField: "name",
  },
  {
    id: "main_category",
    header: "Role",
    minWidth: 100,
    maxWidth: 150,
    cell: (e) => {
      return e.main_category ? (
        <span>
          {e.main_category.charAt(0).toUpperCase() + e.main_category.slice(1)}
          {e[e.main_category] ? ` (${e[e.main_category]})` : ''}
        </span>
      ) : null;
    },
    sortingField: "main_category",
  },
  {
    id: "category",
    header: "Other Roles", 
    minWidth: 100,
    maxWidth: 150,
    cell: (e) => {
      return (
        <div className="flex flex-col">
          {e.category.map((cat, index) => (
            <div key={index}>
              {cat.charAt(0)?.toUpperCase() + cat.slice(1)}
              {e[cat] ? ` (${e[cat]})` : ''}
            </div>
          ))}
        </div>
      );
    },
  }
];
