import axiosInstance from "../../../../../utils/axios";

export const getTitleSearchData = ({
  queryString="",
}) => axiosInstance.get(
  `topsearch?keyword=${queryString}&fields=ip,ip_id,vertical,image_url,release_date&vertical=Movies,Television,Gaming`,
);

export const getTitleSearchByIdData = ({
  ipIds=[],
}) => axiosInstance.get(
  `ipmanagement/v2/iplist?id=${ipIds.join(",")}&include=ip,ip_id,vertical,image_url,release_date`,
);