import {
  Container,
  Header,
  SpaceBetween
} from "@cloudscape-design/components";
import "ag-charts-enterprise";
import { AgCharts } from "ag-charts-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from "react";
import {
  CHART_TYPES,
  useGetChartTheme,
} from "../../../../../hooks/UseTheme/useGetChartTheme";
import { useGetGridTheme } from "../../../../../hooks/UseTheme/useGetGridTheme";
import shortenInteger from "../../../../../utils/shortenInteger";
import RottenTomatesAudienceScore from "../../components/Scores/RTAudienceScore";
import RottenTomatesCriticScore from "../../components/Scores/RTCriticScore";

function ImdbPlot({ itemData }) {
  const { theme } = useGetChartTheme(CHART_TYPES.VIVID);
  const [options, setOptions] = useState({});

  useEffect(() => {
    const episodes = itemData.flatMap((season) =>
      season.episodes.map((episode) => ({
        ...episode,
        season: season.season_number,
      })),
    );

    const seasonAverages = Array.from(
      episodes.reduce((acc, d) => {
        if (d.imdb_vote_average != null && d.imdb_vote_count != null) {
          if (!acc.has(d.season)) {
            acc.set(d.season, { sumRatings: 0, voteCount: 0, episodeCount: 0 });
          }
          const seasonInfo = acc.get(d.season);
          seasonInfo.sumRatings += d.imdb_vote_average;
          seasonInfo.voteCount += d.imdb_vote_count;
          seasonInfo.episodeCount++;
          acc.set(d.season, seasonInfo);
        }
        return acc;
      }, new Map()),
    )
      .map(([season, { sumRatings, voteCount, episodeCount }]) => ({
        season,
        imdb_vote_average: episodeCount > 0 ? sumRatings / episodeCount : null,
        voteCount,
      }))
      .sort((a, b) => a.season - b.season); // Sort the season averages by season

    const chartData = seasonAverages.map(
      ({ season, imdb_vote_average, voteCount }) => ({
        season: `${season}`,
        category: "IMDb Rating",
        rating: imdb_vote_average,
        voteCount: shortenInteger(voteCount),
      }),
    );

    setOptions({
      theme: theme,
      data: chartData,
      series: [
        {
          type: "heatmap",
          xKey: "season",
          xName: "Season",
          yKey: "category",
          yName: "Category",
          colorKey: "rating",
          colorName: "Rating",
          label: {
            color: "black",
            formatter: ({ datum }) =>
              datum.rating.toFixed(1) ? `${datum.rating.toFixed(1)}` : "N/A",
          },
          tooltip: {
            renderer: (params) => {
              const { datum } = params;
              return {
                title: `<span style="color: black; font-weight: bold">Season ${datum.season}</span>`,
                content: `<b>IMDb Rating</b>: ${datum.rating.toFixed(1)}
                               <p><b> Vote Count</b>: ${datum.voteCount}</p>`,
              };
            },
          },
        },
      ],
      gradientLegend: {
        gradient: {
          thickness: 6,
          preferredLength: 400,
        },
        spacing: 25,
        position: "right",
      },
      axes: [
        {
          type: "category",
          position: "bottom",
          label: {
            enabled: true,
          },
          title: {
            text: "Season →",
          },
        },
        {
          type: "category",
          position: "left",
          label: {
            enabled: false,
          },
        },
      ],
    });
  }, [itemData]);

  return <AgCharts options={options} style={{ height: "100%" }} />;
}

const SeasonsGrid = ({ itemData }) => {
  const { theme } = useGetGridTheme();
  const [sortedSeasons, setSortedSeasons] = useState([]);
  const [gridHeight, setGridHeight] = useState('160px'); // Default height

  useEffect(() => {
    const processedSeasons = itemData.map(season => {
      const episodes = season.episodes || [];
      const validEpisodes = episodes.filter(ep => ep.imdb_vote_average != null && ep.imdb_vote_count != null);
      
      const totalVotes = validEpisodes.reduce((sum, ep) => sum + ep.imdb_vote_count, 0);
      const averageRating = validEpisodes.length > 0
        ? validEpisodes.reduce((sum, ep) => sum + ep.imdb_vote_average, 0) / validEpisodes.length
        : null;

      return {
        ...season,
        imdb_vote_count: totalVotes,
        imdb_vote_average: averageRating,
        episode_count: episodes.length  // Add this line
      };
    });

    const sorted = processedSeasons.sort((a, b) => a.season_number - b.season_number);
    setSortedSeasons(sorted);

    const rowHeight = 40; 
    const headerHeight = 51; 
    const minHeight = 160;
    const calculatedHeight = Math.max((sorted.length * rowHeight) + headerHeight, minHeight);
    setGridHeight(`${calculatedHeight}px`);
  }, [itemData]);

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    sortable: true,
    suppressHeaderMenuButton: true,
  }

  const [columnDefs] = useState([
    { field: 'season_number', headerName: 'Season', maxWidth: 110 , cellRenderer: (params) => `Season ${params.value}`},
    { field: 'episode_count', headerName: 'Episodes', maxWidth: 100 },
    {
      field: 'release_date', 
      headerName: 'Air Date',
      cellRenderer: (params) => {
        if (!params.value) return 'N/A';
        const date = new Date(params.value);
        const day = date.getDate().toString().padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        return `${day} ${month}, ${year}`;
      }
    },
    { 
      field: 'imdb_vote_average', 
      headerName: 'IMDb Rating', 
      cellRenderer: (params) => params.value ? params.value.toFixed(1) : 'N/A'
    },
    { 
      field: 'imdb_vote_count', 
      headerName: 'IMDb Votes', 
      cellRenderer: (params) => params.value ? shortenInteger(params.value) : 'N/A'
    },
    { field: 'rtCriticSummary', headerName: 'Rotten Tomatoes Critic Score', sortable: false, cellRenderer: (params) => <RottenTomatesCriticScore rtCriticSummary={params.value} compact={true} /> },
    { field: 'rtFanSummary', headerName: 'Rotten Tomatoes Audience Score', sortable: false, cellRenderer: (params) => <RottenTomatesAudienceScore rtFanSummary={params.value} compact={true} /> },
  ]);

  return (
    <SpaceBetween direction="vertical" size="m">
      {/* <Container
        header={<Header variant="h3">IMDb Season Ratings Heatmap</Header>}
      > */}
        <div className="h-36">
          <ImdbPlot itemData={itemData} />
        </div>
      {/* </Container> */}
      <div className={`${theme}`} style={{ height: `${(sortedSeasons.length * 50) + 51}px` }}>
        <AgGridReact
          rowData={sortedSeasons}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowHeight={50}
        />
      </div>
    </SpaceBetween>
  );
};

export default SeasonsGrid;
