import { useMutation } from "react-query";
import { createMedia } from "../request";
import { mapCreateMediaResponse } from "../mappers/mapCreateMediaResponse";
import React from "react";

interface UseCreateMediaProps {
  vertical: string;
  onSuccess: () => void;
  onError: (error: { message: string; link?: string; linkText?: string }) => void;
}

interface GameData {
  ip: string;
  genre: string[];
  image_url: string;
  summary: string;
  release_date: string;
  ip_id: string;
}

interface GamesResponse {
  data: {
    added: GameData[];
    failed: string[];
    skipped: {
      ip: string;
      ip_id: string;
      igdb_id: number;
    }[];
  };
}

interface OtherVerticalResponse {
  data: any;
}

type ApiResponse = GamesResponse | OtherVerticalResponse;

export const useCreateMedia = ({
  onSuccess,
  onError,
  vertical,
}: UseCreateMediaProps) => {
  const { isLoading, mutate, error, data } = useMutation<ApiResponse, Error, string | number | number[]>(
    (id) => createMedia(id, vertical),
    {
      onSuccess: (response) => {
        if (vertical === "games") {
          const gamesData = (response as GamesResponse).data;
          if (gamesData.added && gamesData.added.length > 0) {
            onSuccess();
          } else if (gamesData.skipped && gamesData.skipped.length > 0) {
            onError({
              message: "Game is already in the database",
              link: `/item/${gamesData.skipped[0].ip_id}`,
              linkText: `View game (${gamesData.skipped[0].ip})`
            });
            return null;
          } else if (gamesData.failed && gamesData.failed.length > 0) {
            onError({ message: "Failed to add game" });
            return null;
          } else {
            onError({ message: "No action was taken" });
            return null;
          }
        } else {
          onSuccess();
        }
      },
      onError: (error: Error) => {
        onError({ message: error.message });
      },
    },
  );

  const processedData = React.useMemo(() => {
    if (!data) return undefined;

    if (vertical === "games") {
      const gamesData = (data as GamesResponse).data;
      return gamesData.added && gamesData.added.length > 0
        ? {
            ip: gamesData.added[0].ip,
            genre: gamesData.added[0].genre,
            url: gamesData.added[0].image_url 
              ? `https://images.igdb.com/igdb/image/upload/t_cover_big/${gamesData.added[0].image_url}.jpg` 
              : '',
            summary: gamesData.added[0].summary,
            releaseDate: gamesData.added[0].release_date,
            status: "Added",
            ip_id: gamesData.added[0].ip_id,
          }
        : null; // Return null instead of undefined for skipped/failed cases
    } else {
      return data.data ? mapCreateMediaResponse(data.data) : null;
    }
  }, [data, vertical]);

  return {
    data: processedData,
    mutate,
    isLoading,
    error,
  };
};
