import { useCallback, useEffect, useState } from "react";

interface UseFetchServerSideGridDataProps {
  gridRef: any;
  fetchMethod: any;
  searchQuery?: string;
}

export const useFetchServerSideGridData = ({
  gridRef,
  fetchMethod,
  searchQuery,
}: UseFetchServerSideGridDataProps) => {  
  const createServer = useCallback(() => {
    return {
      getData: async (request) => {
        const offset = request.startRow;
        const paginationPageSize = request.endRow - request.startRow;

        const requestedRows = await fetchMethod({
          offset,
          limit: paginationPageSize,
          q: searchQuery,
          enabled: true,
        });

        return {
          success: true,
          rows: requestedRows?.results ?? [],
          total: requestedRows?.total ?? 0,
        };
      },
    };
  }, [searchQuery]);

  const createServerSideDatasource = (server) => {
    return {
      getRows: async (params) => {
        const response = await server.getData(params.request);

        if (response.success) {
          params.success({
            rowData: response.rows,
            rowCount: response.total,
          });
        } else {
          params.fail();
        }
      },
    };
  };

  useEffect(() => {
    const server = createServer();
    const dataSource = createServerSideDatasource(server);
    gridRef?.current?.api?.setGridOption("serverSideDatasource", dataSource);
  }, [createServer, gridRef?.current]);
};
