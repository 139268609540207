import moment from "moment";
import { Datapoint, DatapointComparisonProperties, Metric } from "./types";

export const getTimeseriesForMetric = (metric: Metric, rawData) => {
  const averagedData = {} as any;
  metric.datapoints.forEach((datapoint: Datapoint) => {
    let datapointData = rawData.find(d => d.platform_name === datapoint.platform.key && d.datapoint_name === datapoint.datapoint.key)?.data ?? [];
    if (datapoint.difference) {
      datapointData = datapointData.map((d, i) => {
        if (i === 0) return d;
        const previous = datapointData[i - 1];
        const newD = { timestamp: d.timestamp };
        Object.keys(d).filter(k => k !== "timestamp").forEach(k => {
          newD[k] = d[k] - previous[k];
        });
        return newD;
      });
    }
    const datapointWeight = datapoint.weight;
    datapointData.forEach(d => {
      const timestamp = moment.utc(d.timestamp * 1000).startOf("day").unix() * 1000;
      Object.keys(d).filter(k => k !== "timestamp").forEach(k => {
        if (!averagedData[timestamp]) {
          averagedData[timestamp] = { timestamp };
        }
        if (!averagedData[timestamp][k]) {
          averagedData[timestamp][k] = 0;
        }
        if (typeof datapointWeight === "object") {
          // If weight is a list, find the list item that matches the key
          const matchedWeight = datapointWeight.find(w => k.startsWith(w.idPrefix));
          if (matchedWeight && matchedWeight.weight) {
            averagedData[timestamp][k] += d[k] * matchedWeight.weight;
          }
        } else if (typeof datapointWeight === "number") {
          averagedData[timestamp][k] += d[k] * datapointWeight;
        }
      });
    });
  });
  
  const timeseriesData = Object.values(averagedData).sort((a: any, b: any) => a.timestamp - b.timestamp);

  return timeseriesData;
};

export const getTimeseriesForMetrics = (metrics: Array<Metric>, rawData: any) => {
  const timeseriesData = {} as any;

  metrics.forEach(metric => {
    const metricData = getTimeseriesForMetric(metric, rawData);
    const maxValue = Math.max(...metricData.map(d => Math.max(...Object.keys(d).filter(k => k !== "timestamp").map(k => d[k]))));
    const normalizedData = (metric.normalize === true) ? metricData.map(d => {
      Object.keys(d).filter(k => k !== "timestamp").forEach(k => {
        d[k] = d[k] / maxValue * 100.0;
      });
      return d;
    }) : metricData;
    normalizedData.forEach((d: any) => {
      if (!timeseriesData[d.timestamp]) {
        timeseriesData[d.timestamp] = { timestamp: d.timestamp };
      }
      Object.keys(d).filter(k => k !== "timestamp").forEach(k => {
        const metricTitleKey = `${k}_${metric.key}`;
        if (!timeseriesData[d.timestamp][metricTitleKey]) {
          timeseriesData[d.timestamp][metricTitleKey] = 0;
        }
        timeseriesData[d.timestamp][metricTitleKey] += d[k] ?? 0;
      });
    });
  });

  const finalTimeseriesData = Object.values(timeseriesData).sort((a: any, b: any) => a.timestamp - b.timestamp);

  return finalTimeseriesData;
};

export const numberShortFormatter = (n, decimalPlaces=1) => {
  const factor = Math.pow(10, decimalPlaces);
  n = parseFloat(n);
  if (n > 0.0 && n < 1.0) return (Math.round(n * factor) / factor);
  if (n >= 1.0 && n < 1e3) return (Math.round(n * factor) / factor);
  if (n >= 1e3 && n < 1e6) return (Math.round(n / 1e3 * factor) / factor) + "K";
  if (n >= 1e6 && n < 1e9) return (Math.round(n / 1e6 * factor) / factor) + "M";
  if (n >= 1e9 && n < 1e12) return (Math.round(n / 1e9 * factor) / factor) + "B";
  if (n >= 1e12) return (Math.round(n / 1e12 * factor) / factor) + "T";
  return (Math.round(n * factor) / factor);
};

export const urlParamsFromParameters = (obj: DatapointComparisonProperties): string => {
  const newObject = {} as any;
  if (obj.activeMetricKey) newObject.activeMetricKey = obj.activeMetricKey;
  if (obj.metricKeys) newObject.metricKeys = obj.metricKeys.join(",");
  if (obj.parameters) newObject.parameters = JSON.stringify(obj.parameters.map(p => {
    const paramObj = {} as any;
    if (p.dateRange) paramObj.dateRange = `${p.dateRange.startDate} ${p.dateRange.endDate}`;
    if (p.titleIds) paramObj.titleIds = p.titleIds.join(",");
    return paramObj;
  }));
  return new URLSearchParams(newObject).toString();
};

export const parametersFromUrlParams = (params: URLSearchParams): DatapointComparisonProperties => {
  if (params.size === 0) return null;
  const activeMetricKey = params.get("activeMetricKey") ?? null;
  const metricKeys = params.get("metricKeys") ? params.get("metricKeys").split(",") : null;
  const parameters = params.get("parameters") ? JSON.parse(params.get("parameters")).map(p => {
    const paramObj = {} as any;
    if (p.dateRange) paramObj.dateRange = {
      startDate: p.dateRange.split(" ")[0],
      endDate: p.dateRange.split(" ")[1],
    };
    if (p.titleIds) paramObj.titleIds = p.titleIds.split(",");
    return paramObj;
  }) : null;
  const newParams = {} as any;
  if (activeMetricKey) newParams.activeMetricKey = activeMetricKey;
  if (metricKeys) newParams.metricKeys = metricKeys;
  if (parameters) newParams.parameters = parameters;
  return newParams;
};

export const getImageUrl = (vertical, ip_id, image_url) => {
  switch (vertical) {
    case "Movies":
      return `https://image.tmdb.org/t/p/w92/${image_url}`;
    case "Television":
      return `https://image.tmdb.org/t/p/w92/${image_url}`;
    case "Gaming":
      return `https://images.igdb.com/igdb/image/upload/t_cover_big/${image_url}.png`;
    case "Franchise":
      return `https://images.searchpantheon.com/collections/${ip_id}_342.jpg`;
    default:
      return "";
  }
};

export const getImageUrlBig = (vertical, ip_id, image_url) => {
  switch (vertical) {
    case "Movies":
      return `https://image.tmdb.org/t/p/w500/${image_url}`;
    case "Television":
      return `https://image.tmdb.org/t/p/w500/${image_url}`;
    case "Gaming":
      return `https://images.igdb.com/igdb/image/upload/t_cover_big/${image_url}.png`;
    case "Franchise":
      return `https://images.searchpantheon.com/collections/${ip_id}_342.jpg`;
    default:
      return "";
  }
};

export const convertData = (data) => {
  const convertedData = data.map(item => {
    const imageUrl = getImageUrl(item.vertical, item.ip_id, item.image_url);
    const labelTags = [verticalTypeLabels[item.vertical]];
    if (item.release_date) {
      labelTags.push(new Date(item.release_date).getFullYear().toString());
    }
    return {
      value: item.ip_id,
      label: item.ip,
      iconUrl: imageUrl,
      iconAlt: `${item.ip} cover`,
      tags: labelTags,
      originalData: { ...item, value: item.ip_id },
    };
  });

  return convertedData;
};

export const verticalTypeLabels = {
  "Movies": "Movie",
  "Television": "Series",
  "Gaming": "Game",
  "Franchise": "Franchise",
};