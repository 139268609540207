/* 
  ExpandableSection that supports a custom header and inline actions (and with a better arrow icon)
*/

import React, { PropsWithChildren, ReactNode, useState } from "react";
import { FaAngleRight } from "react-icons/fa6";

interface BetterExpandableSectionProps {
  header?: any;
  inlineHeaderActions?: any;
}

export const BetterExpandableSection: React.FC<PropsWithChildren<BetterExpandableSectionProps>> = ({
  children,
  header,
  inlineHeaderActions,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2">
        <div className="flex gap-2 dark:hover:text-sky-400 hover:text-sky-600 hover:cursor-pointer select-none" onClick={() => setExpanded(!expanded)}>
          <div className={`transition-transform origin-center w-3 h-full flex items-center justify-center duration-100 ease-out ${expanded ? "rotate-90" : "rotate-0"}`}>
            <FaAngleRight />
          </div>
          {header}
        </div>
        <div className="border-l border-slate-400 dark:border-slate-600"></div>
        {inlineHeaderActions}
      </div>
      <div className={expanded ? "" : "hidden"}>
        {children}
      </div>
    </div>
  );
};