import moment from "moment";
import React, { useState } from "react";
import { TimeseriesChart } from "../../../platforms/google/components/TimeseriesChart/TimeseriesChart";
import { CountryMap } from "../../../platforms/google/components/CountryMap/CountryMap";
import { getAllCategories } from "../../../platforms/google/utils";
import { CATEGORIES, COUNTRIES, Country, DMA_REGIONS } from "../../../platforms/google/constants";
import { Box } from "@cloudscape-design/components";
import { RangeDateSelector } from "../../../../components/RangeDateSelector";

const mainCountries: Array<Country> = [
  COUNTRIES.AUSTRALIA,
  COUNTRIES.BRAZIL,
  COUNTRIES.CANADA,
  COUNTRIES.GERMANY,
  COUNTRIES.SPAIN,
  COUNTRIES.FRANCE,
  COUNTRIES.UNITED_KINGDOM,
  COUNTRIES.IRELAND,
  COUNTRIES.ITALY,
  COUNTRIES.JAPAN,
  COUNTRIES.SOUTH_KOREA,
  COUNTRIES.MEXICO,
  COUNTRIES.THAILAND,
  COUNTRIES.UNITED_STATES_OF_AMERICA,
  COUNTRIES.INDIA,
];

export const GoogleTitleInsights = ({
  titleData,
}) => {

  const [dateRange, setDateRange] = useState<any>({
    type: "relative",
    amount: 6,
    unit: "month",
    startDate: moment().subtract(6, "month").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  return (
    <div className="flex flex-col gap-4 w-full">
      <div className="flex justify-end items-center">
        <RangeDateSelector
          defaults={dateRange}
          onChange={(range) => setDateRange(range)}
        />
      </div>
      <TimeseriesChart
        selectedTitle={titleData} 
        dateRange={dateRange}
        categories={getAllCategories(CATEGORIES)}
        countries={mainCountries}
      />
      <CountryMap
        selectedTitle={titleData}
        categories={getAllCategories(CATEGORIES)}
        countries={Object.values(COUNTRIES)}
        dmaRegions={Object.values(DMA_REGIONS)}
        dateRange={dateRange}
      />
    </div>
  );
};