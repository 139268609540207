import { Vertical } from "../../../../../../types/verticalTypes";
import axiosInstance from "../../../../../../utils/axios";

interface GetNonOTTTitleDataProps {
  filterQuery: any;
  sort?: string;
  vertical: string;
  sortOrder?: "asc" | "desc";
  offset?: number;
  paginationPageSize: number;
}

const verticalMap = {
  [Vertical.Movies]: "movies",
  [Vertical.Series]: "series",
};

export const getNonOTTTitlesData = ({
  filterQuery,
  sort = "daily_rank",
  vertical,
  sortOrder = "asc",
  offset = 0,
  paginationPageSize,
}: GetNonOTTTitleDataProps) => {
  return axiosInstance.request({
    url: `/ranking/daily/nonott/v3?offset=${offset}&limit=${paginationPageSize}&sort=${sort}&sort_order=${sortOrder}&vertical=${verticalMap[vertical]}`,
    method: "POST",
    data: {
      fields: [],
      q: filterQuery,
    },
  });
};
