import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getTitleSearchByIdData } from "../request";

interface UseGetTitleSearchByIdDataProps {
  ipIds: string[];
}

export const useGetTitleSearchByIdData = ({
  ipIds=[],
}: UseGetTitleSearchByIdDataProps) => {

  const params = {
    ipIds,
  };

  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_TITLE_SEARCH_BY_ID_DATA, { ...params }],
    async () => {
      if (!ipIds || ipIds.length === 0) {
        return await Promise.resolve({ data: [], isLoading: false, error: null });
      }
      return await getTitleSearchByIdData(params);
    },
  );

  return {
    data: (data as any)?.data,
    isLoading,
    ...error as object,
  };
};
