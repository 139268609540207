import { Button, Icon, SplitPanel } from "@cloudscape-design/components";
import DatapointComparison from "../../../../../components/datapoint-comparison/DatapointComparison";
import {
  SCORE_COMPARE_METRICS,
  DATA_COMPARE_METRICS,
} from "../../../../item/pages/components/modals/CompareModal/metrics";
import { METRIC_GROUPS } from "../../../../../components/datapoint-comparison/constants";
import React, { useEffect, useMemo, useState } from "react";
import { urlParamsFromParameters } from "../../../../../components/datapoint-comparison/utils";
import { Vertical } from "../../../../../types/verticalTypes";
import { useMetrics } from "../../../../tools/compare/hooks/useMetrics";
import { WeightObject } from "../../../../../types/weightTypes";

const verticalMetricsMap = {
  [Vertical.Games]: [
    ...SCORE_COMPARE_METRICS.GAMES,
    ...SCORE_COMPARE_METRICS.ALL,
    ...DATA_COMPARE_METRICS.ALL,
    ...DATA_COMPARE_METRICS.GAMES,
  ],
  [Vertical.Movies]: [
    ...SCORE_COMPARE_METRICS.MOVIES_SERIES,
    ...SCORE_COMPARE_METRICS.ALL,
    ...DATA_COMPARE_METRICS.ALL,
    ...DATA_COMPARE_METRICS.MOVIES_SERIES,
  ],
  [Vertical.Series]: [
    ...SCORE_COMPARE_METRICS.MOVIES_SERIES,
    ...SCORE_COMPARE_METRICS.ALL,
    ...DATA_COMPARE_METRICS.ALL,
    ...DATA_COMPARE_METRICS.MOVIES_SERIES,
  ],
};

const idPrefixMap = {
  [Vertical.Games]: "game",
  [Vertical.Movies]: "film",
  [Vertical.Series]: "series",
};

interface CompareContainerProps {
  vertical: Vertical;
  ipIds: string[];
  dateRange: any;
  weightsObject: WeightObject;
  onParametersChanged: (parameters: any) => void;
}

export const CompareContainer: React.FC<CompareContainerProps> = ({
  vertical,
  ipIds,
  dateRange,
  weightsObject,
  onParametersChanged,
}) => {

  const [properties, setProperties] = useState<any>({});

  const { metrics } = useMetrics({
    weightsObjects: { [vertical]: weightsObject },
  });

  useEffect(() => {
    setProperties({
      metricKeys: verticalMetricsMap[vertical]
        .filter((metric) => metric.group === METRIC_GROUPS.ZSCORE)
        .map((metric) => metric.key),
      activeMetricKey: properties.activeMetricKey ?? "summary",
      parameters: [
        {
          dateRange: {
            startDate: dateRange?.startDate,
            endDate: dateRange?.endDate,
          },
          titleIds: ipIds,
        },
      ],
    });
  }, [vertical, ipIds, dateRange]);

  useEffect(() => {
    if (!properties || !properties.parameters) return;
    if (onParametersChanged) {
      onParametersChanged(properties.parameters);
    }
  }, [properties]);

  return (
    <SplitPanel
      header={
        <div className="sp-header-text flex justify-between items-center w-full">
          <div className="inline-block grow flex items-center">
            <span className="mr-1">Compare Titles</span>
            <Button
              variant="inline-icon"
              iconName="external"
              ariaLabel="Open in new tab"
              onClick={() => {
                const urlParams = urlParamsFromParameters(properties);
                window.open(`/tools/compare?${urlParams}`, "_blank");
              }}
            />
          </div>
          <div className="text-white">
            {/*<Button 
              variant="inline-link"
              ariaLabel="Expand to page height"
              onClick={() => {
                const element = document.querySelector("section[class^='awsui_split-panel-bottom_'] > div");
                element.style.blockSize = "1000px";
              }} 
            >
              <span className="dark:text-slate-200 dark:hover:text-white text-slate-700 hover:text-black"><Icon name="expand" /></span>
            </Button>*/}
          </div>
        </div> as any
      }
      hidePreferencesButton={true}
      i18nStrings={{
        preferencesTitle: "Preferences",
        preferencesPositionLabel: "Panel position",
        preferencesPositionDescription:
          "Choose the default position for the split panel.",
        preferencesPositionSide: "Side",
        preferencesPositionBottom: "Bottom",
        preferencesCancel: "Cancel",
        preferencesConfirm: "Confirm",
        closeButtonAriaLabel: "Close panel",
        openButtonAriaLabel: "Open panel",
        resizeHandleAriaLabel: "Resize split panel",
      }}
    >
      <style>{`
        h2[class^='awsui_header-text_']:has(> div[class^='sp-header-text']) {
          width: 100%;
        }
      `}</style>
      <div className="mb-4">
        <DatapointComparison
          metrics={metrics.filter(metric => metric.group === METRIC_GROUPS.ZSCORE && metric.datapoints.some(dp => typeof dp.weight === "number" ? dp.weight > 0 : dp.weight.filter(w => w.idPrefix === idPrefixMap[vertical] && w.weight > 0).length > 0))}
          properties={properties}
          setProperties={setProperties}
          options={{
            container: {
              disablePadding: true,
              disableBorder: true,
            },
            parameters: {
              enabled: true,
              variant: "tokens",
              showDateRanges: false,
            },
            chart: {
              height: 400,
            },
            summary: {
              enabled: true,
            },
          }}
        />
      </div>
    </SplitPanel>
  );
};
