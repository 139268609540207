import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../Layout";
import NewsRiver from "../../../components/news-river/NewsRiver";
import { navItems, NavItemsWithId } from "../../../layouts/common/menu/side-menu";
import { Autosuggest, Button, ContentLayout, DateRangePicker, ExpandableSection, Header, Multiselect, Select, TokenGroup } from "@cloudscape-design/components";
import { Vertical, verticalOptionsWithAll } from "../../../types/verticalTypes";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { FrequencyChart } from "./components/FrequencyChart";
import { BetterExpandableSection } from "./components/BetterExpandableSection";
import { DEFAULT_DARK_STROKES } from "../../../components/datapoint-comparison/constants";
import moment from "moment";
import { NewsRiverDashboardContext } from "./hooks/useNewsRiverDashboardContext";
import { useGetTopSearchDataV2 } from "../../../components/CommonSearch/api/hooks/useGetTopSearchDataV2";
import { convertData } from "../../../components/CommonSearch/CommonSearch";

const NewsRiverDashboard = () => {

  const title = "News River";
  const breadcrumbs = [{ text: "Dashboards" }, { text: title }];

  const newsRiverRef = useRef(null);

  const [selectedFilterOptions, setSelectedFilterOptions] = useState([]);
  const [selectedVertical, setSelectedVertical] = useState<OptionDefinition>(verticalOptionsWithAll[0]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loadItemsSearchQuery, setLoadItemsSearchQuery] = useState("");
  const [newsLoading, setNewsLoading] = useState(false);
  const [timeseriesData, setTimeseriesData] = useState<any>(null);

  const [dateRange, setDateRange] = useState({
    type: "relative",
    unit: "day",
    amount: 7,
    startDate: moment().subtract(7, "days").toISOString(),
    endDate: moment().toISOString(),
  });

  const { data: searchResults, isLoading, error } = useGetTopSearchDataV2({
    q: loadItemsSearchQuery,
    verticals: [Vertical.Movies, Vertical.Series, Vertical.Games, "Franchises"],
    limit: 50,
  });

  const onNewsResponseChange = ({ isLoading, timeseriesData }) => {
    setNewsLoading(isLoading);
    setTimeseriesData(selectedFilterOptions.map((option, index) => {
      const item = timeseriesData?.find(item => item.filter === option.value);
      const color = DEFAULT_DARK_STROKES[index % DEFAULT_DARK_STROKES.length];
      return item ? { ...item, filterOption: option, color } : null;
    }).filter(item => item !== null));
  };

  return (
    <NewsRiverDashboardContext.Provider
      value={{
        setDateRange,
      }}
    >
      <Layout
        title={title}
        breadcrumbs={breadcrumbs}
        navItems={navItems as NavItemsWithId}
        contentType="default"
        content={
          <ContentLayout
            header={
              <Header
                variant="h2"
              >
                {title}
              </Header>
            }
          >
            <div className="flex flex-col gap-4 mt-[-1rem]">
              <div className="flex flex-col sticky top-0 pt-4 dark:bg-[#161d26] bg-white z-10 shadow-[0_6px_6px_-6px_rgba(0,0,0,0.25)]">
                <div className="flex justify-between items-start">
                  <div className="flex gap-2">
                    <Autosuggest
                      onChange={({ detail }) => setSearchQuery(detail.value)}
                      onLoadItems={({ detail }) => setLoadItemsSearchQuery(detail.filteringText)}
                      value={searchQuery}
                      options={(searchResults && searchResults.results?.length > 0) ? convertData(searchResults.results) : []}
                      placeholder="Filter by title or keyword"
                      statusType={isLoading ? "loading" : "finished"}
                      enteredTextLabel={(value) => `Use keyword: ${value}`}
                      filteringType="none"
                      onSelect={({ detail }) => {
                        const newSelectedFilterOptions = [...selectedFilterOptions];
                        if (!detail.selectedOption && detail.value) {
                          newSelectedFilterOptions.push({ label: detail.value, value: detail.value, tags: ["Keyword"] });
                        } else if (detail.selectedOption) {
                          newSelectedFilterOptions.push(detail.selectedOption);
                        }
                        setSelectedFilterOptions(newSelectedFilterOptions);
                        setSearchQuery("");
                      }}
                    />
                  </div>
                  <div className="flex gap-2">
                    <div>
                      <Button
                        iconName="refresh"
                        onClick={() => {
                          newsRiverRef.current.refresh();
                        }}
                        loading={newsLoading}
                      />
                    </div>
                    <Select
                      options={verticalOptionsWithAll}
                      selectedOption={selectedVertical}
                      onChange={({ detail }) => setSelectedVertical(detail.selectedOption)}
                    />
                    <DateRangePicker
                      onChange={({ detail }) => {
                        const newDateRange = { ...detail.value } as any;
                        if (newDateRange.type === "relative") {
                          newDateRange.startDate = moment.utc().subtract(newDateRange.amount, newDateRange.unit).toISOString();
                          newDateRange.endDate = moment().toISOString();
                        }
                        setDateRange(newDateRange);
                      }}
                      value={dateRange as any}
                      relativeOptions={[
                        {
                          key: "previous-1-hour",
                          amount: 1,
                          unit: "hour",
                          type: "relative"
                        },
                        {
                          key: "previous-6-hours",
                          amount: 6,
                          unit: "hour",
                          type: "relative"
                        },
                        {
                          key: "previous-1-day",
                          amount: 1,
                          unit: "day",
                          type: "relative"
                        },
                        {
                          key: "previous-7-day",
                          amount: 7,
                          unit: "day",
                          type: "relative"
                        },
                      ]}
                      isValidRange={range => {
                        if (range.type === "absolute") {
                          const [startDateWithoutTime] = range.startDate.split("T");
                          const [endDateWithoutTime] = range.endDate.split("T");
                          if (!startDateWithoutTime || !endDateWithoutTime) {
                            return {
                              valid: false,
                              errorMessage: "The selected date range is incomplete. Select a start and end date for the date range."
                            };
                          }
                          if (moment(range.startDate).diff(moment(range.endDate), "days") > 0) {
                            return {
                              valid: false,
                              errorMessage: "The selected date range is invalid. The start date must be before the end date."
                            };
                          }
                        }
                        return { valid: true };
                      }}
                      i18nStrings={{
                        todayAriaLabel: "Today",
                        nextMonthAriaLabel: "Next month",
                        previousMonthAriaLabel: "Previous month",
                        customRelativeRangeDurationLabel: "Duration",
                        customRelativeRangeDurationPlaceholder: "Enter duration",
                        customRelativeRangeOptionLabel: "Custom range",
                        customRelativeRangeOptionDescription: "Set a custom range in the past",
                        customRelativeRangeUnitLabel: "Unit of time",
                        formatRelativeRange: (e) => {
                          return `Last ${e.amount} ${1 === e.amount ? e.unit : e.unit + "s"}`;
                        },
                        formatUnit: (e, n) => (1 === n ? e : `${e}s`),
                        dateTimeConstraintText: "For date, use YYYY/MM/DD. For time, use 24 hr UTC format.",
                        relativeModeTitle: "Relative range",
                        absoluteModeTitle: "Absolute range",
                        relativeRangeSelectionHeading: "Choose a range",
                        startDateLabel: "Start date",
                        endDateLabel: "End date",
                        startTimeLabel: "Start time",
                        endTimeLabel: "End time",
                        clearButtonLabel: "Clear and dismiss",
                        cancelButtonLabel: "Cancel",
                        applyButtonLabel: "Apply",
                      }}
                      absoluteFormat="iso"
                      placeholder="Filter by a date and time range"
                      getTimeOffset={() => 0}
                      hideTimeOffset
                    />
                  </div>
                </div>
                <div className="flex justify-between">
                  <div>
                    <div>
                      <TokenGroup
                        items={selectedFilterOptions.map((option, i) => (
                          { 
                            ...option, 
                            label: (
                              <div className="flex gap-2 items-center">
                                <div className="w-4 h-4 rounded-sm" style={{ backgroundColor: DEFAULT_DARK_STROKES[i % DEFAULT_DARK_STROKES.length] }}></div>
                                <div>{option.label}</div>
                              </div>
                            ),
                          }
                        ))}
                        onDismiss={({ detail: { itemIndex } }) => {
                          setSelectedFilterOptions([
                            ...selectedFilterOptions.slice(0, itemIndex),
                            ...selectedFilterOptions.slice(itemIndex + 1)
                          ]);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={`mt-2 ${(selectedFilterOptions.length > 0 && timeseriesData?.length > 0) ? "" : "hidden"}`}>
                  <BetterExpandableSection
                    header={
                      <div className="font-bold">Insights</div>
                    }
                    inlineHeaderActions={
                      <>
                        <Button
                          iconName="remove"
                          variant="inline-link"
                          onClick={() => {
                            setSelectedFilterOptions([]);
                          }}
                          disabled={selectedFilterOptions.length === 0}
                        >
                          Clear filters
                        </Button>
                      </>
                    }
                  >
                    <div className="flex gap-4">
                      <div style={{ width: "100%", height: "150px" }}>
                        <FrequencyChart
                          timeseriesData={timeseriesData}
                        />
                      </div>
                      <div className="border-l border-slate-400 dark:border-slate-600"></div>
                      <div className="flex flex-col gap-1 w-[35%] overflow-y-auto h-[150px]">
                        {timeseriesData?.sort((a, b) => b.total - a.total)?.map((item) => (
                          <div key={item.filterOption.value} className="flex gap-2 items-center">
                            <div className="w-4 h-4 rounded-sm" style={{ backgroundColor: item.color }}></div>
                            <div><b>{item.filterOption.label}</b>: {item.total} articles</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </BetterExpandableSection>
                </div>
                <div className="border-t border-slate-400 dark:border-slate-600 mt-4"></div>
              </div>
              <NewsRiver
                ref={newsRiverRef}
                pageSize={50}
                ipIds={selectedFilterOptions.filter(option => option.tags[0] !== "Keyword").map(option => option.value)}
                keywords={selectedFilterOptions.filter(option => option.tags[0] === "Keyword").map(option => option.value)}
                verticals={selectedVertical.value === "all" ? null : [selectedVertical.value as Vertical]}
                startTimestamp={moment.utc(dateRange.startDate).unix()}
                endTimestamp={dateRange.type === "relative" ? null : moment.utc(dateRange.endDate).unix()}
                onResponseChange={onNewsResponseChange}
                useFullPageScrollTarget={true}
              />
            </div>
          </ContentLayout>
        }
      />
    </NewsRiverDashboardContext.Provider>
  );
};

export default NewsRiverDashboard;