import { Drawer, HelpPanel } from '@cloudscape-design/components';
import React from 'react';

export const GlobalInfoDrawer = () => {

  return (
    <Drawer header="Information">
      <div className="flex flex-col gap-1">
        <div className="text-base font-bold">Global ranking</div>
        <p>In contrast to <b>tracked</b> ranking, <b>global</b> ranking includes all titles available on Pantheon and is based on platform metrics that can apply across all titles. For instance, a movie released in 1996 might not have any presence on TikTok, but it can still receive Wikipedia page views and Google searches, so we want to capture that.</p>
        <div className="text-base font-bold mt-4">How ranks are calculated</div>
        <p>Each day, every title within Pantheon is assigned platform-specific Z-scores, which measure its performance on different platforms. These Z-scores are then combined using weighted sums to calculate a total score for the title. Finally, the titles are ranked by sorting them based on their total scores, with the highest score receiving the top rank.</p>
        <div className="text-base font-bold mt-4">How trends are calculated</div>
        <p>Z-scores are summed up across all days within the selected timeframe. The same process is applied to the previous period for comparison. For example, if you select a 7-day period, the 7 days prior to that are considered the "previous period." Rank changes are then determined by comparing a title's rank in the current period with its rank in the previous period, and the difference is used to show how the rank has shifted.</p>
      </div>
    </Drawer>
  );
};