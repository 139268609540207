import React, { useState, useMemo, useEffect, useRef } from "react";
import moment from "moment";
import { SpaceBetween, Grid, FormField, Multiselect, ButtonDropdown, Link, Header, TokenGroup, Spinner, Button, Icon } from "@cloudscape-design/components";
import { RangeDateSelector } from "../../../RangeDateSelector";
import MultiTitleSelector from "./MultiTitleSelector";
import AlignDateRangeModal from "./AlignDateRangeModal";
import { DEFAULT_DATE_RANGE } from "../../constants";
import { useCompareContext } from "../../hooks/useCompareContext";
import _ from "lodash";
import { MessageBox } from "../../../MessageBox";

const Parameters = ({
  setExternallyLoadedTitles,
  setDateRanges,
  setSelectedMetrics,
  setSelectedTitles,
  setActiveMetricKey,
}) => {

  const messageBoxRef = useRef(null);

  const { metrics, options, dateRanges, selectedTitles, selectedMetrics, activeMetricKey } = useCompareContext();

  const [ alignDateRangeModalOpen, setAlignDateRangeModalOpen ] = useState(false);
  const [ editingDateRange, setEditingDateRange ] = useState(null);
  const [ savedAlignDateRangeParams, setSavedAlignDateRangeParams ] = useState([]);
  const [ titlesLoading, setTitlesLoading ] = useState(false);

  const metricOptions = useMemo(() => metrics?.map(metric => (
    {
      label: metric.name,
      value: metric.key,
      description: metric.datapoints.map(datapoint => `${datapoint.platform.name} ${datapoint.datapoint.name}`).join(", ").slice(0, 64) + 
        (metric.datapoints.map(datapoint => `${datapoint.platform.name} ${datapoint.datapoint.name}`).join(", ").length > 64 ? "..." : ""),
      group: metric.group,
    }
  )) ?? [], [metrics]);

  const metricGroups = useMemo(() => {
    const x = _.uniqBy(metrics.map(metric => metric.group), "key");
    return x;
  }, [metrics]);

  return (
    <div>
      {options.parameters.variant === "full" ? (
        <SpaceBetween direction="vertical" size="s">
          {[...Array(dateRanges.length).keys()].map(paramIndex => (
            <Grid
              key={paramIndex}
              gridDefinition={[
                { colspan: { default: 4, s: 6 } },
                { colspan: { default: 4, s: 3 } },
                { colspan: { default: 4, s: 3 } },
              ]}
            >
              <FormField
                label={paramIndex == 0 ? "Titles" : null}
                stretch
              >
                <MultiTitleSelector
                  selectedTitles={selectedTitles[paramIndex]}
                  setSelectedTitles={(titles) => {
                    setSelectedTitles(selectedTitles.map((t, i) => i === paramIndex ? titles : t));
                  }}
                  onSearchResultsChanged={(results) => {
                    setExternallyLoadedTitles(results);
                  }}
                  shortenPlaceholder={false}
                  onLoadingChanged={(loading) => {
                    setTitlesLoading(loading);
                  }}
                />
              </FormField>
              {paramIndex === 0 ? (
                <FormField
                  label="Metrics"
                >
                  <Multiselect
                    selectedOptions={metricOptions.filter(option => selectedMetrics.map(m => m.key).includes(option.value))}
                    onChange={({ detail }) => {
                      setSelectedMetrics(metrics.filter(metric => detail.selectedOptions.map(m => m.value).includes(metric.key)));
                      if (!activeMetricKey) {
                        setActiveMetricKey(detail.selectedOptions[0].value);
                      } else if (!detail.selectedOptions.map(option => option.value).includes(activeMetricKey)) {
                        if (detail.selectedOptions.length > 0) {
                          setActiveMetricKey(detail.selectedOptions[0].value);
                        } else {
                          setActiveMetricKey(null);
                        }
                      }
                    }}
                    options={metricGroups.map(group => (
                      group === undefined ? (
                        { label: "Other", options: metricOptions.filter(option => option.group == null) }
                      ) : (
                        { label: metricGroups.find(g => g?.key === group.key).name, options: metricOptions.filter(option => option.group && option.group.key === group.key) }
                      )
                    ))}
                    placeholder={selectedMetrics.length > 0 ? `${selectedMetrics.length} ${selectedMetrics.length == 1 ? "metric" : "metrics"} selected` : "Select metrics to compare"}
                    hideTokens={true}
                    filteringType="auto"
                  />
                </FormField>
              ) : (
                <div></div>
              )}
              <FormField
                label={paramIndex == 0 ? "Date range" : null}
              >
                <div className="flex w-full">
                  <div className="grow">
                    <RangeDateSelector
                      defaults={dateRanges[paramIndex]}
                      onChange={(e) => {
                        e.startDate = moment.utc(e.startDate).format("YYYY-MM-DD");
                        e.endDate = moment.utc(e.endDate).format("YYYY-MM-DD");
                        setDateRanges(dateRanges.map((range, index) => index === paramIndex ? e : range));
                      }}
                    />
                  </div>
                  <ButtonDropdown
                    variant="icon"
                    expandToViewport
                    items={[
                      {
                        text: "Align to event",
                        id: "align-to-event",
                      },
                      /*{
                        text: "Split into multiple date ranges",
                        id: "split-date-ranges",
                        disabled: selectedTitles[paramIndex].length <= 1,
                      },*/
                      {
                        text: "Align release dates",
                        id: "align-to-release",
                        disabled: selectedTitles[paramIndex].length === 0,
                      },
                      {
                        text: "Remove",
                        id: "remove",
                        disabled: paramIndex === 0,
                      },
                    ]}
                    onItemClick={({ detail }) => {
                      switch (detail.id) {
                        case "align-to-event":
                          setEditingDateRange({ index: paramIndex, dateRange: dateRanges[paramIndex] });
                          setAlignDateRangeModalOpen(true);
                          break;
                        case "split-date-ranges":
                          messageBoxRef.current.open({
                            headerText: "Split titles into multiple date ranges",
                            messageBody: "This will split the selected titles into multiple date ranges. Are you sure you want to proceed?",
                            primaryButtonText: "Ok",
                            secondaryButtonText: "Cancel",
                            onPrimaryButtonClick: () => {
                              const dateRange = dateRanges[paramIndex];
                              const dateRangeTitles = selectedTitles[paramIndex];
                              const newDateRanges = dateRangeTitles.map((title, index) => {
                                return { type: "absolute", startDate: dateRange.startDate, endDate: dateRange.endDate };
                              });
                              setDateRanges([...dateRanges.filter((range, i) => i !== paramIndex), ...newDateRanges]);
                              setSelectedTitles([...selectedTitles.filter((titles, i) => i !== paramIndex), ...dateRangeTitles.map(x => [x])]);
                            },
                            onSecondaryButtonClick: () => {},
                          });
                          break;
                        case "align-to-release":
                          messageBoxRef.current.open({
                            headerText: "Align titles to release dates",
                            messageBody: "This will align the selected titles to their release dates, with a 30 day lead time and a 6 month follow-up time. Are you sure you want to proceed?",
                            primaryButtonText: "Ok",
                            secondaryButtonText: "Cancel",
                            onPrimaryButtonClick: () => {
                              const dateRangeTitles = selectedTitles[paramIndex];
                              const newDateRanges = dateRangeTitles.map(title => {
                                const releaseDate = title.release_date;
                                const startDate = moment.utc(releaseDate).subtract(30, "days").format("YYYY-MM-DD");
                                const endDate = moment.utc(releaseDate).add(6, "months").format("YYYY-MM-DD");
                                return { type: "absolute", startDate, endDate };
                              });
                              setDateRanges([...dateRanges.filter((range, index) => index !== paramIndex), ...newDateRanges]);
                              setSelectedTitles([...selectedTitles.filter((titles, index) => index !== paramIndex), ...dateRangeTitles.map(x => [x])]);
                            },
                            onSecondaryButtonClick: () => {},
                          });
                          break;
                        case "remove":
                          setDateRanges(dateRanges.filter((range, index) => index !== paramIndex));
                          setSelectedTitles(selectedTitles.filter((titles, index) => index !== paramIndex));
                          break;
                      }
                    }}
                  />
                </div>
              </FormField>
            </Grid>
          ))}
          <Link
            variant="primary"
            onFollow={() => {
              setDateRanges([...dateRanges, DEFAULT_DATE_RANGE]);
              setSelectedTitles([...selectedTitles, []]);
            }}
          >
            (+) Add date range
          </Link>
        </SpaceBetween>
      ) : options.parameters.variant === "readonly" ? (
        <SpaceBetween direction="vertical" size="s">
          <Header
            variant="h3"
          >
            {[...Array(dateRanges.length).keys()].map(paramIndex => (
              <div>
                <div>
                  {selectedTitles[paramIndex].map(title => title.ip).join(", ")}{" "}
                  <span className="font-normal">({moment(dateRanges[paramIndex].startDate).format("l")} - {moment(dateRanges[paramIndex].endDate).format("l")})</span>
                </div>
              </div>
            ))}
          </Header>
        </SpaceBetween>
      ) : options.parameters.variant === "tokens" ? (
        <>
          <div className="flex justify-between items-start">
            <FormField label="Titles" stretch>
              {selectedTitles.flat().length > 0 ? (
                <div className="flex flex-col gap-2">
                  <TokenGroup 
                    disableOuterPadding
                    items={selectedTitles.map(titles => titles.map(title => ({ label: title.ip ?? "Loading..." }))).flat()}
                    onDismiss={({ detail: { itemIndex }}) => {
                      setSelectedTitles(selectedTitles.map((titles, index) => index === Math.floor(itemIndex / titles.length) ? titles.filter((title, i) => i !== itemIndex % titles.length) : titles));
                    }}
                  />
                  <Button variant="inline-link" iconName="remove" onClick={() => setSelectedTitles(dateRanges.map(x => []))} loading={titlesLoading}>Clear selected titles</Button>
                </div>
              ) : (
                <div className="text-slate-400">No titles selected</div>
              )}
            </FormField>
            {options.parameters.showDateRanges && (
              <FormField label="Date range">
                <RangeDateSelector
                  defaults={dateRanges[0]}
                  onChange={(e) => {
                    e.startDate = moment.utc(e.startDate).format("YYYY-MM-DD");
                    e.endDate = moment.utc(e.endDate).format("YYYY-MM-DD");
                    setDateRanges(dateRanges.map((range, index) => index === 0 ? e : range));
                  }}
                />
              </FormField>
            )}
          </div>
        </>
      ) : null}
      <AlignDateRangeModal
        visible={alignDateRangeModalOpen}
        setVisible={setAlignDateRangeModalOpen}
        dateRange={editingDateRange?.dateRange}
        setDateRange={(newDateRange) => {
          setEditingDateRange(prevRange => ({ ...prevRange, dateRange: newDateRange }));
        }}
        onSubmit={({ detail }) => {
          setDateRanges(dateRanges.map((range, index) => editingDateRange.index === index ? detail.dateRange : range));
          setSavedAlignDateRangeParams(prevParams => {
            const newParams = [...prevParams];
            newParams[editingDateRange.index] = detail;
            return newParams;
          });
          setAlignDateRangeModalOpen(false);
        }}
        defaultSelectedTitle={selectedTitles[editingDateRange?.index]?.length > 0 ? selectedTitles[editingDateRange?.index][0] : null}
        defaults={savedAlignDateRangeParams[editingDateRange?.index]}
      />
      <MessageBox ref={messageBoxRef} />
    </div>
  );
};

export default Parameters;