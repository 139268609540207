import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getManageTitleVariantsData } from "../request";

export const useGetManageTitleVariantsData = ({
  offset,
  limit,
  q,
  enabled=false,
}) => {

  const params = {
    offset,
    limit,
    q,
  };

  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_MANAGE_TITLE_VARIANTS_DATA, { ...params }],
    () => getManageTitleVariantsData(params),
    { enabled },
  );

  return {
    data: data?.data,
    isLoading,
    ...error,
  };
};
