import { Container, Header } from "@cloudscape-design/components";
import React from "react";
import { AwardsTable } from "../../components/AwardsTable";

const awardEvents = [
  "Academy Awards, USA",
  "Golden Globes, USA",
  "Sundance Film Festival",
  "Critics Choice Awards",
  "Screen Actors Guild Awards",
];

const shortenAwardName = (fullName: string) => {
  const nameMap = {
    "Academy Awards, USA": "Academy Awards",
    "Golden Globes, USA": "Golden Globes",
    "Sundance Film Festival": "Sundance",
    "Critics Choice Awards": "Critics' Choice",
    "Screen Actors Guild Awards": "SAG Awards",
  };

  return nameMap[fullName] || fullName;
};

export const Awards = ({ itemData, table }: { itemData: any, table: boolean }) => {
  const awardCounts = {};

  // Get awards array handling both payload structures
  const awards = itemData.data?.awards || itemData.awards || [];

  // Calculate award counts
  awardEvents.forEach((event) => {
    awardCounts[event] = awards.filter((award) => award.event === event).length;
  });

  const awardstats = Object.keys(awardCounts)
    .filter((event) => awardCounts[event] > 0)
    .map((event) => ({
      name: event,
      stat: awardCounts[event],
    }));

  const getIconPath = (awardTitle: string) => {
    const formattedTitle = awardTitle.toLowerCase().replace(/[^a-z0-9]/g, "_");

    return `/awards/award_${formattedTitle}.png`;
  };

  return (
    <div>
      <Header>
        <div>
          <div className="grid grid-cols-2 gap-2 md:grid-cols-3 xxl:grid-cols-5">
            {awardstats.map((item, index) => (
              <div
                key={index}
                className="mt-2 overflow-hidden rounded-2xl shadow-lg dark:bg-darkerBg px-4 py-5 sm:p-6 flex items-center border-2 border-gray-100 dark:border-gray-800"
              >
                <div className="award-icon">
                  <img
                    src={getIconPath(item.name)}
                    alt={`${item.name} icon`}
                    className="h-[50px] object-contain"
                  />
                </div>
                <div className="ml-4">
                  <div className="truncate text-sm font-bold">
                    {shortenAwardName(item.name)}
                  </div>
                  <div className="mt-1 text-3xl font-semibold tracking-tight">
                    {item.stat}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Header>
      {table && <div className="py-4"><AwardsTable itemData={itemData} /></div>}
    </div>
  );
};
