import {
  Link,
  Multiselect,
  SpaceBetween
} from "@cloudscape-design/components";
import { ColDef, GridApi, TooltipRendererParams } from "ag-grid-community";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { rgb } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { format } from "date-fns";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CustomLoadingOverlay from "../../../../components/PantheonLoading";
import { YOUTUBE_CATEGORIES } from "../../../../config-global";
import { useGetGridTheme } from "../../../../hooks/UseTheme/useGetGridTheme";
import shortenInteger from "../../../../utils/shortenInteger";
import { useGetChannelData } from "../api/hooks/useGetChannelData";
import { YouTubeChannelTrendsResponse } from "../api/types";

interface ProcessedChannelData extends YouTubeChannelTrendsResponse {
  viewCountChangeData: number[];
  subscriberCountChangeData: number[];
  videoCountChangeData: number[];
  totalViewIncrease: number;
  totalSubscriberIncrease: number;
  totalVideoIncrease: number;
}

function createTooltipRenderer(type: 'view' | 'subscriber' | 'video') {
  return function tooltipRenderer(params: TooltipRendererParams) {
    const { context, xValue } = params;
    const rowData = context.data;
    const dateStrings = Object.keys(rowData.trend_data).sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime()
    );
    const dateString = dateStrings[xValue];
    const date = new Date(dateString);
    const formattedDate = format(date, "dd MMM, yyyy");

    const trendData = rowData.trend_data[dateString];
    const changeData = rowData[`${type}CountChangeData`][xValue];

    const tooltipContent = `
      <div class='tooltip-title'>${formattedDate}</div>
      <div class='tooltip-content'>
        <div>${type.charAt(0).toUpperCase() + type.slice(1)} Count: ${shortenInteger(trendData[`${type}_count`])}</div>
        <div>${type.charAt(0).toUpperCase() + type.slice(1)} Change: ${changeData > 0 ? "+" + shortenInteger(changeData) : "No increase"}</div>
      </div>
    `;

    return `
      <div class='dark:bg-sky-100 bg-sky-900 rounded-md p-2 text-slate-100 dark:text-slate-900'>
        ${tooltipContent}
      </div>
    `;
  };
}

const VideoComponent: React.FC = () => {
  const [interval, setInterval] = useState<string>("weekly");
  const { data: channelData, isLoading } = useGetChannelData(interval);
  const { theme } = useGetGridTheme();
  const isDarkTheme = theme === 'ag-theme-quartz-dark';  

  const gridApiRef = useRef<GridApi | null>(null);
  const [selectedCategories, setSelectedCategories] = useState([
    { label: "All", value: "All" },
  ]);
  const [processedChannelData, setProcessedChannelData] = useState<
    ProcessedChannelData[]
  >([]);
  const [isDataProcessing, setIsDataProcessing] = useState(false);
  const [maxTotalViewIncrease, setMaxTotalViewIncrease] = useState<number>(0);
  const [maxTotalSubscriberIncrease, setMaxTotalSubscriberIncrease] = useState<number>(0);
  const [maxTotalVideoIncrease, setMaxTotalVideoIncrease] = useState<number>(0);

  const tooltipRenderers = useMemo(() => ({
    view: createTooltipRenderer('view'),
    subscriber: createTooltipRenderer('subscriber'),
    video: createTooltipRenderer('video')
  }), []);

  const intervalOptions = useMemo(() => [
    { id: "half_yearly", title: "6 months" },
    { id: "quarterly", title: "3 months" },
    { id: "monthly", title: "1 month" },
    { id: "weekly", title: "7 days" },
  ], []);

  const gridOptions = useMemo(() => ({
    pagination: true,
    paginationPageSize: 20,
    suppressHeaderMenuButton: true,
    autoHeaderHeight: true,
    wrapHeaderText: true,
    floatingFilter: true,
  }), []);

  const handleCategoryChange = useCallback(({ detail }) => {
    const newSelectedOptions = detail.selectedOptions;

    if (newSelectedOptions.some(option => option.value === "All") &&
      !selectedCategories.some(option => option.value === "All")) {
      setSelectedCategories([{ label: "All", value: "All" }]);
    }
    else if (!newSelectedOptions.some(option => option.value === "All") &&
      selectedCategories.some(option => option.value === "All")) {
      setSelectedCategories([]);
    }
    else {
      const filteredOptions = newSelectedOptions.filter(option => option.value !== "All");
      setSelectedCategories(filteredOptions.length > 0 ? filteredOptions : [{ label: "All", value: "All" }]);
    }
  }, [selectedCategories]);

  const processChannelData = useCallback(
    (data: YouTubeChannelTrendsResponse[]): ProcessedChannelData[] => {
      return data.map((channel) => {
        const trendDates = Object.keys(channel.trend_data).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

        const viewCountChangeData = trendDates.map((date, index, array) => {
          if (index === 0) return 0;
          const currentViews = channel.trend_data[date].view_count;
          const previousViews = channel.trend_data[array[index - 1]].view_count;
          return currentViews - previousViews;
        });

        const subscriberCountChangeData = trendDates.map((date, index, array) => {
          if (index === 0) return 0;
          const currentSubscribers = channel.trend_data[date].subscriber_count;
          const previousSubscribers = channel.trend_data[array[index - 1]].subscriber_count;
          return currentSubscribers - previousSubscribers;
        });

        const videoCountChangeData = trendDates.map((date, index, array) => {
          if (index === 0) return 0;
          const currentVideos = channel.trend_data[date].video_count;
          const previousVideos = channel.trend_data[array[index - 1]].video_count;
          return currentVideos - previousVideos;
        });

        const totalViewIncrease = viewCountChangeData.reduce((sum, change) => sum + change, 0);
        const totalSubscriberIncrease = subscriberCountChangeData.reduce((sum, change) => sum + change, 0);
        const totalVideoIncrease = videoCountChangeData.reduce((sum, change) => sum + change, 0);

        return {
          ...channel,
          viewCountChangeData,
          subscriberCountChangeData,
          videoCountChangeData,
          totalViewIncrease,
          totalSubscriberIncrease,
          totalVideoIncrease,
        };
      });
    },
    []
  );

  const getHeatmapColor = useCallback((value: number, max: number, startColor: string, endColor: string) => {
    const colorScale = interpolateRgb(startColor, endColor);
    const interpolatedColor = rgb(colorScale(value / max));
    return `rgb(${interpolatedColor.r}, ${interpolatedColor.g}, ${interpolatedColor.b})`;
  }, []);

  const getViewHeatmapColor = useCallback((totalViewIncrease: number) => {
    return getHeatmapColor(
      totalViewIncrease,
      maxTotalViewIncrease,
      isDarkTheme ? "#0A211A" : "#e6f4ea",  
      isDarkTheme ? "#2d9474" : "#059669"
    );
  }, [maxTotalViewIncrease, getHeatmapColor, isDarkTheme]);

  const getSubscriberHeatmapColor = useCallback((totalSubscriberIncrease: number) => {
    return getHeatmapColor(
      totalSubscriberIncrease, 
      maxTotalSubscriberIncrease, 
      isDarkTheme ? "#152E48" : "#e0f2fe",  
      isDarkTheme ? "#3c7db1" : "#0284c7" 
    );
  }, [maxTotalSubscriberIncrease, getHeatmapColor, isDarkTheme]);

  const getVideoHeatmapColor = useCallback((totalVideoIncrease: number) => {
    return getHeatmapColor(
      totalVideoIncrease, 
      maxTotalVideoIncrease, 
      isDarkTheme ? "#2d1418" : "#fee2e2",  
      isDarkTheme ? "#b13c3c" : "#dc2626" 
    );
  }, [maxTotalVideoIncrease, getHeatmapColor, isDarkTheme]);

  const getTextColor = useCallback((value: number, max: number) => {
    const intensity = value / max;
    return isDarkTheme 
      ? intensity > 0.2 ? '#ffffff' : '#d1d5db'
      : intensity > 0.4 ? '#ffffff' : '#000000';
  }, [isDarkTheme]);

  useEffect(() => {
    if (!Array.isArray(channelData) || channelData.length === 0) return;

    setIsDataProcessing(true);
    
    const processData = () => {
      let processedData = processChannelData(channelData as YouTubeChannelTrendsResponse[]);

      if (selectedCategories.length > 0 && !selectedCategories.some(cat => cat.value === "All")) {
        processedData = processedData.filter(channel =>
          selectedCategories.some(cat => channel.category === cat.value)
        );
      }

      const maxes = processedData.reduce((acc, channel) => ({
        views: Math.max(acc.views, channel.totalViewIncrease),
        subscribers: Math.max(acc.subscribers, channel.totalSubscriberIncrease),
        videos: Math.max(acc.videos, channel.totalVideoIncrease)
      }), { views: 0, subscribers: 0, videos: 0 });

      setMaxTotalViewIncrease(maxes.views);
      setMaxTotalSubscriberIncrease(maxes.subscribers);
      setMaxTotalVideoIncrease(maxes.videos);

      processedData.sort((a, b) => {
        if (b.doc_count !== a.doc_count) return b.doc_count - a.doc_count;
        return b.totalViewIncrease - a.totalViewIncrease;
      });

      setProcessedChannelData(processedData);
      setIsDataProcessing(false);
    };

    requestAnimationFrame(processData);
  }, [channelData, selectedCategories, processChannelData]);

  const sparklineOptions = useMemo(() => ({
    view: {
      type: "column",
      fill: "#4caf50",
      stroke: "#4caf50",
      paddingInner: 0.3,
      padding: { top: 5, bottom: 5 },
      axis: { stroke: "#e0e0e0" },
      tooltip: { renderer: tooltipRenderers.view },
    },
    subscriber: {
      type: "column",
      fill: "#2196F3",
      stroke: "#2196F3",
      paddingInner: 0.3,
      padding: { top: 5, bottom: 5 },
      axis: { stroke: "#e0e0e0" },
      tooltip: { renderer: tooltipRenderers.subscriber },
    },
    video: {
      type: "column",
      fill: "#ff5722",
      stroke: "#ff5722",
      paddingInner: 0.3,
      padding: { top: 5, bottom: 5 },
      axis: { stroke: "#e0e0e0" },
      tooltip: { renderer: tooltipRenderers.video },
    }
  }), [tooltipRenderers]);

  const columnDefs = useMemo<ColDef[]>(
    () => [
      { field: "doc_count", headerName: "Times in top 200", maxWidth: 80 },
      {
        field: "title",
        headerName: "Channel",
        flex: 2,
        minWidth: 300,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: [
            "contains",
            "notContains",
            "equals",
            "notEqual",
            "startsWith",
            "endsWith",
          ],
          defaultOption: "contains",
        },
        cellRenderer: (params: any) => (
          <Link
            href={`https://www.youtube.com/channel/${params.data.channel_id}`}
            external
          >
            {params.value}
          </Link>
        ),
      },
      {
        field: "view_count",
        headerName: "Views",
        valueFormatter: (params) => String(shortenInteger(params.value)),
        maxWidth: 100,
      },
      {
        field: "subscriber_count",
        headerName: "Subscribers",
        valueFormatter: (params) => String(shortenInteger(params.value)),
        maxWidth: 130,
      },
      {
        field: "video_count",
        headerName: "Videos",
        valueFormatter: (params) => String(shortenInteger(params.value)),
        maxWidth: 110,
      },
      {
        field: "viewCountChangeData",
        headerName: "View Count Change",
        cellRenderer: "agSparklineCellRenderer",
        sortable: false,
        cellRendererParams: {
          sparklineOptions: sparklineOptions.view,
        },
        maxWidth: 180,
      },
      {
        field: "subscriberCountChangeData",
        headerName: "Subscriber Count Change",
        cellRenderer: "agSparklineCellRenderer",
        sortable: false,
        cellRendererParams: {
          sparklineOptions: sparklineOptions.subscriber,
        },
        maxWidth: 180,
      },
      {
        field: "videoCountChangeData",
        headerName: "Video Count Change",
        cellRenderer: "agSparklineCellRenderer",
        sortable: false,
        cellRendererParams: {
          sparklineOptions: sparklineOptions.video,
        },
        maxWidth: 180,
      },
      {
        field: "publishedOn",
        cellDataType: "date",
        cellRenderer: (params: any) => {
          const date = new Date(params.value);
          return format(date, "dd MMM, yyyy");
        },
        maxWidth: 120,
        valueFormatter: (params: any) => {
          return params.value;
        },
      },
      {
        field: "totalViewIncrease",
        headerName: "New Views",
        valueFormatter: (params) => String(shortenInteger(params.value)),
        cellStyle: (params) => ({
          backgroundColor: getViewHeatmapColor(params.value),
          color: getTextColor(params.value, maxTotalViewIncrease)
        }),
        maxWidth: 120,
      },
      {
        field: "totalSubscriberIncrease",
        headerName: "New Subscribers",
        valueFormatter: (params) => String(shortenInteger(params.value)),
        cellStyle: (params) => {
          const style = {
            backgroundColor: getSubscriberHeatmapColor(params.value),
            color: getTextColor(params.value, maxTotalSubscriberIncrease)
          };
          return style;
        },
        maxWidth: 120,
      },
      {
        field: "totalVideoIncrease",
        headerName: "New Videos",
        valueFormatter: (params) => String(shortenInteger(params.value)),
        cellStyle: (params) => ({
          backgroundColor: getVideoHeatmapColor(params.value),
          color: getTextColor(params.value, maxTotalVideoIncrease)
        }),
        maxWidth: 120,
      },
      {
        field: "category",
        headerName: "Category",
        maxWidth: 150,
      },
    ],
    [sparklineOptions, getViewHeatmapColor, getSubscriberHeatmapColor, getVideoHeatmapColor, 
     getTextColor, maxTotalViewIncrease, maxTotalSubscriberIncrease, maxTotalVideoIncrease]
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      filter: false,
      suppressHeaderMenuButton: true,
      autoHeaderHeight: true,
      wrapHeaderText: true,
      floatingFilter: true,
    }),
    [],
  );

  const handleIntervalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInterval(event.target.value);
    setIsDataProcessing(true);
  };

  return (
    <SpaceBetween size="m" direction="vertical">
      <div className="flex flex-row justify-between bg-gradient-to-r from-slate-200 to-white dark:from-slate-800 dark:to-[#161D26] p-2 px-8 rounded-lg">
        <fieldset>
          <legend className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
            Interval
          </legend>
          <div className=" space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
            {intervalOptions.map((intervalOption) => (
              <div key={intervalOption.id} className="flex items-center">
                <input
                  id={intervalOption.id}
                  name="interval-option"
                  type="radio"
                  value={intervalOption.id}
                  checked={interval === intervalOption.id}
                  onChange={handleIntervalChange}
                  className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                />
                <label
                  htmlFor={intervalOption.id}
                  className="ml-3 block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                >
                  {intervalOption.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <fieldset>
          <legend className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
            Category
          </legend>
          <Multiselect
            selectedOptions={selectedCategories}
            onChange={handleCategoryChange}
            options={YOUTUBE_CATEGORIES}
            placeholder="Filter by category"
            hideTokens
          />
        </fieldset>
      </div>
      <div className={`${theme} h-[75vh]`}>
        <AgGridReact
          rowData={processedChannelData}
          loading={isLoading || isDataProcessing}
          loadingOverlayComponent={CustomLoadingOverlay}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={(params) => (gridApiRef.current = params.api)}
          {...gridOptions}
        />
      </div>
    </SpaceBetween>
  );
};

export default VideoComponent;