import { useCallback, useEffect, useState } from "react";
import { useGetGenericPreference_V2 } from "../generic_v2/hooks/useGetGenericPreference_V2";
import { usePostGenericPreference_V2 } from "../generic_v2/hooks/usePostGenericPreference_V2";
import { usePutGenericPreference_V2 } from "../generic_v2/hooks/usePutGenericPreference_V2";
import { ApiURLParams } from "../generic_v2/requests"
import { invalidatePreferenceData } from "../generic_v2/utils/serviceUtils";
import { FilterObject } from "../../types/filterTypes";
import { convertFilterObjectsCompat } from "../../utils/filters/filterUtils";


interface UseFilterPreferencesProps {
  apiParams: ApiURLParams;
  defaultFilterObject: FilterObject;
}

export const useFilterPreferences = ({
  apiParams,
  defaultFilterObject,
}: UseFilterPreferencesProps) => {

  const [loadedFilters, setLoadedFilters] = useState<FilterObject[]>([]);
  const [loadedPrimaryFilters, setLoadedPrimaryFilters] = useState<FilterObject[]>([]);
  const [primaryFilter, setPrimaryFilter] = useState<FilterObject>(null);
  const [savePrefsPayload, setSavePrefsPayload] = useState({});


  const { data: rankingPreferenceData } = useGetGenericPreference_V2({
    apiParams,
  });
  const { mutate: updatePreference } = usePutGenericPreference_V2();
  const { mutate: postGenericPreference } = usePostGenericPreference_V2({
    apiParams,
    onSuccess: invalidatePreferenceData,
  });


  useEffect(() => {
    const pref = rankingPreferenceData?.[0]?.data;

    if (pref) {
      if (pref.filters) {
        // Compatibility with old filter format
        const convertedFilters = convertFilterObjectsCompat(pref.filters.filter(f => f != null), defaultFilterObject);
        setLoadedFilters(convertedFilters);
      }
      if (pref.primaryFilter?.length > 0) {
        const convertedPrimaryFilters = convertFilterObjectsCompat(pref.primaryFilter.filter(f => f != null), defaultFilterObject);
        const primaryFilter = convertedPrimaryFilters.find(f => f.category === defaultFilterObject.category) ?? null;
        setPrimaryFilter(primaryFilter);
        setLoadedPrimaryFilters(convertedPrimaryFilters);
      }
    }
  }, [rankingPreferenceData]);

  const savePreferences = (payload) => {
    const prefValue = rankingPreferenceData?.[0];

    if (Object.keys(prefValue?.data ?? {}).length > 0) {
      const { data } = prefValue;
      const finalPayload = { ...data, ...payload };

      updatePreference({ id: prefValue.id, payload: finalPayload });
      return;
    }

    postGenericPreference(payload);
  };

  const saveLoadedFilters = (filters: Array<FilterObject>) => {
    setLoadedFilters(filters);
    setSavePrefsPayload(prev => ({ ...prev, filters }));
  };

  const savePrimaryFilter = useCallback((filter: FilterObject) => {
    const newPrimaryFilters = Array.from(loadedPrimaryFilters.filter(f => f != null && f.category !== defaultFilterObject.category));
    if (filter) {
      newPrimaryFilters.push(filter);
    }
    setPrimaryFilter(filter);
    setLoadedPrimaryFilters(newPrimaryFilters);
    setSavePrefsPayload(prev => ({ ...prev, primaryFilter: newPrimaryFilters }));
  }, [defaultFilterObject, loadedPrimaryFilters]);


  useEffect(() => {
    if (Object.keys(savePrefsPayload).length > 0) {
      savePreferences(savePrefsPayload);
    }
  }, [savePrefsPayload]);


  return {
    loadedFilters,
    primaryFilter,
    saveLoadedFilters,
    savePrimaryFilter,
  };
};