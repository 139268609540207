import { AgCharts } from "ag-charts-react";
import React from "react";
import { useChartConfig } from "./hooks/useChartConfig";


interface FrequencyChartProps {
  timeseriesData: Array<any>;
}

export const FrequencyChart: React.FC<FrequencyChartProps> = ({
  timeseriesData,
}) => {

  const chartOptions = useChartConfig(timeseriesData);

  return (
    <AgCharts
      options={chartOptions}
    />
  );
};