import { useEffect, useState } from "react";
import { ApiURLParams } from "../requests";
import { invalidatePreferenceData } from "../utils/serviceUtils";
import { PreferenceItem, useGetGenericPreference_V2 } from "./useGetGenericPreference_V2";
import { usePostGenericPreference_V2 } from "./usePostGenericPreference_V2";
import { usePutGenericPreference_V2 } from "./usePutGenericPreference_V2";

interface UseGenericPreferenceProps {
  apiParams: ApiURLParams;
  onPreferenceFirstLoad?: (data: any) => void;
  onGetPreferenceSuccess?: (data: any) => void;
  onGetPreferenceError?: () => void;
  onGetPreferenceSettled?: () => void;
  onPostPreferenceSuccess?: (data: any) => void;
  onPostPreferenceError?: () => void;
  onPostPreferenceSettled?: () => void;
}

export const useGenericPreference_V2 = ({
  apiParams,
  onPreferenceFirstLoad,
  onGetPreferenceSuccess,
  onGetPreferenceError,
  onGetPreferenceSettled,
  onPostPreferenceSuccess,
  onPostPreferenceError,
  onPostPreferenceSettled,
}: UseGenericPreferenceProps) => {
  const path = [apiParams.service, apiParams.module, apiParams.tab].filter(Boolean).join("/");

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const handlePostPreferenceSuccess = (data) => {
    invalidatePreferenceData();
    onPostPreferenceSuccess?.(data);
  }

  const handleGetPreferenceSuccess = (data) => {
    onGetPreferenceSuccess?.(data);
    if (isFirstLoad) {
      const prefValue = data?.data?.find((d: PreferenceItem) => d.path === path);
      onPreferenceFirstLoad?.(prefValue?.data);
      setIsFirstLoad(false);
    }
  }

  const { data: getPreferenceData, isLoading: getPreferenceIsLoading, error: getPreferenceError } = useGetGenericPreference_V2({ 
    apiParams,
    onSuccess: handleGetPreferenceSuccess,
    onError: onGetPreferenceError,
    onSettled: onGetPreferenceSettled,
  });
  const { mutate: updatePreference, isLoading: putPreferenceIsLoading, error: putPreferenceError } = usePutGenericPreference_V2();
  const { mutate: postGenericPreference, isLoading: postPreferenceIsLoading, error: postPreferenceError } = usePostGenericPreference_V2({
    apiParams,
    onSuccess: handlePostPreferenceSuccess,
    onError: onPostPreferenceError,
    onSettled: onPostPreferenceSettled,
  });

  const savePreference = (payload) => {
    const prefValue = getPreferenceData?.find((d: PreferenceItem) => d.path === path);

    if (Object.keys(prefValue?.data ?? {}).length > 0) {
      const { data } = prefValue;
      const updatedData = { ...data, ...payload };

      updatePreference({ id: prefValue.id, payload: updatedData });
      return;
    }

    postGenericPreference(payload);
  };

  useEffect(() => {
    const preferenceData = getPreferenceData?.find((d: PreferenceItem) => d.path === path)?.data;
    if (preferenceData) {
      onPreferenceFirstLoad?.(preferenceData);
      setIsFirstLoad(false);
    }
  }, []);

  return {
    preferenceData: getPreferenceData?.find((d: PreferenceItem) => d.path === path)?.data,
    savePreference,
    getPreferenceIsLoading,
    getPreferenceError,
    postPreferenceIsLoading,
    postPreferenceError,
    putPreferenceIsLoading,
    putPreferenceError,
  };
};