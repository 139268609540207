import React, { PropsWithChildren } from 'react';
import { useThemeContext } from '../../app/ThemeProvider';
import { COLOR_MAP } from './constants';

interface TagProps {
  color?: "blue" | "red" | "green" | "yellow" | "purple";
  href?: string;
}

const Tag: React.FC<PropsWithChildren<TagProps>> = ({
  children,
  color="blue",
  href,
}) => {
  const { mode } = useThemeContext();

  return (
    <a href={href} target="_blank" className={href ? "pointer-events-auto" : "pointer-events-none"}>
      <div className={`relative px-3 py-1 border h-fit w-fit rounded-full flex items-center justify-center group hover:brightness-125 ${COLOR_MAP[color]["border"][mode]}`}>
        <div className={`absolute w-full h-full rounded-full opacity-0 group-hover:opacity-10 pointer-events-none ${COLOR_MAP[color]["background"][mode]}`}></div>
        <div className={`text-xs text-nowrap ${COLOR_MAP[color]["text"][mode]}`}>
          {children}
        </div>
      </div>
    </a>
  );
};

export default Tag;