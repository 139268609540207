import { Button, Icon, Link } from "@cloudscape-design/components";
import React, { useMemo } from "react";
import { convertData } from "../../../../../../components/datapoint-comparison/utils";
import { VerticalIcon } from "../../../../../../components/VerticalIcon";
import renderTrend, { formattedTrendBadge } from "../../../../../../utils/trendIcon";
import { chartMap } from "../../../../../ranking/global-ranking/pantheon-ranking/components/DataTable/hooks/useGridConfig";
import { CHART_LABELS } from "../../../../hooks/useGridConfig";
import { getAdditionalColumnDefinition } from "../../../../../ranking/global-ranking/pantheon-ranking/components/DataTable/gridUtils";
import { useGoogleDashboardContext } from "../../../hooks/useGoogleDashboardContext";
import { useSearchParams } from "react-router-dom";

export const useGridConfig = ({
  data,
  additionalPlatformColumns,
  selectedAdditionalColumns=[],
}) => {

  const { setSelectedTab } = useGoogleDashboardContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultColDef = {
    sortable: true,
    flex: 1,
    suppressHeaderMenuButton: true,
    suppressHeaderFilterButton: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    minWidth: 100,
    suppressMovable: true,
  };
  
  const rowData = useMemo(() => {
    if (!data) return [];
    const convertedData = convertData(data).map(x => ({ ...x, ...x.originalData }));
    return convertedData;
  }, [data]);

  const columnDefs = useMemo(() => {
    const mainColumns = [
      {
        field: "rank",
        headerText: "Rank",
        pinned: "left",
        suppressHeaderMenuButton: true,
        width: 150,
        minWidth: 100,
        maxWidth: 150,
        checkboxSelection: true,
        cellRenderer: (params) => {
          if (params.data.rank_change != null) {
            const rankTrend = params.data.rank_change > 0 ? "up" : params.data.rank_change < 0 ? "down" : "same";
            const newParams = { ...params, data: { ...params.data, rank_trend: rankTrend } };
            return renderTrend(newParams, "rank", "rank_change", "rank_trend");
          }
          return params.value;
        },
      },
      {
        field: "vertical",
        headerName: "Category",
        hide: true,
      },
      {
        field: "release_date",
        headerName: "Release Date",
        hide: true,
        filterValueGetter: (params) => params.data.release_date,
      },
      {
        field: "ip",
        headerName: "Title",
        minWidth: 300,
        flex: 1,
        pinned: "left",
        suppressHeaderMenuButton: true,
        cellRenderer: (params) => {
          let iconVariant;
          if (params.data.tracked === true) {
            iconVariant = "success";
          } else if (params.data.tracked === false) {
            iconVariant = "disabled";
          } else {
            iconVariant = "disabled";
          }
    
          if (!params.value) return null;
          const ipId = params.data.ip_id;
          const release_year = params.data.release_date ? new Date(Date.parse(params.data.release_date)).getFullYear() : "N/A";
          return (
            <div className="flex gap-2 mt-2.5 align-middle">
              <Icon
                name={"status-positive"}
                variant={iconVariant}
              />
              <span className="text-slate-500 flex justify-center">
                <VerticalIcon vertical={params.data.vertical} />
              </span>
              <Link href={`/item/${ipId}`}>
                <span>{params.data.ip}</span>
              </Link>
              <span className="text-xs text-slate-500 mt-0.5">
                {release_year}
              </span>
            </div>
          );
        },
      },
      {
        field: "indexed_volume",
        headerName: "Search Interest",
        suppressHeaderMenuButton: true,
        valueGetter: (params) => params.data.indexed_volume * 1000000,
        cellRenderer: (params) => {
          const volumeChange = params.data.volume_change * 1000000;
          const origValue = params.value - volumeChange;
          const percentage = Math.round(((params.value - origValue) / origValue) * 100);
          return (
            <div className="flex gap-2 items-center">
              <span>{params.value.toLocaleString(undefined, { maximumFractionDigits: 1 })}</span>
              <span>{formattedTrendBadge(
                percentage, 
                (value) => `${Math.abs(value)}%`,
              )}</span>
            </div>
          );
        },
      },
      {
        headerName: "Actions",
        suppressHeaderMenuButton: true,
        pinned: "right",
        maxWidth: 150,
        cellRenderer: (params) => {
          return (
            <Button
              variant="inline-link"
              onClick={() => {
                setSearchParams(prev => {
                  prev.set("title", params.data.ip_id);
                  prev.set("tab", "insights");
                  setSelectedTab("insights");
                  return prev;
                });
              }}
            >
              <span className="text-xs">View title insights</span>
            </Button>
          );
        },
      },
    ];

    const additionalColumns = additionalPlatformColumns ? additionalPlatformColumns.filter(key => key !== CHART_LABELS.SCORE_CHANGE).map(key => {
      const { field, headerName, cellDataType } = chartMap[key];
      const hide = !selectedAdditionalColumns.find(o => o === key);
      return getAdditionalColumnDefinition(
        field,
        headerName,
        cellDataType,
        hide,
      );
    }) : [];

    return [
      ...mainColumns,
      ...additionalColumns,
    ];
  }, [selectedAdditionalColumns, additionalPlatformColumns]);

  return {
    defaultColDef,
    rowData,
    columnDefs,
  };
};