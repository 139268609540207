import { Vertical } from "../../../types/verticalTypes";
import axiosInstance from "../../../utils/axios";

const verticalMap = {
  [Vertical.Movies]: "movies",
  [Vertical.Series]: "series",
  [Vertical.Games]: "videogames",
};

export const getFilterOptionsData = ({
  filterKey,
  vertical,
  query,
  size,
  disabled,
}) => {

  if (disabled) {
    return Promise.resolve({ data: [] });
  }

  const params = { field: filterKey };
  if (vertical) {
    params.vertical = verticalMap[vertical];
  }
  if (size) {
    params.size = size;
  }
  if (query) {
    params.query = query;
  }

  return axiosInstance.request({
    url: `/filters/v2`,
    method: "GET",
    params: params,
  });
};