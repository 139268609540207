import React from "react";
import { Category } from "../../constants";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import { useGetGoogleRankingData } from "../../api/hooks/useGetGoogleRankingData";
import { useGridConfig } from "./hooks/useGridConfig";
import { useGetGridTheme } from "../../../../../hooks/UseTheme/useGetGridTheme";
import CustomLoadingOverlay from "../../../../../components/PantheonLoading";
import TableLegend from "../../../../../components/table/TableLegend";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useGoogleDashboardContext } from "../../hooks/useGoogleDashboardContext";

interface RankingTableProps {
  gridRef: any;
  selectedAdditionalColumns: Array<OptionDefinition>;
  additionalColumnOptions: Array<OptionDefinition>;
  dateRange: DateRange;
  selectedCategory: Category;
  selectedIpIds: Array<string>;
  setSelectedIpIds: React.Dispatch<React.SetStateAction<Array<string>>>;
}

export const RankingTable: React.FC<RankingTableProps> = ({
  gridRef,
  selectedAdditionalColumns,
  additionalColumnOptions,
  dateRange,
  selectedCategory,
  selectedIpIds,
  setSelectedIpIds,
}) => {

  const { setSplitPanelStatus } = useGoogleDashboardContext();

  const { theme } = useGetGridTheme();

  const { data, isLoading, error } = useGetGoogleRankingData({
    enabled: selectedCategory != null,
    category: selectedCategory?.value,
    startDate: moment(dateRange.startDate).format("YYYY-MM-DD"),
    endDate: moment(dateRange.endDate).format("YYYY-MM-DD"),
  });

  const gridOptions = useGridConfig({ 
    data, 
    additionalPlatformColumns: additionalColumnOptions.map(c => c.value),
    selectedAdditionalColumns: selectedAdditionalColumns.map(c => c.value),
  });

  return (
    <div className="flex flex-col gap-4">
      <div className={`${theme} h-[80vh]`}>
        <AgGridReact
          ref={gridRef}
          {...gridOptions as any}
          enableAdvancedFilter={true}
          includeHiddenColumnsInAdvancedFilter={true}
          loading={isLoading}
          loadingOverlayComponent={CustomLoadingOverlay}
          pagination={true}
          paginationPageSize={50}
          rowSelection="multiple"
          suppressRowClickSelection
          onGridReady={(params) => {
            const advancedFilterElement = document.getElementById("advancedFilterParent");
        
            if (advancedFilterElement) {
              params.api.setGridOption("advancedFilterParent", advancedFilterElement);
            }
          }}
          onRowSelected={(params) => {
            if (params.source === "api") {
              return;
            }
            const rowIpId = params.data.ip_id;
            setSelectedIpIds(prev => {
              const selectedIpIdsCopy = [...prev];
              if (params.node.isSelected()) {
                if (selectedIpIdsCopy.includes(rowIpId)) {
                  return;
                }
                selectedIpIdsCopy.push(rowIpId);
                setSplitPanelStatus(true);
              } else {
                const index = selectedIpIdsCopy.indexOf(rowIpId);
                if (index === -1) {
                  return;
                }
                selectedIpIdsCopy.splice(index, 1);
              }
              if (setSelectedIpIds) {
                setSelectedIpIds(selectedIpIdsCopy);
              }
              return selectedIpIdsCopy;
            });
          }}
          onPaginationChanged={(params) => {
            params.api.forEachNode((node) => {
              if (selectedIpIds.includes(node?.data?.ip_id)) {
                node.setSelected(true);
              } else {
                node.setSelected(false);
              }
            });
          }}
        />
      </div>
      <TableLegend />
    </div>
  );
};