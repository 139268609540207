import React from "react";
import { GRPantheonRankingIndex } from "../../GRPantheonRankingIndex";
import { Vertical } from "../../../../../../types/verticalTypes";

export const GRSeries = () => {
  return (
    <GRPantheonRankingIndex
      vertical={Vertical.Series}
      title="Series"
    />
  );
};
