import React, { useEffect } from "react";
import { useGridConfig } from "./hooks/useGridConfig";
import { AgGridReact } from "ag-grid-react";
import CustomLoadingOverlay from "../../../../../components/PantheonLoading";
import { useGetGridTheme } from "../../../../../hooks/UseTheme/useGetGridTheme";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";

interface RankingRawDataV2Props {
  gridRef: any;
  data: any;
  isLoading: boolean;
  enabledWeightColumns: Array<string>;
  additionalColumnOptions: Array<OptionDefinition>;
  selectedAdditionalColumns: Array<OptionDefinition>;
  setFilteredData: (filteredData: Array<any>) => void;
}

export const RankingRawDataV2: React.FC<RankingRawDataV2Props> = ({ 
  gridRef,
  data,
  isLoading=false,
  enabledWeightColumns=[],
  additionalColumnOptions,
  selectedAdditionalColumns,
  setFilteredData,
}) => {

  const { theme } = useGetGridTheme();

  const gridConfig = useGridConfig({
    enabledWeightColumns,
    additionalPlatformColumns: additionalColumnOptions.map(c => c.value),
    selectedAdditionalColumns: selectedAdditionalColumns.map(c => c.value),
  });

  const setExternalFilteredData = () => {
    const api = gridRef.current?.api;
    if (!api) return;

    const filteredData = [];
    api.forEachNodeAfterFilterAndSort((rowNode, index) => {
      filteredData.push(rowNode.data);
    });
    setFilteredData(filteredData);
  };

  useEffect(() => {
    setExternalFilteredData();
  }, [data]);

  return (
    <div>
      <div style={{ width: "100%", height: "70vh" }} className={theme}>
        <AgGridReact
          ref={gridRef}
          rowData={data}
          {...gridConfig as any}
          loading={isLoading}
          loadingOverlayComponent={CustomLoadingOverlay}
          pagination={true}
          paginationPageSize={100}
          enableAdvancedFilter={true}
          defaultExcelExportParams={{ exportAsExcelTable: true, allColumns: true }}
          defaultCsvExportParams={{ allColumns: true }}
          onFilterChanged={() => {
            setExternalFilteredData();
          }}
        />
      </div>
    </div>
  );
};