import { Autosuggest, Select } from "@cloudscape-design/components";
import React, { useState } from "react";
import { useGetTopSearchDataV2 } from "./api/hooks/useGetTopSearchDataV2";
import { Vertical } from "../../types/verticalTypes";
import { VerticalIcon } from "../VerticalIcon";

export const convertData = (data) => (
  data.map(item => {
    return {
      value: item.ip_id,
      label: item.ip,
      iconUrl: item.image_url,
      iconAlt: `${item.ip} cover`,
      tags: item.tags,
    };
  })
);

const verticalOptions = [
  { label: "All", value: "all", iconName: "search" },
  { label: "Movies", value: Vertical.Movies, iconSvg: (<VerticalIcon vertical={Vertical.Movies} />) },
  { label: "Series", value: Vertical.Series, iconSvg: (<VerticalIcon vertical={Vertical.Series} />) },
  { label: "Games", value: Vertical.Games, iconSvg: (<VerticalIcon vertical={Vertical.Games} />) },
  { label: "Franchises", value: "Franchises", iconSvg: (<VerticalIcon vertical={"Franchises"} />) },
  { label: "Talent", value: "Talent", iconName: "group" },
];

const CommonSearch = () => {
  const [query, setQuery] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [selectedVertical, setSelectedVertical] = useState(verticalOptions.find(o => o.value === localStorage.getItem("searchCategory")) ?? verticalOptions[0]);

  const { data: searchResults, isLoading } = useGetTopSearchDataV2({
    q: searchQuery,
    verticals: selectedVertical.value === "all" ? [Vertical.Movies, Vertical.Series, Vertical.Games, "Franchises", "Talent"] : [selectedVertical.value],
    limit: 50,
  });

  return (
    <div className="relative w-full flex">
      <div className="[&_button]:!rounded-se-none [&_button]:!rounded-ee-none [&_button]:!border-r-0">
        <Select
          options={verticalOptions}
          selectedOption={selectedVertical}
          onChange={({ detail }) => {
            setSelectedVertical(detail.selectedOption);
            localStorage.setItem("searchCategory", detail.selectedOption.value);
          }}
        />
      </div>
      <div className="grow [&_input]:!rounded-ss-none [&_input]:!rounded-es-none">
        <Autosuggest
          onChange={({ detail }) => setQuery(detail.value)}
          onLoadItems={({ detail }) => setSearchQuery(detail.filteringText)}
          onSelect={({ detail }) => {
            if (detail.selectedOption) {
              setQuery(detail.selectedOption.label);
              if (detail.selectedOption.value.startsWith("nm")) {
                window.location = `${window.location.origin}/item/talent/${detail.selectedOption.value}`;
              } else {
                window.location = `${window.location.origin}/item/${detail.selectedOption.value}`;
              }
            }
          }}
          expandToViewport
          value={query}
          options={ (searchResults && searchResults?.results?.length > 0) ? convertData(searchResults.results) : [] }
          placeholder="Search"
          filteringType="none"
          empty={query?.length > 0 ? "No results found" : null}
          statusType={isLoading ? "loading" : "finished"}
          enteredTextLabel={(value) => `Searching for "${value}"` + (selectedVertical.value !== "all" ? ` in ${selectedVertical.label.toLocaleLowerCase()}` : "")}
        />
      </div>
    </div>
  );
};

export default CommonSearch;