import axiosInstance from "../../../../../utils/axios";

export const getTrendingRankingDataV2 = ({
  startTs,
  endTs,
}) => {

  return axiosInstance.request({
    url: `/ranking/raw/v2?gte=${startTs}&lte=${endTs}&interval=none`,
    method: "GET",
    headers: {
      "Content-Type": "text/plain",
    },
  });
};


