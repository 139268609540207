import React, { useEffect, useMemo, useState } from "react";
import { useMetrics } from "../../../../tools/compare/hooks/useMetrics";
import { DatapointComparisonProperties } from "../../../../../components/datapoint-comparison/types";
import DatapointComparison from "../../../../../components/datapoint-comparison/DatapointComparison";
import { Button, SplitPanel } from "@cloudscape-design/components";
import { METRIC_GROUPS } from "../../../../../components/datapoint-comparison/constants";
import _ from "lodash";
import { urlParamsFromParameters } from "../../../../../components/datapoint-comparison/utils";
import { Category } from "../../constants";

interface CompareContainerProps {
  dateRange: DateRange;
  selectedCategory: Category;
  selectedIpIds: Array<string>;
  setSelectedIpIds: React.Dispatch<React.SetStateAction<Array<string>>>;
}

export const CompareContainer: React.FC<CompareContainerProps> = ({
  dateRange,
  selectedCategory,
  selectedIpIds,
  setSelectedIpIds,
}) => {

  const [properties, setProperties] = useState<DatapointComparisonProperties>({});

  const { metrics } = useMetrics({});

  useEffect(() => {
    const googleMetrics = metrics.filter(m => m.group === METRIC_GROUPS.GOOGLE);
    const activeMetricKey = googleMetrics.find(m => m.datapoints[0].datapoint.key === selectedCategory.value).key;

    setProperties({
      metricKeys: googleMetrics.map(m => m.key),
      activeMetricKey: activeMetricKey,
      parameters: [
        {
          dateRange: {
            startDate: dateRange?.startDate,
            endDate: dateRange?.endDate,
          },
          titleIds: selectedIpIds,
        },
      ],
    });
  }, [selectedCategory, selectedIpIds, dateRange]);

  useEffect(() => {
    if (!properties || !properties.parameters) return;
    const newTitleIds = properties.parameters[0].titleIds;
    if (!_.isEqual(newTitleIds.sort(), selectedIpIds.sort())) {
      setSelectedIpIds(newTitleIds);
    }
  }, [properties]);

  return (
    <SplitPanel
      header={
        <div className="sp-header-text flex justify-start items-center w-full">
          <div className="inline-block grow flex items-center">
            <span className="mr-1">Compare Titles</span>
            <Button
              variant="inline-icon"
              iconName="external"
              ariaLabel="Open in new tab"
              onClick={() => {
                const urlParams = urlParamsFromParameters(properties);
                window.open(`/tools/compare?${urlParams}`, "_blank");
              }}
            />
          </div>
        </div> as any
      }
      hidePreferencesButton={true}
      i18nStrings={{
        preferencesTitle: "Preferences",
        preferencesPositionLabel: "Panel position",
        preferencesPositionDescription:
          "Choose the default position for the split panel.",
        preferencesPositionSide: "Side",
        preferencesPositionBottom: "Bottom",
        preferencesCancel: "Cancel",
        preferencesConfirm: "Confirm",
        closeButtonAriaLabel: "Close panel",
        openButtonAriaLabel: "Open panel",
        resizeHandleAriaLabel: "Resize split panel",
      }}
    >
      <div className="mb-4">
        <DatapointComparison
          metrics={metrics}
          properties={properties}
          setProperties={setProperties}
          options={{
            container: {
              disablePadding: true,
              disableBorder: true,
            },
            parameters: {
              enabled: true,
              variant: "tokens",
              showDateRanges: false,
            },
            chart: {
              height: 400,
            },
            summary: {
              enabled: false,
            },
          }}
        />
      </div>
    </SplitPanel>
  );
};