interface MapCreateMediaResponseProps {
  ip: string;
  genre: string | string[];
  ip_id: string;
  status?: string;
  release_date: string;
  image_url: string;
  summary: string;
  vertical?: string;
}

export const mapCreateMediaResponse = ({
  ip,
  genre,
  ip_id,
  status,
  release_date,
  image_url,
  summary,
  vertical,
}: MapCreateMediaResponseProps) => ({
  ip,
  genre: Array.isArray(genre) ? genre : [genre],
  ip_id,
  status: status || "Added",
  summary,
  releaseDate: release_date,
  url: vertical === "games" 
    ? `https://images.igdb.com/igdb/image/upload/t_cover_big/${image_url}.jpg`
    : image_url,
});
