import { dispatch } from "../../../../app/store";
import axiosInstance from "../../../../utils/axios";
import {
  fetchLeaderboardData,
  leaderboardDataAction,
} from "../slices/leaderboard-slice";
import { fetchListoptionData } from "../slices/list-options-slice";
import { rankingRawDataAction } from "../slices/ranking-rawdata-slice";

export async function getLeaderboardData(gte, lte, option, size) {
  await dispatch(fetchLeaderboardData(gte, lte, option, size));
}

export async function getListoptionData() {
  await dispatch(fetchListoptionData());
}

export function clearLeaderboardData() {
  dispatch(leaderboardDataAction.clearData());
}

export async function getRankingRawData({ interval, lte, gte }) {
  dispatch(rankingRawDataAction.startLoading());
  try {
    const response = await axiosInstance.request({
      url: `ranking/raw`,
      method: `GET`,
      params: { interval, lte, gte },
    });
    dispatch(rankingRawDataAction.insertTrendRowData(response.data));
  } catch (e) {}
}

export async function getRankingRawDataV2({ lte, gte }) {
  dispatch(rankingRawDataAction.startLoading());
  try {
    const response = await axiosInstance.request({
      url: `ranking/raw/v2`,
      method: `GET`,
      params: { lte, gte },
    });
    dispatch(rankingRawDataAction.insertTrendRowData(response.data));
  } catch (e) {}
}
