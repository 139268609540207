import React, { useEffect, useState } from "react";
import moment from "moment";
import { Box, ButtonDropdown, Multiselect, Select } from "@cloudscape-design/components";
import { agChartLightTheme } from "../../../../hooks/UseTheme/themes";
import { CROSSLINE_TYPE_OPTIONS } from "../../constants";
import { AddTimelineEventBar } from "./AddTimelineEventBar";
import { useCompareContext } from "../../hooks/useCompareContext";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";

export const axisTypeOptions: Array<OptionDefinition> = [
  { value: "number", label: "Linear" },
  { value: "log", label: "Logarithmic" },
];

export const granularityOptions: Array<OptionDefinition> = [
  { value: "day", label: "Day" },
  { value: "week", label: "Week" },
  { value: "month", label: "Month" },
];

interface ChartOptionsProps {
  exportTableRef: any;
  chartRef: any;
  selectedAxisType: OptionDefinition;
  setSelectedAxisType: (selectedAxisType: OptionDefinition) => void;
  selectedGranularity: OptionDefinition;
  setSelectedGranularity: (selectedGranularity: OptionDefinition) => void;
  selectedCrosslineTypes: Array<OptionDefinition>;
  setSelectedCrosslineTypes: (selectedCrosslineTypes: Array<OptionDefinition>) => void;
  chartBaseTheme: string;
  setChartBaseTheme: (chartBaseTheme: string) => void;
}

const ChartOptions: React.FC<ChartOptionsProps> = ({
  exportTableRef,
  chartRef,
  selectedAxisType,
  setSelectedAxisType,
  selectedGranularity,
  setSelectedGranularity,
  selectedCrosslineTypes,
  setSelectedCrosslineTypes,
  chartBaseTheme,
  setChartBaseTheme,
}) => {

  const { activeMetric, options } = useCompareContext();

  const [ dummyCanvas, setDummyCanvas ] = useState<HTMLCanvasElement>(null);
  const [ addEventVisible, setAddEventVisible ] = useState<boolean>(false);

  const createDummyCanvas = () => {
    // Used to hide the theme change flicker when downloading the chart as an image
    const sourceCanvas = chartRef.current.chart.canvasElement;
    const destinationCanvas = document.createElement("canvas");
    destinationCanvas.width = sourceCanvas.width;
    destinationCanvas.height = sourceCanvas.height;
    destinationCanvas.style.width = sourceCanvas.style.width;
    destinationCanvas.style.height = sourceCanvas.style.height;
    destinationCanvas.style.position = "absolute";
    destinationCanvas.style.display = "block";
    destinationCanvas.style.zIndex = "99999";
    destinationCanvas.style.left = "0";
    destinationCanvas.style.top = "0";
    const destCtx = destinationCanvas.getContext("2d");
    destCtx.drawImage(sourceCanvas, 0, 0);
    chartRef.current.chart.canvasElement.parentNode.appendChild(destinationCanvas);
    setDummyCanvas(destinationCanvas);
  };

  useEffect(() => {
    if (chartBaseTheme !== null) {
      setTimeout(() => {
        chartRef.current.download({
          fileName: `timeseries_${activeMetric.key}_${moment.utc().format("YYYY-MM-DDTHH:mm:ss")}`,
        });
        setChartBaseTheme(null);
      }, 100);
    } else if (dummyCanvas !== null) {
      setTimeout(() => {
        dummyCanvas.parentNode?.removeChild(dummyCanvas);
      }, 200);
    }
  }, [chartBaseTheme, dummyCanvas]);

  return (
    <div className="flex items-center justify-between flex-wrap-reverse gap-y-2">
      {options.chart.addTimelineEvents.enabled ? (
        <AddTimelineEventBar
          visible={addEventVisible}
          setVisible={setAddEventVisible}
          chartRef={chartRef}
        />
      ) : (<div></div>)}
      {addEventVisible == false && (
        <div>
          <div className="flex gap-x-2 items-center">
            <Select
              options={granularityOptions}
              selectedOption={selectedGranularity}
              onChange={({ detail }) => setSelectedGranularity(detail.selectedOption)}
            />
            <Select
              options={axisTypeOptions}
              selectedOption={selectedAxisType}
              onChange={({ detail }) => setSelectedAxisType(detail.selectedOption)}
            />
            <Multiselect
              selectedOptions={selectedCrosslineTypes}
              options={CROSSLINE_TYPE_OPTIONS}
              onChange={({ detail }) => setSelectedCrosslineTypes(detail.selectedOptions as Array<OptionDefinition>)}
              placeholder="Show or hide timeline events"
              hideTokens
              //expandToViewport
            />
            <ButtonDropdown
              items={[
                { text: "Chart as PNG", id: "download_image" },
                { text: "Series data as CSV", id: "download_data_csv" },
                { text: "Series data as XLSX", id: "download_data_xlsx" },
              ]}
              onItemClick={({ detail }) => {
                switch(detail.id) {
                  case "download_image":
                    createDummyCanvas();
                    setChartBaseTheme(agChartLightTheme.baseTheme);
                    break;
                  case "download_data_csv":
                    exportTableRef.current.api.exportDataAsCsv({
                      fileName: `timeseries_${activeMetric.key}_${moment.utc().format("YYYY-MM-DDTHH:mm:ss")}`,
                    });
                    break;
                  case "download_data_xlsx":
                    exportTableRef.current.api.exportDataAsExcel({
                      fileName: `timeseries_${activeMetric.key}_${moment.utc().format("YYYY-MM-DDTHH:mm:ss")}`,
                    });
                    break;
                }
              }}
            >
              Export
            </ButtonDropdown>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChartOptions;