import { useMemo } from "react";
import { Link, Grid } from "@cloudscape-design/components";
import { convertData, numberShortFormatter } from "../../../utils";
import { DEFAULT_DARK_STROKES } from "../../../constants";
import { useThemeContext } from "../../../../../app/ThemeProvider";
import { Mode } from "@cloudscape-design/global-styles";
import { useCompareContext } from "../../../hooks/useCompareContext";

const lerp = (a, b, t) => {
  return a + (b - a) * t;
}

const hexToRgb = (hex) => {
  const bigint = parseInt(hex.replace("#", ""), 16);
  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255,
  };
};

const rgbToHex = (rgb) => {
  return `#${((1 << 24) + (Math.floor(rgb.r) << 16) + (Math.floor(rgb.g) << 8) + Math.floor(rgb.b)).toString(16).slice(1)}`;
}

const interpolateColor = (color1, color2, t) => {
  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  const r = lerp(rgb1.r, rgb2.r, t);
  const g = lerp(rgb1.g, rgb2.g, t);
  const b = lerp(rgb1.b, rgb2.b, t);

  return rgbToHex({ r, g, b });
};

export const useGridConfig = ({
  metricData,
  heatmapEnabled,
}) => {

  const { mode } = useThemeContext();
  const { titles, selectedMetrics, multiParamInfo } = useCompareContext();

  const tableRowData = useMemo(() => {
    const convertedTitles = convertData(titles);
    const titleMap = convertedTitles.map(title => ({ [title.value]: title })).reduce((acc, obj) => ({ ...acc, ...obj }), {});
    const titleObjects = Object.values(titleMap);

    let i = 0;
    const titleColorMap = multiParamInfo.map((paramInfo, paramIndex) => (
      titleObjects.filter(title => paramInfo.title_ids.includes(title.value)).map((title) => (
        { [`${title.value}_${paramIndex}`]: DEFAULT_DARK_STROKES[i++ % DEFAULT_DARK_STROKES.length] }
      )).reduce((acc, obj) => ({ ...acc, ...obj }), {})
    )).reduce((acc, obj) => ({ ...acc, ...obj }), {});

    return multiParamInfo.map((paramInfo, paramIndex) => {
      return [...new Set(convertedTitles.map(t => t.value))].filter(id => paramInfo.title_ids.includes(id)).map((ip_id) => {
        const title = convertedTitles.find(t => t.value === ip_id);
        return {
          title: title.label,
          ip_id: title.value,
          image_url: title.iconUrl,
          image_alt: title.iconAlt,
          color: titleColorMap[`${title.value}_${paramIndex}`],
          tags: title.tags,
          ...selectedMetrics.reduce((acc, metric) => {
            const sumValues = metricData.map(d => d[metric.key]?.sum ?? 0).filter(v => v != null);
            const maxMetricTotal = sumValues.reduce((a, b) => Math.max(a, b), 0);
            const minMetricTotal = sumValues.reduce((a, b) => Math.min(a, b), Infinity);
            const metricDataForTitle = metricData.find(
              (d) => d.ip_id === title.value && d.param_index === paramIndex,
            );
            const sumForTitle = metricDataForTitle?.[metric.key]?.sum;
            const heat = sumForTitle ? (sumForTitle - minMetricTotal) / (maxMetricTotal - minMetricTotal) : 0;
            return {
              ...acc,
              [metric.key]: { ...metricDataForTitle?.[metric.key], heat } ?? null,
            };
          }, {}),
        };
      });
    }).flat();
  }, [titles, selectedMetrics, metricData, multiParamInfo]);

  const gridOptions = useMemo(() => {
    return {
      rowData: tableRowData,
      columnDefs: [
        {
          field: "title",
          headerName: "Title",
          pinned: "left",
          headerCheckboxSelection: false,
          checkboxSelection: false,
          showDisabledCheckboxes: false,
          minWidth: 300,
          flex: 1,
          suppressHeaderMenuButton: true,
          cellRenderer: (params) => (
            <div className="flex space-x-2 items-center leading-none h-full">
              <div style={{ backgroundColor: params.data.color, width: "15px", height: "4px", minWidth: "15px", borderRadius: "2px" }}></div>
              <img
                src={params.data.image_url}
                alt={params.data.image_alt}
                className="w-8 h-8 min-w-8 object-cover rounded-md"
              />
              <div className="flex flex-col justify-between h-full py-0.5">
                <div className="p-0">
                  <Link href={`/item/${params.data.ip_id}`}>
                    {params.value}
                  </Link>
                </div>
                <div className="text-xs dark:text-slate-400 text-slate-600">
                  {params.data.tags.join(" \u2022 ")}
                </div>
              </div>
            </div>
          ),
        },
        ...selectedMetrics.map((metric) => ({
          field: metric.key,
          headerName: metric.name,
          width: 175,
          suppressHeaderMenuButton: true,
          sortable: false,
          cellStyle: (params) => {
            if (heatmapEnabled) {
              return {
                backgroundColor: params.data?.[metric.key]?.heat != null ? interpolateColor(
                  mode === Mode.Dark ? "#224270" : "#dce9fc", 
                  mode === Mode.Dark ? "#090d1c" : "#98b1d4", 
                  params.data[metric.key].heat
                ) : "transparent",
              };
            } else {
              return {};
            }
          },
          cellRenderer: (params) => (
            <div className="w-full leading-tight items-center justify-items-end">
              <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]} className="w-full">
                {!metric.normalize ? (
                  <div className="flex flex-col items-end">
                    <div className="text-xs dark:text-slate-400">Total</div>
                    <div
                      className="text-s"
                      title={
                        params.data?.[metric.key]?.sum
                          ? params.data?.[metric.key]?.sum.toLocaleString(undefined, {
                              maximumFractionDigits:
                                metric.decimalDigits ?? 2,
                            }) + (metric.normalize ? "" : "")
                          : "No value"
                      }
                    >
                      {params.data?.[metric.key]?.sum
                        ? numberShortFormatter(params.data?.[metric.key]?.sum) +
                          (metric.normalize ? "" : "")
                        : "-"}
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="flex flex-col items-end">
                  <div className="text-xs dark:text-slate-400">Average</div>
                  <div
                    className="text-s"
                    title={
                      params.data?.[metric.key]?.average
                        ? params.data?.[metric.key]?.average.toLocaleString(undefined, {
                            maximumFractionDigits:
                              metric.decimalDigits ?? 2,
                          }) + (metric.normalize ? "" : "")
                        : "No value"
                    }
                  >
                    {params.data?.[metric.key]?.average
                      ? (metric.normalize
                          ? params.data?.[metric.key]?.average.toLocaleString(undefined, {
                              maximumFractionDigits:
                                metric.decimalDigits ?? 2,
                            })
                          : numberShortFormatter(params.data?.[metric.key]?.average)) +
                        (metric.normalize ? "" : "")
                      : "-"}
                  </div>
                </div>
                {/*<div className="flex flex-col items-end">
                    <div className="text-xs text-slate-400">Peak</div>
                    <div className="text-s">{params.value?.max ? (params.value.max.toLocaleString(undefined, { maximumFractionDigits: metric.decimalDigits ?? 2 }) + (metric.normalize ? "%" : "")) : "-"}</div>
                  </div>*/}
              </Grid>
            </div>
          ),
        })),
        ...selectedMetrics
          .map((metric) => [
            {
              field: `${metric.key}_average`,
              headerName: `${metric.name} (Average)`,
              width: 150,
              hide: true,
              valueGetter: (params) => params.data[metric.key]?.average,
            },
            {
              field: `${metric.key}_max`,
              headerName: `${metric.name} (Peak)`,
              width: 150,
              hide: true,
              valueGetter: (params) => params.data[metric.key]?.max,
            },
            {
              field: `${metric.key}_sum`,
              headerName: `${metric.name} (Total)`,
              width: 150,
              hide: true,
              valueGetter: (params) => params.data[metric.key]?.sum,
            },
          ])
          .flat(),
      ],
      domLayout: tableRowData.length <= 10 ? "autoHeight" : "normal",
      suppressRowClickSelection: true,
    };
  }, [tableRowData, selectedMetrics, heatmapEnabled]);

  return {
    gridOptions,
  };
};