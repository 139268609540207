import { useEffect, useMemo, useState } from "react";
import { METRIC_GROUPS } from "../../../../components/datapoint-comparison/constants";
import { PLATFORMS, DATAPOINTS, TYPES, SERVICES } from "../../../../config-global";
import { DEFAULT_WEIGHTS_OBJECTS } from "../../../ranking/global-ranking/constants";
import { CATEGORIES } from "../../../platforms/google/constants";
import { Vertical } from "../../../../types/verticalTypes";
import { WeightObject } from "../../../../types/weightTypes";
import { Metric } from "../../../../components/datapoint-comparison/types";
import { ApiURLParams } from "../../../../services/generic_v2/requests";
import { useGetGenericPreference_V2 } from "../../../../services/generic_v2/hooks/useGetGenericPreference_V2";

const getWeight = (weightsObject: WeightObject, platform): number => {
  if (!weightsObject) return 0.0;
  const platformWeight = weightsObject.weights.find(weight => weight.field === platform.key);
  if (platformWeight?.enabled === false) return 0.0;
  return platformWeight?.weight ?? 0.0;
};

const defaultApiParams = { type: TYPES.PERSONAL, service: SERVICES.TOOLS, module: "compare" };

interface UseMetricsProps {
  weightsObjects?: Record<string, WeightObject>;
  prefsApiParams?: ApiURLParams;
}

interface UseMetricsReturn {
  metrics: Array<Metric>;
}

export const useMetrics = ({
  weightsObjects = DEFAULT_WEIGHTS_OBJECTS,
  prefsApiParams = defaultApiParams,
}: UseMetricsProps): UseMetricsReturn => {

  const { data: preferenceData } = useGetGenericPreference_V2({ apiParams: prefsApiParams });

  const [customMetrics, setCustomMetrics] = useState<Array<Metric>>([]);

  useEffect(() => {
    const pref = preferenceData?.[0]?.data;

    if (pref) {
      if (pref.customMetrics) {
        setCustomMetrics(pref.customMetrics);
      }
    }
  }, [preferenceData]);

  const zScoreMetrics = useMemo<Array<Metric>>(() => {
    return [
      {
        name: "Weighted Score",
        key: "weighted_score",
        group: METRIC_GROUPS.ZSCORE,
        normalize: false,
        hideInSummary: true,
        decimalDigits: 0,
        datapoints: [
          { 
            _platform: PLATFORMS.WIKIPEDIA,
            platform: PLATFORMS.PANTHEON_GLOBAL, 
            datapoint: DATAPOINTS.WIKIPEDIA.ZSCORE, 
            weight: [
              { idPrefix: "film", weight: getWeight(weightsObjects[Vertical.Movies], PLATFORMS.WIKIPEDIA) },
              { idPrefix: "series", weight: getWeight(weightsObjects[Vertical.Series], PLATFORMS.WIKIPEDIA) },
              { idPrefix: "game", weight: getWeight(weightsObjects[Vertical.Games], PLATFORMS.WIKIPEDIA) },
            ],
          },
          { 
            _platform: PLATFORMS.YOUTUBE,
            platform: PLATFORMS.PANTHEON_GLOBAL, 
            datapoint: DATAPOINTS.YOUTUBE.ZSCORE, 
            weight: [
              { idPrefix: "film", weight: getWeight(weightsObjects[Vertical.Movies], PLATFORMS.YOUTUBE) },
              { idPrefix: "series", weight: getWeight(weightsObjects[Vertical.Series], PLATFORMS.YOUTUBE) },
              { idPrefix: "game", weight: getWeight(weightsObjects[Vertical.Games], PLATFORMS.YOUTUBE) },
            ],
          },
          { 
            _platform: PLATFORMS.IMDB,
            platform: PLATFORMS.PANTHEON_GLOBAL, 
            datapoint: DATAPOINTS.IMDB.ZSCORE, 
            weight: [
              { idPrefix: "film", weight: getWeight(weightsObjects[Vertical.Movies], PLATFORMS.IMDB) },
              { idPrefix: "series", weight: getWeight(weightsObjects[Vertical.Series], PLATFORMS.IMDB) },
              { idPrefix: "game", weight: 0 },
            ],
          },
          { 
            _platform: PLATFORMS.PIRACY,
            platform: PLATFORMS.PANTHEON_GLOBAL, 
            datapoint: DATAPOINTS.PIRACY.ZSCORE, 
            weight: [
              { idPrefix: "film", weight: getWeight(weightsObjects[Vertical.Movies], PLATFORMS.PIRACY) },
              { idPrefix: "series", weight: getWeight(weightsObjects[Vertical.Series], PLATFORMS.PIRACY) },
              { idPrefix: "game", weight: 0 },
            ],
          },
          { 
            _platform: PLATFORMS.ROTTEN_TOMATOES,
            platform: PLATFORMS.PANTHEON_GLOBAL, 
            datapoint: DATAPOINTS.ROTTEN_TOMATOES.ZSCORE, 
            weight: [
              { idPrefix: "film", weight: getWeight(weightsObjects[Vertical.Movies], PLATFORMS.ROTTEN_TOMATOES) },
              { idPrefix: "series", weight: getWeight(weightsObjects[Vertical.Series], PLATFORMS.ROTTEN_TOMATOES) },
              { idPrefix: "game", weight: 0 },
            ],
          },
          { 
            _platform: PLATFORMS.STEAM,
            platform: PLATFORMS.PANTHEON_GLOBAL, 
            datapoint: DATAPOINTS.STEAM.ZSCORE, 
            weight: [
              { idPrefix: "film", weight: 0 },
              { idPrefix: "series", weight: 0 },
              { idPrefix: "game", weight: getWeight(weightsObjects[Vertical.Games], PLATFORMS.STEAM) },
            ],
          },
          { 
            _platform: PLATFORMS.TWITCH,
            platform: PLATFORMS.PANTHEON_GLOBAL, 
            datapoint: DATAPOINTS.TWITCH.ZSCORE, 
            weight: [
              { idPrefix: "film", weight: 0 },
              { idPrefix: "series", weight: 0 },
              { idPrefix: "game", weight: getWeight(weightsObjects[Vertical.Games], PLATFORMS.TWITCH) },
            ],
          },
        ],
      },
      {
        name: "Wikipedia Z-Score",
        key: "global_wiki_score",
        group: METRIC_GROUPS.ZSCORE,
        normalize: false,
        decimalDigits: 0,
        datapoints: [
          { 
            platform: PLATFORMS.PANTHEON_GLOBAL, 
            datapoint: DATAPOINTS.WIKIPEDIA.ZSCORE, 
            weight: 1.0,
          },
        ],
      },
      {
        name: "IMDb Z-Score",
        key: "global_imdb_score",
        group: METRIC_GROUPS.ZSCORE,
        normalize: false,
        decimalDigits: 0,
        datapoints: [
          { 
            platform: PLATFORMS.PANTHEON_GLOBAL, 
            datapoint: DATAPOINTS.IMDB.ZSCORE, 
            weight: [
              { idPrefix: "film", weight: 1.0 },
              { idPrefix: "series", weight: 1.0 },
              { idPrefix: "game", weight: 0 },
            ],
          },
        ],
      },
      {
        name: "Rotten Tomatoes Z-Score",
        key: "global_rt_score",
        group: METRIC_GROUPS.ZSCORE,
        normalize: false,
        decimalDigits: 0,
        datapoints: [
          { 
            platform: PLATFORMS.PANTHEON_GLOBAL, 
            datapoint: DATAPOINTS.ROTTEN_TOMATOES.ZSCORE, 
            weight: [
              { idPrefix: "film", weight: 1.0 },
              { idPrefix: "series", weight: 1.0 },
              { idPrefix: "game", weight: 0 },
            ],
          },
        ],
      },
      {
        name: "Piracy Z-Score",
        key: "global_piracy_score",
        group: METRIC_GROUPS.ZSCORE,
        normalize: false,
        decimalDigits: 0,
        datapoints: [
          { 
            platform: PLATFORMS.PANTHEON_GLOBAL, 
            datapoint: DATAPOINTS.PIRACY.ZSCORE, 
            weight: [
              { idPrefix: "film", weight: 1.0 },
              { idPrefix: "series", weight: 1.0 },
              { idPrefix: "game", weight: 0 },
            ],
          },
        ],
      },
      {
        name: "YouTube Z-Score",
        key: "global_yt_score",
        group: METRIC_GROUPS.ZSCORE,
        normalize: false,
        decimalDigits: 0,
        datapoints: [
          { 
            platform: PLATFORMS.PANTHEON_GLOBAL, 
            datapoint: DATAPOINTS.YOUTUBE.ZSCORE, 
            weight: [
              { idPrefix: "film", weight: 1.0 },
              { idPrefix: "series", weight: 1.0 },
              { idPrefix: "game", weight: 1.0 },
            ],
          },
        ],
      },
      {
        name: "Steam Z-Score",
        key: "global_steam_score",
        group: METRIC_GROUPS.ZSCORE,
        normalize: false,
        decimalDigits: 0,
        datapoints: [
          { 
            platform: PLATFORMS.PANTHEON_GLOBAL, 
            datapoint: DATAPOINTS.STEAM.ZSCORE, 
            weight: [
              { idPrefix: "film", weight: 0 },
              { idPrefix: "series", weight: 0 },
              { idPrefix: "game", weight: 1.0 },
            ],
          },
        ],
      },
      {
        name: "Twitch Z-Score",
        key: "global_twitch_score",
        group: METRIC_GROUPS.ZSCORE,
        normalize: false,
        decimalDigits: 0,
        datapoints: [
          { 
            platform: PLATFORMS.PANTHEON_GLOBAL, 
            datapoint: DATAPOINTS.TWITCH.ZSCORE, 
            weight: [
              { idPrefix: "film", weight: 0 },
              { idPrefix: "series", weight: 0 },
              { idPrefix: "game", weight: 1.0 },
            ],
          },
        ],
      },
    ];
  }, [weightsObjects]);

  const platformMetrics: Array<Metric> = [
    {
      name: "Wikipedia Page Views",
      key: "global_wiki_page_views",
      unit: "views",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.GLOBAL_WIKIPEDIA, 
          datapoint: DATAPOINTS.WIKIPEDIA.PAGE_VIEWS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search Trends",
      key: "gt_ww",
      group: METRIC_GROUPS.PLATFORM,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_TRENDS, 
          datapoint: DATAPOINTS.GOOGLE_TRENDS.WORLDWIDE, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Twitter Tweet Volume",
      key: "tweets",
      unit: "tweets",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.TWITTER, 
          datapoint: DATAPOINTS.TWITTER.TWEET_COUNT, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Reddit Upvotes",
      key: "reddit_upvotes",
      unit: "upvotes",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.REDDIT, 
          datapoint: DATAPOINTS.REDDIT.UPVOTES, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Reddit Comments",
      key: "reddit_comments",
      unit: "comments",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.REDDIT, 
          datapoint: DATAPOINTS.REDDIT.COMMENTS,
          weight: 1.0
        },
      ],
    },
    {
      name: "Reddit Members",
      key: "reddit_members",
      unit: "members",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.REDDIT,
          datapoint: DATAPOINTS.REDDIT.SUBREDDIT_MEMBERS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "YouTube Views",
      key: "global_yt_views",
      unit: "views",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.GLOBAL_YOUTUBE, 
          datapoint: DATAPOINTS.YOUTUBE.VIEWS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "YouTube Comments",
      key: "global_yt_comments",
      unit: "comments",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.GLOBAL_YOUTUBE,
          datapoint: DATAPOINTS.YOUTUBE.COMMENTS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "YouTube Likes",
      key: "global_yt_likes",
      unit: "likes",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.GLOBAL_YOUTUBE, 
          datapoint: DATAPOINTS.YOUTUBE.LIKES, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Wattpad Views",
      key: "wp_views",
      unit: "views",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.WATTPAD, 
          datapoint: DATAPOINTS.WATTPAD.VIEWS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Wattpad Comments",
      key: "wp_comments",
      unit: "comments",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.WATTPAD, 
          datapoint: DATAPOINTS.WATTPAD.COMMENTS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Wattpad Stars",
      key: "wp_stars",
      unit: "stars",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.WATTPAD, 
          datapoint: DATAPOINTS.WATTPAD.STARS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "AO3 Hits",
      key: "ao3_hits",
      unit: "hits",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.AO3, 
          datapoint: DATAPOINTS.AO3.HITS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "AO3 Comments",
      key: "ao3_comments",
      unit: "comments",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.AO3, 
          datapoint: DATAPOINTS.AO3.COMMENTS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "AO3 Kudos",
      key: "ao3_kudos",
      unit: "kudos",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.AO3, 
          datapoint: DATAPOINTS.AO3.KUDOS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Torrent Downloads",
      key: "piracy_downloads",
      unit: "downloads",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.PIRACY, 
          datapoint: DATAPOINTS.PIRACY.DOWNLOADS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Steam Players",
      key: "global_steam_ccu",
      unit: "players",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.GLOBAL_STEAM, 
          datapoint: DATAPOINTS.STEAM.PLAYER_COUNT, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Twitch Viewers",
      key: "global_twitch_viewers",
      unit: "viewers",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.GLOBAL_TWITCH, 
          datapoint: DATAPOINTS.TWITCH.VIEWER_COUNT, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Twitch Streamers",
      key: "global_twitch_streamers",
      unit: "streamers",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.GLOBAL_TWITCH, 
          datapoint: DATAPOINTS.TWITCH.STREAMER_COUNT, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "IMDb Votes",
      key: "global_imdb_votes",
      unit: "votes",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.GLOBAL_IMDB, 
          datapoint: DATAPOINTS.IMDB.VOTES, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "IMDb Rating",
      key: "global_imdb_rating",
      unit: "rating",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.GLOBAL_IMDB, 
          datapoint: DATAPOINTS.IMDB.RATING, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Box Office Mojo Domestic Gross",
      key: "boxoffice_domestic_gross",
      unit: "USD",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.BOX_OFFICE, 
          datapoint: DATAPOINTS.BOX_OFFICE.GROSS_DOMESTIC, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Rotten Tomatoes Audience Votes",
      key: "global_rt_audience_votes",
      unit: "votes",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.GLOBAL_ROTTEN_TOMATOES, 
          datapoint: DATAPOINTS.ROTTEN_TOMATOES.AUDIENCE_VOTES, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Rotten Tomatoes Audience Rating",
      key: "global_rt_audience_rating",
      unit: "rating",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.GLOBAL_ROTTEN_TOMATOES, 
          datapoint: DATAPOINTS.ROTTEN_TOMATOES.AUDIENCE_RATING, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Rotten Tomatoes Critic Votes",
      key: "global_rt_critic_votes",
      unit: "votes",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.GLOBAL_ROTTEN_TOMATOES, 
          datapoint: DATAPOINTS.ROTTEN_TOMATOES.CRITIC_VOTES, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Rotten Tomatoes Critic Rating",
      key: "global_rt_critic_rating",
      unit: "rating",
      group: METRIC_GROUPS.PLATFORM,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.GLOBAL_ROTTEN_TOMATOES, 
          datapoint: DATAPOINTS.ROTTEN_TOMATOES.CRITIC_RATING, 
          weight: 1.0 
        },
      ],
    },
  ];

  const googleMetrics: Array<Metric> = [
    {
      name: "Google Search (Title & Category)",
      key: `google_${CATEGORIES.GENERAL.CATEGORY.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_GENERAL_CATEGORY, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Title & Release Year)",
      key: `google_${CATEGORIES.GENERAL.RELEASE_YEAR.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_GENERAL_RELEASE_YEAR, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Intent to Watch - Where to Watch)",
      key: `google_${CATEGORIES.INTENT_TO_WATCH.WHERE_TO_WATCH.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_WATCH_WHERE_TO_WATCH, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Intent to Watch - Netflix)",
      key: `google_${CATEGORIES.INTENT_TO_WATCH.OTT.NETFLIX.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_WATCH_NETFLIX, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Intent to Watch - Disney+)",
      key: `google_${CATEGORIES.INTENT_TO_WATCH.OTT.DISNEY_PLUS.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_WATCH_DISNEY_PLUS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Intent to Watch - HBO Max)",
      key: `google_${CATEGORIES.INTENT_TO_WATCH.OTT.HBO_MAX.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_WATCH_HBO_MAX, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Intent to Watch - Hulu)",
      key: `google_${CATEGORIES.INTENT_TO_WATCH.OTT.HULU.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_WATCH_HULU, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Intent to Watch - Amazon Prime Video)",
      key: `google_${CATEGORIES.INTENT_TO_WATCH.OTT.PRIME_VIDEO.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_WATCH_AMAZON_PRIME_VIDEO, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Intent to Watch - Apple TV+)",
      key: `google_${CATEGORIES.INTENT_TO_WATCH.OTT.APPLE_TV_PLUS.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_WATCH_APPLE_TV_PLUS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Intent to Watch - Peacock)",
      key: `google_${CATEGORIES.INTENT_TO_WATCH.OTT.PEACOCK.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_WATCH_PEACOCK, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Intent to Watch - Paramount+)",
      key: `google_${CATEGORIES.INTENT_TO_WATCH.OTT.PARAMOUNT_PLUS.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_WATCH_PARAMOUNT_PLUS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Intent to Watch - Cable)",
      key: `google_${CATEGORIES.INTENT_TO_WATCH.CABLE.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_WATCH_CABLE, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Intent to Watch - Physical)",
      key: `google_${CATEGORIES.INTENT_TO_WATCH.PHYSICAL.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_WATCH_PHYSICAL, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Intent to Watch - Theatrical)",
      key: `google_${CATEGORIES.INTENT_TO_WATCH.THEATRICAL.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_WATCH_THEATRICAL, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Intent to Watch - Piracy)",
      key: `google_${CATEGORIES.INTENT_TO_WATCH.PIRACY.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_WATCH_PIRACY, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Intent to Watch - Latest Season)",
      key: `google_${CATEGORIES.INTENT_TO_WATCH.LATEST_SEASON.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_WATCH_LATEST_SEASON, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Intent to Play - Where to Play)",
      key: `google_${CATEGORIES.INTENT_TO_PLAY.WHERE_TO_PLAY.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_PLAY_WHERE_TO_PLAY, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Intent to Play - Steam)",
      key: `google_${CATEGORIES.INTENT_TO_PLAY.DIGITAL.STEAM.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_PLAY_STEAM, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Intent to Play - Epic Games Store)",
      key: `google_${CATEGORIES.INTENT_TO_PLAY.DIGITAL.EPIC_GAMES.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_PLAY_EPIC_GAMES, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Intent to Play - GOG)",
      key: `google_${CATEGORIES.INTENT_TO_PLAY.DIGITAL.GOG.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_PLAY_GOG, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Intent to Play - PlayStation)",
      key: `google_${CATEGORIES.INTENT_TO_PLAY.DIGITAL.PLAYSTATION.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_PLAY_PLAYSTATION, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Intent to Play - Xbox)",
      key: `google_${CATEGORIES.INTENT_TO_PLAY.DIGITAL.XBOX.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_PLAY_XBOX, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Intent to Play - Console)",
      key: `google_${CATEGORIES.INTENT_TO_PLAY.PHYSICAL.CONSOLE.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_PLAY_CONSOLE, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Intent to Play - PC)",
      key: `google_${CATEGORIES.INTENT_TO_PLAY.PHYSICAL.PC.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_PLAY_PC, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Learn About - General)",
      key: `google_${CATEGORIES.LEARN_ABOUT.GENERAL.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_LEARN_ABOUT_GENERAL, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Learn About - News)",
      key: `google_${CATEGORIES.LEARN_ABOUT.NEWS.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_LEARN_ABOUT_NEWS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Learn About - Social)",
      key: `google_${CATEGORIES.LEARN_ABOUT.SOCIAL_MEDIA.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_LEARN_ABOUT_SOCIAL, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Learn About - Awards)",
      key: `google_${CATEGORIES.LEARN_ABOUT.AWARDS.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_LEARN_ABOUT_AWARDS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Learn About - Reviews)",
      key: `google_${CATEGORIES.REVIEWS.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_LEARN_ABOUT_REVIEWS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Search (Learn About - Trailers)",
      key: `google_${CATEGORIES.LEARN_ABOUT.TRAILERS.value}`,
      group: METRIC_GROUPS.GOOGLE,
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_SEARCH, 
          datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_LEARN_ABOUT_TRAILERS, 
          weight: 1.0 
        },
      ],
    },
  ];

  return {
    metrics: [
      ...customMetrics, 
      ...zScoreMetrics, 
      ...platformMetrics, 
      ...googleMetrics
    ],
  } as UseMetricsReturn;
};