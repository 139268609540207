import { Icon, Link, Popover } from "@cloudscape-design/components";
import React from "react";
import { VerticalIcon } from "../../../../../../components/VerticalIcon";
import { Vertical } from "../../../../../../types/verticalTypes";

export const columnDefinitions = [
  {
    id: "name",
    header: "Title & Summary",
    width: 450,
    minWidth: 300,
    cell: (e) => {
      const summary = e.summary || "N/A";
      const typeMapping = {
        "Video Game": Vertical.Games,
        "Television Series": Vertical.Series,
        Film: Vertical.Movies,
      };

      const firstDate = new Date(e.first_date);
      const lastDate = new Date(e.last_date);

      // Calculate difference in years and months
      const diffYears = lastDate.getFullYear() - firstDate.getFullYear();
      const diffMonths =
        (lastDate.getFullYear() - firstDate.getFullYear()) * 12 +
        (lastDate.getMonth() - firstDate.getMonth());

      let timeDisplay;
      if (diffMonths < 12) {
        timeDisplay = `${diffMonths} ${diffMonths > 1 ? "months" : "month"}`;
      } else {
        timeDisplay = `${diffYears} ${diffYears > 1 ? "years" : "year"}`;
      }

      return (
        <div className="flex items-center gap-4">
          <img
            src={`https://images.searchpantheon.com/collections/${e.ip_id}_684.jpg`}
            alt={e.name}
            className="rounded-lg w-20 h-20  object-cover"
            loading="lazy"
          />
          <div className="flex flex-col gap-1">
            <div className=" flex  gap-2 align-top items-center">
              <Link
                href={`${window.location.origin}/item/${e.ip_id}`}
                fontSize="heading-m"
                variant="secondary"
              >
                <span className=" font-bold">{e.name}</span>
              </Link>
              <Popover
                dismissButton={false}
                size="large"
                triggerType="custom"
                content={<div>{summary}</div>}
              >
                <Icon variant="link" name="status-info" className="hover:cursor-pointer" />
              </Popover>
            </div>

            <div className="text-xs text-slate-400 flex gap-1">
              {[
                <span>{firstDate.getFullYear()} - {lastDate.getFullYear()}</span>,
                <span>{timeDisplay}</span>,
                ...[...e.content_types].sort((a, b) => a.localeCompare(b)).map((type, index) => (
                  <VerticalIcon
                    key={index}
                    vertical={typeMapping[type]}
                    className="w-4 h-4"
                  />
                )),
              ].map((element, index) => [
                index > 0 && " \u2022 ",
                <span key={index}>{element}</span>,
              ])}
            </div>
          </div>
        </div>
      );
    },
    sortingField: "name",
  },
  {
    id: "total_ips",
    header: "Total",
    minWidth: 100,
    maxWidth: 100,
    cell: (e) => {
      return <span className="font-bold text-base">{e.total_ips}</span>;
    },
    sortingField: "total_ips",
  },
  {
    id: "film_count",
    header: "Movies",
    minWidth: 100,
    maxWidth: 100,
    cell: (e) => {
      return <span className="text-base">{e.film_count}</span>;
    },
    sortingField: "film_count",
  },
  {
    id: "series_count",
    header: "Series",
    minWidth: 100,
    maxWidth: 100,
    cell: (e) => {
      return <span className="text-base">{e.series_count}</span>;
    },
    sortingField: "series_count",
  },
  {
    id: "game_count",
    header: "Games",
    minWidth: 100,
    maxWidth: 100,
    cell: (e) => {
      return <span className="text-base">{e.game_count}</span>;
    },
    sortingField: "game_count",
  },
];
