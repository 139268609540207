import { useQuery, UseQueryResult } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getTitleSearchData } from "../request";

interface UseGetTitleSearchDataProps {
  queryString: string;
}

export const useGetTitleSearchData = ({
  queryString="",
}: UseGetTitleSearchDataProps) => {

  const params = {
    queryString,
  };

  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_TITLE_SEARCH_DATA, { ...params }],
    async () => {
      if (!queryString || queryString.length === 0) {
        return await Promise.resolve({ data: [], isLoading: false, error: null });
      }
      return await getTitleSearchData(params);
    },
  );

  return {
    data: (data as any)?.data,
    isLoading,
    error,
  };
};
