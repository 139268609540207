import { Container, TextFilter } from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import DateRangeSelector from "../../raw-data/header/DateRangeSelector";

export const BasicParameters = ({
  dateRange,
  setDateRange,
  titleSearchQuery,
  setTitleSearchQuery,
  additionalFilters = null,
  showAdvancedFilter = true,
}) => {
  const [searchQuery, setSearchQuery] = useState(titleSearchQuery);

  useEffect(() => {
    setSearchQuery(titleSearchQuery);
  }, [titleSearchQuery]);

  return (
    <Container variant="borderless" disableContentPaddings>
      <div className="flex flex-col gap-y-4">
        <div className="flex items-center justify-between flex-wrap gap-y-4">
          <div className="flex space-x-2 items-center">
            <div className="flex space-x-2 items-center">
              <TextFilter
                filteringText={searchQuery}
                filteringPlaceholder="Search for a title"
                onChange={({ detail }) => {
                  setSearchQuery(detail.filteringText);
                  if (detail.value === "") {
                    setTitleSearchQuery("");
                  }
                }}
                onDelayedChange={({ detail }) => {
                  setTitleSearchQuery(detail.filteringText);
                }}
              />
            </div>
          </div>
          <div className="flex gap-x-2 items-center flex-wrap gap-y-4">
            {additionalFilters}
            <DateRangeSelector
              defaults={dateRange}
              onChange={setDateRange}
              isDateEnabled={(date) => date <= new Date()}
            />
          </div>
        </div>
        <div
          id="advancedFilterParent"
          className={`${showAdvancedFilter ? "" : "hidden"} [&:not(:has(div))]:hidden`}
        ></div>
      </div>
    </Container>
  );
};
