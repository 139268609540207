import React, { useContext, createContext } from "react";
import { Category } from "../constants";

type GoogleDashboardContextProps = {
  setSelectedTab: (tabId: string) => void;
  setSplitPanelStatus: (status: boolean) => void;
};

export const GoogleDashboardContext = createContext<GoogleDashboardContextProps>(null);

export const useGoogleDashboardContext = () => {
  return useContext(GoogleDashboardContext);
};