import React from "react";
import {
  NavItemsWithId,
  navItems,
} from "../../../../layouts/common/menu/side-menu";
import { Layout } from "../../Layout";
import { MediaHome } from "../media/components/MediaHome";

const breadcrumbs = [
  { text: "Explore", href: "/explore" },
  { text: "Games" },
];

export default function ExploreGames() {
  return (
    <Layout
      title="Explore Games"
      navItems={navItems as NavItemsWithId}
      breadcrumbs={breadcrumbs}
      contentType="table"
      content={
        <MediaHome
          vertical="games"
          header="Games"
          headerAddButtonText="Add game"
          modalTitle="Add Game"
          modalPlaceholder="Search for a game or enter an IGDB ID"
          modalAutoCompletePlatform="igdb"
        />
      }
    />
  );
}
