import React, { useCallback, useMemo } from "react";
import renderTrend from "../../../../../../utils/trendIcon";
import { Link } from "@cloudscape-design/components";
import { VerticalIcon } from "../../../../../../components/VerticalIcon";
import { getAdditionalColumnDefinition, numberColumnDefinition } from "../../../../global-ranking/pantheon-ranking/components/DataTable/gridUtils";
import { platforms } from "../../../constants";
import { CHART_LABELS } from "../../../../../platforms/hooks/useGridConfig";
import { chartMap } from "../../../../global-ranking/pantheon-ranking/components/DataTable/hooks/useGridConfig";
import moment from "moment";

interface UseGridConfigProps {
  enabledWeightColumns: Array<string>;
  additionalPlatformColumns?: Array<string>;
  selectedAdditionalColumns: Array<string>;
}

export const useGridConfig = ({
  enabledWeightColumns=[],
  additionalPlatformColumns,
  selectedAdditionalColumns=[],
}: UseGridConfigProps) => {

  const defaultColDef = {
    sortable: true,
    flex: 1,
    suppressHeaderMenuButton: true,
    suppressHeaderFilterButton: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    minWidth: 100,
    suppressMovable: true,
  };

  const columnDefs = useMemo(() => {
    const mainColumns = [
      {
        field: "rank",
        headerName: "Rank",
        pinned: "left",
        width: 100,
        minWidth: 100,
        flex: 1,
        cellRenderer: (params) => {
          if (params.data.rank_change != null) {
            const rankTrend = params.data.rank_change > 0 ? "up" : params.data.rank_change < 0 ? "down" : "same";
            const newParams = { ...params, data: { ...params.data, rank_trend: rankTrend } };
            return renderTrend(newParams, "rank", "rank_change", "rank_trend");
          }
          return params.value;
        },
      },
      {
        ...numberColumnDefinition("score", "Pantheon Score", false, 3),
        maxWidth: 100,
        pinned: "left",
        initialSort: "score",
      },
      {
        field: "vertical",
        headerName: "Category",
        hide: true,
      },
      {
        field: "release_date",
        headerName: "Release Date",
        hide: true,
        filterValueGetter: (params) => params.data.release_date,
      },
      {
        field: "ip_list",
        headerName: "IP list",
        hide: true,
        valueGetter: (params) => params.data.ip_list?.join(", "),
      },
      {
        field: "ip",
        headerName: "Title",
        minWidth: 250,
        filter: "agNumberColumnFilter",
        pinned: "left",
        cellRenderer: (params) => {
          if (!params.value) return null;
          const ipId = params.data.ip_id;
          const release_year = params.data.release_date ? new Date(Date.parse(params.data.release_date)).getFullYear() : "N/A";
          return (
            <div className="flex gap-2 mt-2.5 align-middle">
              <span className="text-slate-500 flex items-center">
                <VerticalIcon vertical={params.data.vertical} />
              </span>
              <Link href={`/item/${ipId}`}>
                <span>{params.data.ip}</span>
              </Link>
              <span className="text-xs text-slate-500 mt-0.5">
                {release_year}
              </span>
            </div>
          );
        },
      },
      ...platforms.map(platform => (
        {
          ...numberColumnDefinition(platform.key, platform.name, false, 3),
          hide: !enabledWeightColumns.includes(platform.key),
        }
      )),
    ];

    const additionalColumns = additionalPlatformColumns ? additionalPlatformColumns.filter(key => key !== CHART_LABELS.SCORE_CHANGE).map(key => {
      const { field, headerName, cellDataType } = chartMap[key];
      const hide = !selectedAdditionalColumns.find(o => o === key);
      return getAdditionalColumnDefinition(
        field,
        headerName,
        cellDataType,
        hide,
      );
    }) : [];

    return [
      ...mainColumns,
      ...additionalColumns,
    ];
  }, [platforms, enabledWeightColumns]);

  const onGridReady = useCallback((params) => {
    const advancedFilterElement = document.getElementById(
      "advancedFilterParent",
    );

    if (advancedFilterElement) {
      params.api.setGridOption("advancedFilterParent", advancedFilterElement);
      params.api.setGridOption("includeHiddenColumnsInAdvancedFilter", true);
    }
  }, []);

  return {
    columnDefs,
    onGridReady,
    defaultColDef,
  };
};