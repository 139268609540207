import { ContentLayout } from "@cloudscape-design/components";
import "ag-grid-enterprise";
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  NavItemsWithId,
  navItems,
} from "../../../layouts/common/menu/side-menu";
import { Layout } from "../Layout";
import { TalentComponent } from "./components/TalentComponent";

function TalentPage() {
  const { id } = useParams();
  const [talentName, setTalentName] = useState(id);
  const breadcrumbs = [
    { text: "Explore", href: "/explore" }, 
    { text: "Talent", href: `/explore/talent` }, 
    { text: talentName }
  ];
  
  return (
    <Layout
      title={talentName}
      breadcrumbs={breadcrumbs.map((item) => ({
        ...item,
        href: item.href || "#",
      }))}
      navItems={navItems as NavItemsWithId}
      contentType="table"
      content={
        <ContentLayout>
          <TalentComponent id={id} onNameLoad={setTalentName} />
        </ContentLayout>
      }
    />
  );
}

export default TalentPage;
