import {
  ContentLayout,
  Header,
  Popover,
  Tabs
} from "@cloudscape-design/components";
import "ag-grid-enterprise";
import startCase from "lodash/startCase";
import React, { useEffect, useState } from "react";
import {
  NavItemsWithId,
  navItems,
} from "../../../layouts/common/menu/side-menu";
import { addQueryParams, getQueryParams } from "../../../utils/queryUtils";
import CustomFlashBar from "../../common/CustomFlashBar";
import { DEFAULT_WEIGHTS_OBJECTS } from "../../ranking/global-ranking/constants";
import { PlatformTrendsCharts } from "../components/PlatformTrendsCharts";
import { Layout } from "../Layout";
import { getGlobalTrendsData } from "./api/request";
import {
  defaultCategory,
  globalApiParams,
  globalCategoryOptions,
} from "./constants";
import {
  additionalPlatformColumns,
  corePlatformColumns,
  defaultColumn,
} from "./utils/gridConfig";
import { Weight } from "../../../types/weightTypes";
import { Vertical } from "../../../types/verticalTypes";


const title = "Pantheon";
const tabs = ["trends"];

function GlobalTrendsChartsIndexPage() {
  const breadcrumbs = [{ text: "Platforms" }, { text: title }];
  const tabQuery = getQueryParams("tab");
  const isValidTab = tabs.some((str) => str.includes(tabQuery));
  const [activeTabId, setActiveTabId] = useState(
    isValidTab ? tabQuery : "trends",
  );

  if (!isValidTab) {
    addQueryParams({ tab: activeTabId });
  }
  const [selectedVertical, setSelectedVertical] = useState({
    label: startCase(defaultCategory),
    value: defaultCategory,
  });

  const [weights, setWeights] = useState([]);

  useEffect(() => {
    const category = globalCategoryOptions.find(
      ({ value }) => value === defaultCategory
    );
    setSelectedVertical(
      category || { label: startCase(defaultCategory), value: defaultCategory }
    );
  }, []);

  const getWeightsForSelectedVertical = (selectedVertical): Array<Weight> => {
    switch (selectedVertical.value) {
      case 'movies':
        return DEFAULT_WEIGHTS_OBJECTS[Vertical.Movies]?.weights || [];
      case 'series':
        return DEFAULT_WEIGHTS_OBJECTS[Vertical.Series]?.weights || [];
      case 'gaming':
        return DEFAULT_WEIGHTS_OBJECTS[Vertical.Games]?.weights || [];
      default:
        return [];
    }
  };

  useEffect(() => {
    const currentWeights = getWeightsForSelectedVertical(selectedVertical);

    const formattedWeights = currentWeights.map((weight) => ({
      label: weight.label,
      value: weight.weight,
    }));

    setWeights(formattedWeights);
  }, [selectedVertical]);


  const handleCategoryChange = (newCategory) => {
    setSelectedVertical(newCategory);
  };

  return (
    <Layout
      title={title}
      breadcrumbs={breadcrumbs}
      navItems={navItems as NavItemsWithId}
      contentType="table"
      content={
        <ContentLayout
          header={
            <>
              <Header
                description={
                  <span>
                    This module provides Trend information based on weighted platform scores.{' '}
                    <Popover
                      fixedWidth
                      header="Weights Used:"
                      size="small"
                      content={
                        <div>
                          {weights.map((weight, index) => (
                            <div
                              key={index}
                              className="flex p-2 mb-2 justify-between text-line font-bold bg-slate-200/50 dark:bg-slate-700/50 rounded-md ring-1 ring-inset ring-slate-300 dark:ring-slate-600 text-slate-900 dark:text-slate-200"
                            >
                              <span>{weight.label.name}</span>
                              <span>{weight.value}</span>
                            </div>
                          ))}
                        </div>
                      }
                    >
                      Weights Used
                    </Popover>
                  </span>
                }
                variant="h2"
              >
                {title}
              </Header>

              <CustomFlashBar />
            </>
          }
        >
          <Tabs
            onChange={({ detail }) => {
              setActiveTabId(detail.activeTabId);
              addQueryParams({ tab: detail.activeTabId });
            }}
            activeTabId={activeTabId}
            fitHeight
            tabs={[
              {
                label: "Trends",
                id: "trends",
                content: (
                  <PlatformTrendsCharts
                    apiParams={globalApiParams}
                    defaultColumn={defaultColumn}
                    defaultCategory={defaultCategory}
                    categoryOptions={globalCategoryOptions}
                    corePlatformColumns={corePlatformColumns}
                    fetchMethod={getGlobalTrendsData}
                    additionalPlatformColumns={additionalPlatformColumns}
                    selectedVertical={selectedVertical}
                    setSelectedVertical={handleCategoryChange}
                  />
                ),
              }
            ]}
          />
        </ContentLayout>
      }
    />
  );
}

export default GlobalTrendsChartsIndexPage;
