import React, { useEffect, useState } from "react";
import { FormField, Input } from "@cloudscape-design/components";
import { Filter } from "../../../types/filterTypes";

interface TextFilterProps {
  filterObject: Filter;
  setFilterObject: (filterObject: Filter) => void;
}

export const TextFilter: React.FC<TextFilterProps> = ({
  filterObject,
  setFilterObject,
}) => {

  const [text, setText] = useState<string>("");

  useEffect(() => {
    if (filterObject.value) {
      setText(filterObject.value[0]);
    } else {
      setText("");
    }
  }, [filterObject]);

  return (
    <FormField label={
      <div className="flex justify-between">
        <div>{filterObject.label}</div>
      </div>
    }>
      <Input
        value={text}
        onChange={({ detail }) => {
          setText(detail.value);
          const newFilterObject = {
            ...filterObject,
            value: [detail.value],
          };
          if (detail.value.length === 0) {
            delete newFilterObject.value;
          }
          setFilterObject(newFilterObject);
        }}
        placeholder="Enter text"
      />
    </FormField>
  );
};