import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle, useCallback, ReactElement } from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import { useGridConfig } from "./hooks/useGridConfig";
import { useGetGridTheme } from "../../../../../../hooks/UseTheme/useGetGridTheme";
import { useFetchServerSideGridData } from "../../api/hooks/useFetchServerSideGridData";
import { useSetGridAdvancedFilterTheme } from "../../../../../../hooks/UseTheme/useSetGridAdvancedFilterTheme";
import TableLegend from "../../../../../../components/table/TableLegend";
import _ from "lodash";
import { useGetPantheonRankingDataPromise } from "../../api/hooks/useGetPantheonRankingDataPromise";
import { Vertical } from "../../../../../../types/verticalTypes";

const verticalMap = {
  [Vertical.Movies]: "movies",
  [Vertical.Series]: "series",
  [Vertical.Games]: "gaming",
};

interface DataTableProps {
  vertical: Vertical;
  selectedIpIds?: string[];
  setSelectedIpIds?: (ipIds: string[]) => void;
  setSplitPanelStatus: (status: boolean) => void;
  filterQuery: any;
  weightageValues: any;
  dateRange: any;
  additionalColumns: any;
  selectedAdditionalColumns: any;
  showLegend?: boolean;
  autoHeight?: boolean;
  usePagination?: boolean;
  onRowCountSet?: (count: number) => void;
  sortField?: string;
  sortOrder?: string;
}

export const DataTable = forwardRef<ReactElement | null, DataTableProps>(({
  vertical,
  selectedIpIds=[],
  setSelectedIpIds=null,
  setSplitPanelStatus,
  filterQuery,
  weightageValues,
  dateRange,
  additionalColumns,
  selectedAdditionalColumns,
  showLegend=true,
  autoHeight=false,
  usePagination=true,
  onRowCountSet=null,
  sortField="score",
  sortOrder="desc",
}, ref) => {

  const { theme } = useGetGridTheme();
  const gridRef = useRef(null);

  const [paginationPageSize, setPaginationPageSize] = useState(usePagination ? 50 : 1000);
  const [disabled, setDisabled] = useState(true);
  const [innerSelectedIpIds, setInnerSelectedIpIds] = useState(selectedIpIds);
  const [rowCount, setRowCount] = useState(0);

  const gridOptions = useGridConfig({
    weightageValues,
    additionalPlatformColumns: additionalColumns,
    selectedOptions: selectedAdditionalColumns.map(o => o.value),
    sortField,
    sortOrder,
  });

  const { getPantheonRankingData } = useGetPantheonRankingDataPromise();

  useFetchServerSideGridData({
    filter: filterQuery,
    gridRef,
    vertical: verticalMap[vertical],
    fetchMethod: getPantheonRankingData,
    startTs: moment.utc(dateRange.startDate).unix(),
    endTs: moment.utc(dateRange.endDate).unix(),
    weightageValues,
    usePagination,
  } as any);
  useImperativeHandle(ref, () => ({ ...gridRef.current, setInnerSelectedIpIds }));

  useEffect(() => {
    if (!_.isEqual(selectedIpIds, innerSelectedIpIds)) {
      setInnerSelectedIpIds(selectedIpIds);
      gridRef.current?.api?.forEachNode(node => {
        if (selectedIpIds.includes(node?.data?.ip_id)) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      });
    }
  }, [selectedIpIds]);

  useEffect(() => {
    if (gridRef?.current?.api) {
      gridRef.current.api.applyColumnState({
        state: [{ colId: sortField, sort: sortOrder }],
        defaultState: { sort: null },
      });
    }
  }, [gridRef, sortField, sortOrder]);

  return (
    <div className="flex flex-col space-y-4">
      <div style={{ width: "100%", height: (autoHeight && rowCount <= 20) ? "fit-content" : "70vh" }} className={theme}>
        <AgGridReact
          ref={gridRef}
          enableAdvancedFilter={true}
          domLayout={(autoHeight && rowCount <= 20) ? "autoHeight" : "normal"}
          paginationPageSize={paginationPageSize}
          pagination={usePagination}
          paginationPageSizeSelector={[20, 50, 100]}
          onPaginationChanged={(params) => {
            setRowCount(params.api.getDisplayedRowCount());
            if (onRowCountSet) {
              if (params.api.getServerSideGroupLevelState()?.[0]?.lastRowIndexKnown) {
                onRowCountSet(params.api.getDisplayedRowCount());
              } else {
                onRowCountSet(-1);
              }
            }
            const size = params.api.paginationGetPageSize();
            if (size !== paginationPageSize && usePagination) setPaginationPageSize(size);
            params.api.forEachNode(node => {
              if (innerSelectedIpIds.includes(node?.data?.ip_id)) {
                node.setSelected(true);
              } else {
                node.setSelected(false);
              }
            });
          }}
          rowModelType={"serverSide"}
          blockLoadDebounceMillis={100}
          rowSelection="multiple"
          suppressRowClickSelection
          suppressCsvExport
          suppressExcelExport
          onRowSelected={(params) => {
            if (params.source === "api") {
              return;
            }
            const rowIpId = params.data.ip_id;
            setInnerSelectedIpIds(prev => {
              const selectedIpIdsCopy = [...prev];
              if (params.node.isSelected()) {
                if (selectedIpIdsCopy.includes(rowIpId)) {
                  return;
                }
                selectedIpIdsCopy.push(rowIpId);
                setSplitPanelStatus(true);
              } else {
                const index = selectedIpIdsCopy.indexOf(rowIpId);
                if (index === -1) {
                  return;
                }
                selectedIpIdsCopy.splice(index, 1);
              }
              if (setSelectedIpIds) {
                setSelectedIpIds(selectedIpIdsCopy);
              }
              return selectedIpIdsCopy;
            });
          }}
          {...gridOptions}
        />
      </div>
      {showLegend && <TableLegend />}
    </div>
  );
});