import React, { useEffect, useMemo, useState } from "react";
import { TileData } from "../../../../../components/title-tiles/types";
import TitleTiles from "../../../../../components/title-tiles/TitleTiles";
import { Spinner } from "@cloudscape-design/components";
import InfiniteScroll from "react-infinite-scroll-component";
import { FaGoogle, FaInstagram, FaReddit, FaTiktok, FaWikipediaW, FaXTwitter, FaYoutube, FaSteam, FaTwitch, FaImdb } from "react-icons/fa6";
import { TbBrandAo3 } from "react-icons/tb";
import { SiFandom, SiWattpad } from "react-icons/si";
import { platforms } from "../../constants";

const platformIconMap = {
  twitter: <FaXTwitter className="h-4 w-4" />,
  google: <FaGoogle className="h-4 w-4" />,
  wiki: <FaWikipediaW className="h-4 w-4" />,
  a03: <TbBrandAo3 className="h-4 w-4" />,
  youtube: <FaYoutube className="h-4 w-4" />,
  reddit: <FaReddit className="h-4 w-4" />,
  fandom: <SiFandom className="h-4 w-4" />,
  instagram: <FaInstagram className="h-4 w-4" />,
  tiktok: <FaTiktok className="h-4 w-4" />,
  steam: <FaSteam className="h-4 w-4" />,
  twitch: <FaTwitch className="h-4 w-4" />,
  wattpad: <SiWattpad className="h-4 w-4" />,
  imdb: <FaImdb className="h-4 w-4" />,
};

export const RankingTileView = ({
  data,
  isLoading=false,
  enabledWeightColumns=[],
}) => {

  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  const tileItemData: Array<TileData> = useMemo(() => {
    if (!data) return [];

    return data.map(item => (
      {
        ipId: item.ip_id,
        title: item.ip,
        vertical: item.vertical,
        releaseDate: item.release_date,
        imageUrl: item.image_url,
        rank: item.rank,
        rankChange: item.rank_change,
        content: (
          <div className="flex flex-col gap-2 w-full h-full overflow-y-hidden">
            <div className={`rounded-md px-2 py-1 text-sm font-semibold border-2 border-slate-600 text-slate-200 flex justify-center gap-1`}>
              Score: <span className="font-bold text-yellow-300">{parseFloat(item.score).toFixed(3)}</span>
            </div>
            <div className="flex flex-col gap-y-1 text-slate-200 flex-wrap w-full content-between grow max-h-[85%] overflow-hidden">
              {platforms.map(platform => (
                enabledWeightColumns.includes(platform.key) && item[platform.key] != null && item[platform.key] > 0 && (
                  <div className="text-xs flex items-center rounded-md w-fit">
                    <span className="p-1 rounded-l-md text-slate-400" style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>{platformIconMap[platform.key]}</span>
                    <span className="p-1 rounded-r-md font-bold text-slate-400 w-full" style={{ backgroundColor: "rgba(0,0,0,0.3)" }}>{`${parseFloat(item[platform.key]).toFixed(3)}`}</span>
                  </div>
                )
              ))}
            </div>
          </div>
        ),
      } as TileData
    ));
  }, [data]);

  const paginatedData = useMemo(() => {
    return tileItemData.slice(0, currentPageIndex * pageSize);
  }, [tileItemData, currentPageIndex, pageSize]);

  const fetchMoreData = () => {
    setCurrentPageIndex(currentPageIndex + 1);
  };

  useEffect(() => {
    setCurrentPageIndex(1);
  }, [data]);

  return (
    isLoading ? (
      <div className="w-full h-96 flex items-center justify-center">
        <Spinner size="large" />
      </div>
    ) : (
      <InfiniteScroll
        style={{ overflow: "100%" }}
        dataLength={paginatedData.length}
        next={fetchMoreData}
        hasMore={paginatedData.length < tileItemData.length}
        loader={
          <div className="flex justify-center my-10">
            <Spinner size="large" />
          </div>
        }
      >
        <TitleTiles
          variant="grid"
          itemData={paginatedData}
        />
      </InfiniteScroll>
    )
  );
};