import React, { useEffect, useMemo, useState } from "react";
import { FormField, Multiselect, Select, Toggle } from "@cloudscape-design/components";
import { useGetFilterOptionsData } from "../api/hooks/useGetFilterOptionsData";
import { Filter, FilterJoinOperation } from "../../../types/filterTypes";

interface SetFilterProps {
  filterObject: Filter;
  setFilterObject: (filterObject: Filter) => void;
  tokenLimit?: number;
}

export const SetFilter: React.FC<SetFilterProps> = ({
  filterObject,
  setFilterObject,
  tokenLimit=2,
}) => {

  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);

  const { data, isLoading } = useGetFilterOptionsData({
    vertical: filterObject.controlOptions.filterVertical,
    filterKey: filterObject.field,
    query: filterObject.controlOptions.autoComplete ? searchQuery : undefined,
    size: filterObject.controlOptions.autoComplete ? 50 : undefined,
    disabled: filterObject.controlOptions.setOptions ? true : disabled,
  });

  const options = useMemo(() => (
    [
      ...(filterObject.controlOptions.allowBlank ? [
        { label: "Is blank", value: "Is blank", disabled: selectedOptions.length > 0 && selectedOptions[0].value !== "Is blank" }, 
        { label: "Is not blank", value: "Is not blank", disabled: selectedOptions.length > 0 && selectedOptions[0].value !== "Is not blank" }
      ] : []), 
      ...(data?.data ? data.data.map(option => (
        { 
          label: option, 
          value: option.replaceAll(",", "\\,"), 
          disabled: selectedOptions.some(selectedOption => ["Is blank", "Is not blank"].includes(selectedOption.value)) 
        }
      )) : []),
      ...(filterObject.controlOptions.setOptions ? filterObject.controlOptions.setOptions.map(option => (
        { 
          label: option.label, 
          value: option.value.replaceAll(",", "\\,"), 
          disabled: selectedOptions.some(selectedOption => ["Is blank", "Is not blank"].includes(selectedOption.value)) 
        }
      )) : [])
    ]
  ), [data, selectedOptions]);

  useEffect(() => {
    if (filterObject.value) {
      setSelectedOptions(filterObject.value.map(value => value.replaceAll("\\,", "\\|").split(",")).flat().map(option => ({ label: option.replaceAll("\\|", ","), value: option.replaceAll("\\|", "\\,") })));
    } else {
      setSelectedOptions([]);
    }
  }, [filterObject]);

  return (
    <FormField label={
      <div className="flex justify-between">
        <div>{filterObject.label}</div>
        {filterObject.controlOptions.selectMultiple && (
          <div className="flex items-center gap-x-2 font-normal">
            <div className="text-xs dark:text-slate-400">AND</div>
            <Toggle
              checked={filterObject.valueJoinOperation === FilterJoinOperation.And}
              onChange={({ detail }) => {
                const joinOperation = detail.checked ? FilterJoinOperation.And : FilterJoinOperation.Or;
                setFilterObject({
                  ...filterObject,
                  value: selectedOptions.map(option => option.value),
                  valueJoinOperation: joinOperation,
                });
              }}
            />
          </div>
        )}
      </div>
    }>
      {filterObject.controlOptions.selectMultiple ? (
        <Multiselect
          selectedOptions={selectedOptions}
          onChange={({ detail }) => {
            setSelectedOptions(detail.selectedOptions);
            setFilterObject({
              ...filterObject,
              value: detail.selectedOptions.map(option => option.value),
            });
          }}
          options={options}
          loadingText="Loading"
          placeholder="Select options"
          statusType={isLoading ? "loading" : "finished"}
          onLoadItems={({ detail }) => {
            setSearchQuery(detail.filteringText);
          }}
          filteringType="manual"
          onFocus={() => {
            setDisabled(false);
          }}
          tokenLimit={tokenLimit}
          i18nStrings={{
            tokenLimitShowFewer: "Show less",
            tokenLimitShowMore: "Show more",
          }}
          virtualScroll
        />
      ) : (
        <Select
          selectedOption={selectedOptions.length > 0 ? options.find(x => x.value === selectedOptions[0].value) : null}
          onChange={({ detail }) => {
            setSelectedOptions([detail.selectedOption]);
            setFilterObject({
              ...filterObject,
              value: [detail.selectedOption.value],
            });
          }}
          options={options}
          loadingText="Loading"
          placeholder="Select option"
          statusType={isLoading ? "loading" : "finished"}
          onLoadItems={({ detail }) => {
            setSearchQuery(detail.filteringText);
          }}
          filteringType="manual"
          onFocus={() => {
            setDisabled(false);
          }}
          virtualScroll
        />
      )}
    </FormField>
  );
};