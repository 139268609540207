import { useMemo } from "react";
import moment from "moment";
import { useCompareContext } from "../../../hooks/useCompareContext";

interface UseDownloadGridConfigProps {
  crosslineData: Array<any>;
  titleMetricInfo: Array<any>;
}

export const useDownloadGridConfig = ({
  crosslineData,
  titleMetricInfo,
}: UseDownloadGridConfigProps) => {

  const { timeseriesData } = useCompareContext();

  const gridOptions = useMemo(() => {
    return {
      rowData: timeseriesData.map(d => (
        {
          ...d,
          notes: crosslineData.filter(c => c.value === d.timestamp)?.map(c => `${c.ip_name}: ${c.label}`)?.join(", ") ?? "",
        }
      )),
      columnDefs: [
        {
          field: "timestamp",
          headerName: "Date",
          valueFormatter: (params) => moment.utc(params.value).format("YYYY-MM-DD"),
          valueGetter: (params) => moment.utc(params.data.timestamp).format("YYYY-MM-DDT00:00:00"),
          width: 150,
          cellClass: "dateType",
        },
        ...titleMetricInfo.map(t => (
          {
            field: t.value,
            headerName: t.metadata.title.ip + " (" + t.metadata.metric.name + ")",
            width: 150,
            valueFormatter: (params) => params.value,
            valueGetter: (params) => Math.round(params.data[t.value] * 10000.0) / 10000.0,
            cellClass: "numberType",
          }
        )),
        {
          field: "notes",
          headerName: "Notes",
          width: 150,
        },
      ],
      excelStyles: [
        {
          id: "numberType",
          numberFormat: {
            format: "0.0000",
          },
        },
        {
          id: "dateType",
          dataType: "DateTime",
          numberFormat: {
            format: "m/d/yyy",
          },
        },
      ],
    };
  }, [timeseriesData, crosslineData, titleMetricInfo]);

  return {
    gridOptions,
  };
};