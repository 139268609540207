export const enum Vertical {
  Movies = "Movies",
  Series = "Television",
  Games = "Gaming",
};

export const Verticals = [Vertical.Movies, Vertical.Series, Vertical.Games];

export type VerticalOption = {
  label: string;
  value: Vertical | "all";
};

export const verticalOptions: VerticalOption[] = [
  { label: "Movies", value: Vertical.Movies },
  { label: "Series", value: Vertical.Series },
  { label: "Games", value: Vertical.Games },
];

export const verticalOptionsWithAll: VerticalOption[] = [
  { label: "All categories", value: "all" },
  { label: "Movies", value: Vertical.Movies },
  { label: "Series", value: Vertical.Series },
  { label: "Games", value: Vertical.Games },
];