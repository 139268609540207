import { ButtonDropdown, SpaceBetween } from "@cloudscape-design/components";
import React, { FC } from "react";

type FilterDropdownProps = {
  defaultFilters?: any;
  handleFilterItemClick: (id: string, filters: any) => void;
  existingPref: any;
  vertical: string;
};

export const FiltersDropdownV2: FC<FilterDropdownProps> = ({
  defaultFilters = {},
  handleFilterItemClick,
  existingPref,
  vertical,
}) => {
  const currentFilters = existingPref?.data?.[vertical]?.filters ?? {};
  const filters = { ...currentFilters, ...defaultFilters };

  const items = Object.keys(filters).map((key) => ({
    text: key,
    id: key,
  }));

  return (
    <SpaceBetween direction="horizontal" size="xs">
      <ButtonDropdown
        items={items}
        variant="normal"
        onItemClick={(event) => handleFilterItemClick(event.detail.id, filters)}
        disabled={items.length === 0}
      >
        Load filter
      </ButtonDropdown>
    </SpaceBetween>
  );
};
