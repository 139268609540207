import { useQuery } from "react-query";
import { TALENT_QUERY_KEYS } from "../contants";
import { getTalentData } from "../request";
import { TalentResponse } from "../types";

export const useGetTalentData = (id: string) => {
  const { isLoading, data, error } = useQuery<TalentResponse>(
    [TALENT_QUERY_KEYS.GET_TALENT_DATA, id],
    () => getTalentData(id)
  );

  return {
    data: data,
    isLoading,
    error,
  };
};
