import {
  Box,
  Button,
  Header,
  Pagination,
  Spinner,
  Table,
} from "@cloudscape-design/components";
import React, { FC, useEffect, useState } from "react";
import { useGetGenericPreference } from "../../../../../../services/generic/hooks/useGetGenericPreference";
import { usePostAndGetGenericPreference } from "../../../../../../services/generic/hooks/usePostAndGetGenericPreference";
import createFlashMessage from "../../../../../../utils/createFlashMessage";
import { isSome } from "../../../../../../utils/sugarUtils";
import CustomFlashBar from "../../../../../common/CustomFlashBar";
import {
  addMessageToFlash,
  cleanAllFlashMessage,
} from "../../../../../common/redux/flash-action";
import { AddMediaModal } from "../../../../components/AddMediaModal";
import Filters from "../../../../components/Filters";
import Preferences from "../../../../components/Preferences";
import { useFetchMediaData } from "../../api/hooks/useFetchMediaData";
import { useGetApiParams } from "../../hooks/useGetApiParams";
import { getColumnDefinitions } from "./columnDefinitions";

const DEFAULT_FILTERING_QUERY = { tokens: [], operation: "and" };

const getDefaultVisibleContent = (vertical) => {
  if (vertical === "companies") return ["name", "poster"];
  if (vertical === "games")
    return ["name", "poster", "release_date", "tracked"];
  if (vertical === "franchises")
    return [
      "name",
      "poster",
      "content_type",
      "total_ips",
      "film_count",
      "series_count",
      "game_count",
    ];

  return ["name", "poster", "release_date", "status", "tracked"];
};

interface MediaHomeProps {
  vertical: string;
  header: string;
  headerAddButtonText?: string;
  modalTitle?: string;
  modalPlaceholder?: string;
  modalAutoCompletePlatform?: string;
}

export const MediaHome: FC<MediaHomeProps> = ({
  vertical,
  header,
  headerAddButtonText,
  modalTitle,
  modalPlaceholder,
  modalAutoCompletePlatform,
}) => {
  const [preferences, setPreferences] = useState({
    wrapLines: true,
    stripedRows: true,
    contentDensity: "comfortable",
    pageSize: 50,
    visibleContent: getDefaultVisibleContent(vertical),
  });
  const [sortingColumn, setSortingColumn] = useState(null);
  const [sortingDescending, setSortingDescending] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteringQuery, setFilteringQuery] = useState(DEFAULT_FILTERING_QUERY);
  const [displayAddMediaModal, setDisplayAddMediaModal] = useState(false);

  const { postAndGetGenericPreference } = usePostAndGetGenericPreference({
    onSuccess: (data) =>
      addMessageToFlash(
        createFlashMessage({
          type: "success",
          message: data.response?.data?.message,
        }),
      ),
    onError: (e) => {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: e.response?.data?.message || "Failed to save preference ",
        }),
      );
    },
  });

  useGetGenericPreference({
    onSuccess: (data) => {
      if (data.explore?.[vertical]?.preferences) {
        setPreferences(data.explore?.[vertical]?.preferences);
      }
    },
  });

  const {
    mutate: getMediaData,
    data: mediaData,
    isLoading,
  } = useFetchMediaData({
    vertical,
    onError: (error) =>
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: error,
        }),
      ),
  });

  const { from, pageSize, range, body } = useGetApiParams({
    currentPage,
    pageSize: preferences.pageSize,
    sortingColumn,
    sortingDescending,
    filteringQuery,
    vertical,
  });

  useEffect(() => {
    if (isSome(from) && isSome(pageSize) && Object.keys(body).length > 0)
      getMediaData({ from, pageSize, range, body, vertical });
  }, [from, pageSize, range, body, getMediaData, vertical]);

  useEffect(() => {
    cleanAllFlashMessage();
  }, []);

  const handlePreferencesChange = (newPreferences) => {
    const payload = {
      explore: {
        [vertical]: {
          preferences: newPreferences,
        },
      },
    };

    postAndGetGenericPreference(payload);
    setPreferences(newPreferences);
  };

  return (
    <>
      <Table
        visibleColumns={preferences.visibleContent}
        columnDefinitions={getColumnDefinitions(vertical)}
        sortingColumn={sortingColumn}
        sortingDescending={sortingDescending}
        onSortingChange={(event) => {
          setSortingDescending(event.detail.isDescending);
          setSortingColumn(event.detail.sortingColumn);
        }}
        loading={isLoading}
        items={mediaData?.data}
        loadingText="Loading resources"
        wrapLines
        stickyHeader
        resizableColumns={true}
        variant="full-page"
        empty={
          <Box textAlign="center" color="inherit">
            <b>No items</b>
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
              No items to display.
            </Box>
          </Box>
        }
        header={
          <>
            <CustomFlashBar />
            <Header
              variant="h3"
              counter={mediaData?.total ? `(${mediaData?.total?.toLocaleString()})` : null}
              actions={
                headerAddButtonText ? (
                  <Button
                    iconName="add-plus"
                    onClick={() => setDisplayAddMediaModal(true)}
                  >
                    {headerAddButtonText}
                  </Button>
                ) : null
              }
            >
              {header}
            </Header>
          </>
        }
        preferences={
          <Preferences
            preferences={preferences}
            handleChange={handlePreferencesChange}
          />
        }
        pagination={
          <Pagination
            currentPageIndex={currentPage}
            pagesCount={Math.ceil(
              (mediaData?.total > 10000 ? 10000 : mediaData?.total) /
                preferences.pageSize,
            )}
            onChange={(page) => {
              setCurrentPage(page.detail.currentPageIndex);
            }}
          />
        }
        filter={
          <Filters
            data={mediaData?.data}
            preferences={preferences}
            query={filteringQuery}
            handleChange={(detail) => {
              setFilteringQuery(detail);
              setCurrentPage(1);
            }}
          />
        }
      />
      {headerAddButtonText && (
        <AddMediaModal
          visible={displayAddMediaModal}
          vertical={vertical}
          header={modalTitle}
          placeholder={modalPlaceholder}
          onDismiss={() => setDisplayAddMediaModal(false)}
          autoCompletePlatform={modalAutoCompletePlatform}
        />
      )}
    </>
  );
};
