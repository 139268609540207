import React, { useEffect, useMemo, useRef, useState } from "react";
import { ApiURLParams } from "../../services/generic_v2/requests";
import _ from "lodash";
import { WeightObject } from "../../types/weightTypes";
import { PlatformWeightsDrawerV2 } from "../../modules/ranking/components/PlatformWeightsDrawerV2";
import { FaSliders } from "react-icons/fa6";
import { useWeightPreferences } from "../../services/weightPreference/useWeightPreferences";

interface UseWeightDrawerProps {
  defaultWeightObject: WeightObject;
  prefsApiParams: ApiURLParams;
}

export const useWeightDrawer = ({
  defaultWeightObject,
  prefsApiParams,
}: UseWeightDrawerProps) => {
  const weightDrawerRef = useRef(null);

  const [weightsObject, setWeightsObject] = useState<WeightObject>(defaultWeightObject);

  const { primaryWeight } = useWeightPreferences({ 
    apiParams: prefsApiParams, 
    defaultWeightObject,
  });

  const weightDrawerConfig = useMemo(() => (
    {
      id: "weightDrawer",
      content: (
        <PlatformWeightsDrawerV2
          prefsApiParams={prefsApiParams}
          weightsObject={weightsObject}
          setWeightsObject={setWeightsObject}
          defaultWeightsObject={defaultWeightObject}
        />
      ),
      trigger: {
        iconName: "Weightage",
        iconSvg: (<FaSliders style={{ fill: "white" }} />),
      },
      ariaLabels: {
        drawerName: "My Drawer",
        closeButton: "Close",
        triggerButton: "Open",
        resizeHandle: "Resize",
      },
      resizable: true,
      defaultSize: 290,
      badge: !_.isEqual(weightsObject, defaultWeightObject),
    }
  ), [weightsObject]);

  useEffect(() => {
    if (!primaryWeight) return;
    setWeightsObject(primaryWeight);
    weightDrawerRef.current?.setCurrentWeightsObject(primaryWeight);
  }, [primaryWeight]);

  return {
    weightDrawerConfig,
    weightDrawerRef,
    weightsObject,
  };
};