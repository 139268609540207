import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGetManageTitleVariantsData } from "../../../api/hooks/useGetManageTitleVariantsData";
import { useFetchServerSideGridData } from "./hooks/useFetchServerSideGridData";
import { useQueryPromise } from "../../../../../../hooks/useQueryPromise";
import { AgGridReact } from "ag-grid-react";
import { useGetGridTheme } from "../../../../../../hooks/UseTheme/useGetGridTheme";
import { useGridConfig } from "./hooks/useGridConfig";
import { Button, ColumnLayout, Input } from "@cloudscape-design/components";
import axiosInstance from "../../../../../../utils/axios";
import { MessageBox } from "../../../../../../components/MessageBox";


export const ManageTitleVariants = () => {

  const gridRef = useRef(null);
  const messageBoxRef = useRef(null);
  const { theme } = useGetGridTheme();

  const [changedVariants, setChangedVariants] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryFilter, setSearchQueryFilter] = useState("");

  const sanitizedChangedVariants = useMemo(() => {
    return Object.values(changedVariants).filter((v: any) => v.newValue !== v.origValue);
  }, [changedVariants]);

  const { getData } = useQueryPromise(useGetManageTitleVariantsData);

  const gridOptions = useGridConfig();

  useFetchServerSideGridData({
    gridRef,
    fetchMethod: getData,
    searchQuery: searchQueryFilter,
  });

  const saveChanges = useCallback(() => {
    const body = sanitizedChangedVariants.map((v: any) => ({
      [v.ipId]: v.newValue.split(","),
    })).reduce((acc: any, curr: any) => ({ ...acc, ...curr }), {});

    setIsSaving(true);
    axiosInstance.request({
      method: "POST",
      url: "/google/manage/titlevariants",
      data: body,
    }).then(() => {
      setChangedVariants({});
      setIsSaving(false);
    });
  }, [sanitizedChangedVariants]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-3">
        <div className="text-slate-600 dark:text-slate-400 text-xs">
          <p>Title variants are used in the Google search keyword list generation process. When editing these, think as if you were searching for the title on Google.</p>
          <p>Keep in mind that these variants are used in query templates, so an ambiguous movie title like "accident" will have "movie" appended to it in the final list, so there's no need to add any extra disambiguation.</p>
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex gap-2">
          <Input
            type="search"
            placeholder="Search"
            value={searchQuery}
            onChange={({ detail }) => {
              setSearchQuery(detail.value);
              if (detail.value === "") {
                setSearchQueryFilter("");
              }
            }}
            onKeyDown={({ detail }) => {
              if (detail.key === "Enter") {
                setSearchQueryFilter(searchQuery);
              }
            }}
          />
          <Button 
            iconName="search"
            onClick={() => setSearchQueryFilter(searchQuery)}
          />
        </div>
        <Button
          onClick={() => {
            messageBoxRef.current.open({
              headerText: "Save changed",
              messageBody: (
                <div>
                  <p className="mb-4">Overwrite the following title variants?</p>
                  {sanitizedChangedVariants.map((v: any) => (
                    <div className="flex gap-3 mb-3">
                      <span className="flex gap-2 items-center">{v.origValue.split(",").map((variant) => (
                        <div className="px-2 py-1 border border-blue-400 text-blue-400 rounded-full text-xs">{variant}</div>
                      ))}</span>
                      <span>&#x2192;</span>
                      <span className="flex gap-2 items-center">{v.newValue.split(",").map((variant) => (
                        <div className="px-2 py-1 border border-blue-400 text-blue-400 rounded-full text-xs">{variant}</div>
                      ))}</span>
                    </div>
                  ))}
                </div>
              ),
              primaryButtonText: "Submit",
              secondaryButtonText: "Cancel",
              onPrimaryButtonClick: () => {
                saveChanges();
              },
              onSecondaryButtonClick: () => {},
            });
          }}
          disabled={sanitizedChangedVariants.length === 0}
          loading={isSaving}
        >
          Save changes
        </Button>
      </div>
      <div style={{ height: "80vh", width: "100%" }} className={theme}>
        <AgGridReact
          ref={gridRef}
          pagination={true}
          paginationPageSizeSelector={[20, 50, 100]}
          rowModelType={"serverSide"}
          blockLoadDebounceMillis={100}
          onCellValueChanged={(params) => {
            setChangedVariants((prev) => (
              {
                ...prev,
                [params.data.ip_id]: {
                  ipId: params.data.ip_id,
                  newValue: params.newValue,
                  origValue: prev[params.data.ip_id] ? prev[params.data.ip_id].origValue : params.oldValue,
                },
              }
            ));
          }}
          {...gridOptions}
        />
      </div>
      <MessageBox ref={messageBoxRef} />
    </div>
  );
};