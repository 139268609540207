


export const convertWeightObjectsCompatV1 = (weightObjects) => {
  const convertedWeights = weightObjects.map(w => {
    w.weights = w.weights.map(weight => {
      if (weight.platform) {
        weight.label = weight.platform.name;
        weight.field = weight.platform.key;
        delete weight.platform;
      }
      return weight;
    });
    return w;
  });
  return convertedWeights;
};

export const convertWeightObjectsCompat = (weightObjects) => {
  return convertWeightObjectsCompatV1(weightObjects);
};