import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, ButtonDropdown, Checkbox, FormField, Grid, Header, Icon, Input, Modal, Popover, Select, Slider, SpaceBetween, Tabs } from "@cloudscape-design/components";
import { DATAPOINTS, PLATFORMS, SERVICES, TYPES } from "../../../../config-global";
import { useGetGenericPreference_V2 } from "../../../../services/generic_v2/hooks/useGetGenericPreference_V2";
import { usePutGenericPreference_V2 } from "../../../../services/generic_v2/hooks/usePutGenericPreference_V2";
import { usePostGenericPreference_V2 } from "../../../../services/generic_v2/hooks/usePostGenericPreference_V2";
import { invalidatePreferenceData } from "../../../../services/generic_v2/utils/serviceUtils";
import _ from "lodash";
import moment from "moment";
import { METRIC_GROUPS } from "../../../../components/datapoint-comparison/constants";
import { MessageBox, MessageBoxOptions } from "../../../../components/MessageBox";
import { Metric, NameKeyPair } from "../../../../components/datapoint-comparison/types";

const defaultCustomMetric: Metric = {
  name: "Untitled metric",
  key: `custom_metric_${moment().format("YYYYMMDDHHmmss")}`,
  group: METRIC_GROUPS.CUSTOM,
  normalize: false,
  decimalDigits: 0,
  datapoints: [],
};

const availablePlatformDatapoints = [
  {
    displayPlatform: PLATFORMS.WIKIPEDIA,
    datapoints: [
      { platform: PLATFORMS.GLOBAL_WIKIPEDIA, datapoint: DATAPOINTS.WIKIPEDIA.PAGE_VIEWS },
      { platform: PLATFORMS.PANTHEON_GLOBAL, datapoint: DATAPOINTS.WIKIPEDIA.ZSCORE },
    ],
  },
  {
    displayPlatform: PLATFORMS.YOUTUBE,
    datapoints: [
      { platform: PLATFORMS.GLOBAL_YOUTUBE, datapoint: DATAPOINTS.YOUTUBE.VIEWS },
      { platform: PLATFORMS.GLOBAL_YOUTUBE, datapoint: DATAPOINTS.YOUTUBE.LIKES },
      { platform: PLATFORMS.GLOBAL_YOUTUBE, datapoint: DATAPOINTS.YOUTUBE.COMMENTS },
      { platform: PLATFORMS.PANTHEON_GLOBAL, datapoint: DATAPOINTS.YOUTUBE.ZSCORE },
    ],
  },
  {
    displayPlatform: PLATFORMS.IMDB,
    datapoints: [
      { platform: PLATFORMS.GLOBAL_IMDB, datapoint: DATAPOINTS.IMDB.VOTES },
      { platform: PLATFORMS.GLOBAL_IMDB, datapoint: DATAPOINTS.IMDB.RATING },
      { platform: PLATFORMS.PANTHEON_GLOBAL, datapoint: DATAPOINTS.IMDB.ZSCORE },
    ],
  },
  {
    displayPlatform: PLATFORMS.ROTTEN_TOMATOES,
    datapoints: [
      { platform: PLATFORMS.GLOBAL_ROTTEN_TOMATOES, datapoint: DATAPOINTS.ROTTEN_TOMATOES.AUDIENCE_VOTES },
      { platform: PLATFORMS.GLOBAL_ROTTEN_TOMATOES, datapoint: DATAPOINTS.ROTTEN_TOMATOES.AUDIENCE_RATING },
      { platform: PLATFORMS.GLOBAL_ROTTEN_TOMATOES, datapoint: DATAPOINTS.ROTTEN_TOMATOES.CRITIC_VOTES },
      { platform: PLATFORMS.GLOBAL_ROTTEN_TOMATOES, datapoint: DATAPOINTS.ROTTEN_TOMATOES.CRITIC_RATING },
      { platform: PLATFORMS.PANTHEON_GLOBAL, datapoint: DATAPOINTS.ROTTEN_TOMATOES.ZSCORE },
    ],
  },
  {
    displayPlatform: PLATFORMS.STEAM,
    datapoints: [
      { platform: PLATFORMS.GLOBAL_STEAM, datapoint: DATAPOINTS.STEAM.PLAYER_COUNT },
      { platform: PLATFORMS.PANTHEON_GLOBAL, datapoint: DATAPOINTS.STEAM.ZSCORE },
    ],
  },
  {
    displayPlatform: PLATFORMS.TWITCH,
    datapoints: [
      { platform: PLATFORMS.GLOBAL_TWITCH, datapoint: DATAPOINTS.TWITCH.VIEWER_COUNT },
      { platform: PLATFORMS.GLOBAL_TWITCH, datapoint: DATAPOINTS.TWITCH.STREAMER_COUNT },
      { platform: PLATFORMS.PANTHEON_GLOBAL, datapoint: DATAPOINTS.TWITCH.ZSCORE },
    ],
  },
  {
    displayPlatform: PLATFORMS.PIRACY,
    datapoints: [
      { platform: PLATFORMS.PIRACY, datapoint: DATAPOINTS.PIRACY.DOWNLOADS },
      { platform: PLATFORMS.PANTHEON_GLOBAL, datapoint: DATAPOINTS.PIRACY.ZSCORE },
    ],
  },
  {
    displayPlatform: PLATFORMS.GOOGLE_SEARCH,
    datapoints: [
      { platform: PLATFORMS.GOOGLE_SEARCH, datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_GENERAL_CATEGORY },
      { platform: PLATFORMS.GOOGLE_SEARCH, datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_GENERAL_RELEASE_YEAR },
      { platform: PLATFORMS.GOOGLE_SEARCH, datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_PLAY_CONSOLE },
      { platform: PLATFORMS.GOOGLE_SEARCH, datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_PLAY_PC },
      { platform: PLATFORMS.GOOGLE_SEARCH, datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_PLAY_WHERE_TO_PLAY },
      { platform: PLATFORMS.GOOGLE_SEARCH, datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_WATCH_CABLE },
      { platform: PLATFORMS.GOOGLE_SEARCH, datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_WATCH_PHYSICAL },
      { platform: PLATFORMS.GOOGLE_SEARCH, datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_WATCH_PIRACY },
      { platform: PLATFORMS.GOOGLE_SEARCH, datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_WATCH_LATEST_SEASON },
      { platform: PLATFORMS.GOOGLE_SEARCH, datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_WATCH_THEATRICAL },
      { platform: PLATFORMS.GOOGLE_SEARCH, datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_INTENT_TO_WATCH_WHERE_TO_WATCH },
      { platform: PLATFORMS.GOOGLE_SEARCH, datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_LEARN_ABOUT_AWARDS },
      { platform: PLATFORMS.GOOGLE_SEARCH, datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_LEARN_ABOUT_GENERAL },
      { platform: PLATFORMS.GOOGLE_SEARCH, datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_LEARN_ABOUT_NEWS },
      { platform: PLATFORMS.GOOGLE_SEARCH, datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_LEARN_ABOUT_REVIEWS },
      { platform: PLATFORMS.GOOGLE_SEARCH, datapoint: DATAPOINTS.GOOGLE_SEARCH.VOLUME_LEARN_ABOUT_SOCIAL },
    ],
  },
];

interface CustomMetricsModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

export const CustomMetricsModal: React.FC<CustomMetricsModalProps> = ({
  visible,
  setVisible,
}) => {

  const apiParams = { type: TYPES.PERSONAL, service: SERVICES.TOOLS, module: "compare" };
  const messageBoxRef = useRef(null);

  const [loadedMetrics, setLoadedMetrics] = useState<Array<Metric>>([]);
  const [currentMetric, setCurrentMetric] = useState<Metric>(defaultCustomMetric);

  const { data: preferenceData } = useGetGenericPreference_V2({
    apiParams,
  });
  const { mutate: updatePreference } = usePutGenericPreference_V2();
  const { mutate: postGenericPreference } = usePostGenericPreference_V2({
    apiParams,
    onSuccess: invalidatePreferenceData,
  });

  useEffect(() => {
    const pref = preferenceData?.[0]?.data;

    if (pref) {
      if (pref.customMetrics) {
        setLoadedMetrics(pref.customMetrics as Array<Metric>);
      }
    }
  }, [preferenceData]);

  const savePreferences = (payload) => {
    const prefValue = preferenceData?.[0];

    if (Object.keys(prefValue?.data ?? {}).length > 0) {
      const { data } = prefValue;

      const finalPayload = { ...data, ...payload };

      updatePreference({ id: prefValue.id, payload: finalPayload });
      return;
    }

    postGenericPreference(payload);
  };

  const saveCustomMetrics = (metricObjects: Array<Metric>) => {
    savePreferences({ customMetrics: metricObjects });
  };

  const currentMetricIsSaved = useMemo(() => {
    return loadedMetrics?.map(m => _.isEqual(m, currentMetric)).includes(true);
  }, [currentMetric, loadedMetrics]);

  return (
    <Modal
      header={
        <div className="w-full flex justify-between items-center mt-[-0.3rem]">
          <div className="">Manage Custom Metrics</div>
          <div className="flex gap-2 justify-end w-[31rem]">
            <ButtonDropdown
              items={[
                { text: "Save metric", id: "save", disabled: currentMetricIsSaved || _.isEqual(currentMetric, defaultCustomMetric) },
                { text: "Load metric", items: loadedMetrics.map(metric => ({ text: metric.name, id: `load_${metric.key}` })), disabled: loadedMetrics.length === 0 },
                { text: "Delete loaded metric", id: "delete", disabled: loadedMetrics.filter(metric => metric.key === currentMetric.key).length === 0 },
                { text: "Reset fields", id: "clear", disabled: _.isEqual(currentMetric, defaultCustomMetric) },
              ]}
              expandableGroups
              onItemClick={({ detail }) => {
                switch (detail.id) {
                  case "save":
                    const loadedMetricWithId = loadedMetrics.find(m => m.key === currentMetric.key);
                    const metricCopy = JSON.parse(JSON.stringify(currentMetric));
                    if (loadedMetricWithId) {
                      messageBoxRef.current?.open({
                        headerText: "Overwrite metric",
                        messageBody: <div>Are you sure you want to overwrite <b>{loadedMetricWithId.name}</b> with new values?</div>,
                        primaryButtonText: "Yes",
                        secondaryButtonText: "No",
                        onPrimaryButtonClick: () => {
                          const newLoadedMetrics = loadedMetrics.map(m => m.key === metricCopy.key ? metricCopy : m);
                          setLoadedMetrics(newLoadedMetrics);
                          saveCustomMetrics(newLoadedMetrics);
                        }
                      } as MessageBoxOptions);
                    } else {
                      const newLoadedMetrics = [...loadedMetrics, metricCopy];
                      setLoadedMetrics(newLoadedMetrics);
                      saveCustomMetrics(newLoadedMetrics);
                    }
                    break;
                  case "clear":
                    messageBoxRef.current?.open({
                      headerText: "Reset fields",
                      messageBody: <div><p>Are you sure you want to reset all fields to their default values?</p><p>You will lose any unsaved changes.</p></div>,
                      primaryButtonText: "Yes",
                      secondaryButtonText: "No",
                      onPrimaryButtonClick: () => {
                        setCurrentMetric(defaultCustomMetric);
                      }
                    } as MessageBoxOptions);
                    break;
                  case "delete":
                    messageBoxRef.current?.open({
                      headerText: "Delete metric",
                      messageBody: <div>Are you sure you want to delete metric <b>{currentMetric.name}</b>?</div>,
                      primaryButtonText: "Yes",
                      secondaryButtonText: "No",
                      onPrimaryButtonClick: () => {
                        const newLoadedMetrics = loadedMetrics.filter(m => m.key !== currentMetric.key);
                        setLoadedMetrics(newLoadedMetrics);
                        saveCustomMetrics(newLoadedMetrics);
                        setCurrentMetric(defaultCustomMetric);
                      }
                    } as MessageBoxOptions);
                    break;
                  default:
                    if (detail.id.startsWith("load_")) {
                      const metricKey = detail.id.replace("load_", "");
                      const metric = loadedMetrics.find(m => m.key === metricKey);

                      if (metric) {
                        const metricCopy = JSON.parse(JSON.stringify(metric));
                        if (!_.isEqual(currentMetric, defaultCustomMetric) && !currentMetricIsSaved) {
                          messageBoxRef.current?.open({
                            headerText: "Load metric",
                            messageBody: <div>Are you sure you want to load metric <b>{metric.name}</b> and discard your changes in <b>{currentMetric.name}</b>?</div>,
                            primaryButtonText: "Yes",
                            secondaryButtonText: "No",
                            onPrimaryButtonClick: () => {
                              setCurrentMetric(metricCopy);
                            }
                          } as MessageBoxOptions);
                        } else {
                          setCurrentMetric(metricCopy);
                        }
                      }
                    }
                    break;
                }
              }}
            >
              Actions
            </ButtonDropdown>
          </div>
        </div>
      }
      size="large"
      visible={visible}
      onDismiss={() => setVisible(false)}
      footer={
        <div className="flex justify-between items-center">
          <div className="dark:text-slate-400 text-slate-600 h-full">{(!currentMetricIsSaved && !_.isEqual(currentMetric, defaultCustomMetric)) ? "You have unsaved changes" : ""}</div>
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="primary" disabled={currentMetricIsSaved || _.isEqual(currentMetric, defaultCustomMetric)} onClick={() => {
              const loadedMetricWithId = loadedMetrics.find(m => m.key === currentMetric.key);
              const metricCopy = JSON.parse(JSON.stringify(currentMetric));
              if (loadedMetricWithId) {
                messageBoxRef.current?.open({
                  headerText: "Overwrite metric",
                  messageBody: <div>Are you sure you want to overwrite <b>{loadedMetricWithId.name}</b> with new values?</div>,
                  primaryButtonText: "Yes",
                  secondaryButtonText: "No",
                  onPrimaryButtonClick: () => {
                    const newLoadedMetrics = loadedMetrics.map(m => m.key === metricCopy.key ? metricCopy : m);
                    setLoadedMetrics(newLoadedMetrics);
                    saveCustomMetrics(newLoadedMetrics);
                  }
                } as MessageBoxOptions);
              } else {
                const newLoadedMetrics = [...loadedMetrics, metricCopy];
                setLoadedMetrics(newLoadedMetrics);
                saveCustomMetrics(newLoadedMetrics);
              }
            }}>Save changes</Button>
          </SpaceBetween>
        </div>
      }
    >
      <SpaceBetween direction="vertical" size="m">
        <div className="text-xs dark:text-slate-400 text-slate-600">{loadedMetrics.map(x => x.key).includes(currentMetric.key) ? <div>Currently editing metric: <b>{loadedMetrics.find(x => x.key === currentMetric.key).name}</b></div> : <div>Currently creating new metric: <b>{currentMetric.name}</b></div>}</div>
        <Grid 
          className="grow"
          gridDefinition={[
            { colspan: 6 }, { colspan: 6 },
          ]}
        >
          <div className="grow">
            <FormField label="Metric name">
              <Input 
                value={currentMetric.name}
                onChange={({ detail }) => setCurrentMetric({ ...currentMetric, name: detail.value })}
                placeholder="Enter metric name"
              />
            </FormField>
          </div>
          <FormField 
            label="Normalize values"
            info={
              <Popover
                position="top"
                size="medium"
                triggerType="custom"
                content={
                  <div>Normalized metrics will cap their max value to 100 on the timeseries</div>
                }
              >
                <Button variant="inline-link" iconName="status-info" className="ml-[-0.25rem]" />
              </Popover>
            }
          >
            <Checkbox
              className="mt-1"
              checked={currentMetric.normalize}
              onChange={({ detail }) => setCurrentMetric({ ...currentMetric, normalize: detail.checked })}
            >
              Enabled
            </Checkbox>
          </FormField>
        </Grid>
        <FormField label="Datapoints" stretch>
          <div className="flex flex-col gap-2">
            {currentMetric.datapoints.map((datapoint, index) => {
              const datapointPlatform = datapoint._platform as NameKeyPair;
              return (
                <div key={index} className="flex">
                  <Grid 
                    className="grow"
                    gridDefinition={[
                      { colspan: 3 }, { colspan: 3 }, { colspan: 4 }, { colspan: 2 },
                    ]}
                  >
                    <FormField description={index === 0 ? "Platform" : null}>
                      <Select
                        placeholder="Select platform"
                        selectedOption={datapointPlatform ? { label: datapointPlatform.name, value: datapointPlatform.key } : null}
                        options={availablePlatformDatapoints.map(platform => ({ label: platform.displayPlatform.name, value: platform.displayPlatform.key }))}
                        onChange={({ detail }) => {
                          const newDatapoints = [...currentMetric.datapoints];
                          newDatapoints[index]._platform = Object.values(PLATFORMS).find(platform => platform.key === detail.selectedOption.value);
                          newDatapoints[index].datapoint = null;
                          setCurrentMetric({ ...currentMetric, datapoints: newDatapoints });
                        }}
                      />
                    </FormField>
                    <FormField description={index === 0 ? "Datapoint" : null}>
                      <Select
                        placeholder="Select datapoint"
                        selectedOption={datapoint.datapoint ? { label: datapoint.datapoint.name, value: datapoint.datapoint.key } : null}
                        options={datapointPlatform ? availablePlatformDatapoints.find(pd => pd.displayPlatform.key === datapointPlatform.key).datapoints.map(datapoint => ({ label: datapoint.datapoint.name, value: datapoint.datapoint.key })) : []}
                        onChange={({ detail }) => {
                          const datapointKey = detail.selectedOption.value;
                          const realPlatformDatapoint = availablePlatformDatapoints.find(pd => pd.displayPlatform.key === datapointPlatform.key).datapoints.find(dp => dp.datapoint.key === datapointKey);
                          const newDatapoints = [...currentMetric.datapoints];
                          newDatapoints[index].platform = realPlatformDatapoint.platform;
                          newDatapoints[index].datapoint = realPlatformDatapoint.datapoint;
                          setCurrentMetric({ ...currentMetric, datapoints: newDatapoints });
                        }}
                        disabled={!datapointPlatform}
                      />
                    </FormField>
                    <FormField description={index === 0 ? "Weight" : null}>
                      <div className="flex gap-2">
                        <div className="grow">
                          <Slider
                            className="slider-no-labels"
                            onChange={({ detail }) => {
                              const newDatapoints = [...currentMetric.datapoints];
                              newDatapoints[index].weight = detail.value;
                              setCurrentMetric({ ...currentMetric, datapoints: newDatapoints });
                            }}
                            value={datapoint.weight as number}
                            max={10.0}
                            min={0.0}
                            step={0.5}
                            disabled={!datapoint.datapoint}
                          />
                        </div>
                        <div className="w-16">
                          <Input
                            value={datapoint.weight as any}
                            onChange={({ detail }) => {
                              const newDatapoints = [...currentMetric.datapoints];
                              newDatapoints[index].weight = parseFloat(detail.value);
                              setCurrentMetric({ ...currentMetric, datapoints: newDatapoints });
                            }}
                            inputMode="decimal"
                            type="number"
                            disabled={!datapoint.datapoint}
                            step={0.5}
                          />
                        </div>
                      </div>
                    </FormField>
                    <FormField description={index === 0 ? "\u200C" : null}>
                      <Button
                        className="mt-1"
                        iconName="remove"
                        variant="inline-link"
                        onClick={() => {
                          const newDatapoints = [...currentMetric.datapoints];
                          newDatapoints.splice(index, 1);
                          setCurrentMetric({ ...currentMetric, datapoints: newDatapoints });
                        }}
                        disabled={currentMetric.datapoints.length === 1}
                      >
                        Remove
                      </Button>
                    </FormField>
                  </Grid>
                </div>
              );
            })}
            <div className="flex gap-2">
              <Button
                iconName="add-plus"
                onClick={() => {
                  setCurrentMetric({
                    ...currentMetric,
                    datapoints: [...currentMetric.datapoints, { platform: null, datapoint: null, weight: 1.0 }],
                  });
                }}
              >
                Add datapoint
              </Button>
            </div>
          </div>
        </FormField>
      </SpaceBetween>
      <MessageBox ref={messageBoxRef} />
    </Modal>
  );
};