import React from "react";
import { Link } from "@cloudscape-design/components";

export const MediaCard = ({ data }) => {
  const isGame = data.ip_id?.startsWith('game-');
  const imgSrc = isGame ? data.url : `https://image.tmdb.org/t/p/w300${data.url}`;

  return (
    <article className="grid grid-cols-1 md:grid-cols-2 gap-2">
      <div className="w-full md:w-64 xl:w-96 h-auto">
        <img
          src={imgSrc}
          alt={data.ip}
          className="rounded-lg w-56 h-auto object-cover shadow-md"
          loading="lazy"
        />
      </div>
      <div className="flex flex-col gap-4">
        <Link href={`/item/${data.ip_id}`}>
          <h2 className="text-2xl font-bold ">
            {data.ip}
          </h2>
        </Link>
        <div className="flex items-center gap-2">
          <span className="font-bold">Status:</span>
          <span className="px-2 py-1 bg-green-600 text-white text-sm rounded-full">
            {data.status}
          </span>
        </div>
        <div>
          <span className="font-bold">Release date:</span>
          <span className="ml-2">{data.releaseDate}</span>
        </div>
        <div>
          <span className="font-bold">Genres:</span>
          <div className="flex flex-wrap gap-2 mt-1">
            {Array.isArray(data.genre) && data.genre.length > 0 ? (
              data.genre.map((genre, index) => (
                <span
                  key={index}
                  className="px-2 py-1 bg-gray-700 text-white text-sm rounded-full"
                >
                  {genre}
                </span>
              ))
            ) : (
              <span className="text-gray-400 text-sm">No genres available</span>
            )}
          </div>
        </div>
        <div>
          <span className="font-bold">Summary:</span>
          <div>{data.summary}</div>
        </div>
      </div>
    </article>
  );
};
