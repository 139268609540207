import React, { useCallback, useMemo } from "react";
import { Icon, Link } from "@cloudscape-design/components";
import { numberColumnDefinition, getAdditionalColumnDefinition } from "../gridUtils";
import { CHART_LABELS } from "../../../../../../platforms/hooks/useGridConfig";
import renderTrend from "../../../../../../../utils/trendIcon";

export const chartMap = {
  [CHART_LABELS.BROADCASTERS_TREND]: {
    headerName: "Broadcasters Trend",
    field: "monthly_avg_broadcasters",
    isChart: true,
  },
  [CHART_LABELS.FOLLOWERS_TREND]: {
    headerName: "New Followers Trend",
    field: "monthly_new_followers",
    isChart: true,
  },
  [CHART_LABELS.VIEWERS_TREND]: {
    headerName: "Viewers Trend",
    field: "monthly_avg_viewers",
    isChart: true,
  },
  [CHART_LABELS.VOTE_TREND]: {
    headerName: "Vote Trend",
    field: "monthly_votes",
    isChart: true,
  },
  [CHART_LABELS.DOWNLOAD_TRENDS]: {
    headerName: "Download Trend",
    field: "monthly_downloads",
    isChart: true,
  },
  [CHART_LABELS.PLAYERS_COUNT_TRENDS]: {
    headerName: "Players Trend",
    field: "monthly_player_count",
    isChart: true,
  },
  [CHART_LABELS.RECOMMENDATIONS_TRENDS]: {
    headerName: "Recommendations Trend",
    field: "monthly_recommendations",
    isChart: true,
  },
  [CHART_LABELS.POSITIVE_REVIEW_TRENDS]: {
    headerName: "Postive Review Trend",
    field: "monthly_positive_reviews",
    isChart: true,
  },
  [CHART_LABELS.BROADCASTERS_TREND]: {
    headerName: "Broadcasters Trend",
    field: "monthly_avg_broadcasters",
    isChart: true,
  },
  [CHART_LABELS.FOLLOWERS_TREND]: {
    headerName: "New Followers Trend",
    field: "monthly_new_followers",
    isChart: true,
  },
  [CHART_LABELS.PAGEVIEW_TREND]: {
    headerName: "PageView Trend",
    field: "last_30_days_views",
    isChart: true,
  },
  [CHART_LABELS.VIEWS_TREND]: {
    headerName: "Views Trend",
    field: "monthly_views",
    isChart: true,
  },
  [CHART_LABELS.LIKES_TREND]: {
    headerName: "Likes Trend",
    field: "monthly_likes",
    isChart: true,
  },
  [CHART_LABELS.COMMENTS_TREND]: {
    headerName: "Comments Trend",
    field: "monthly_comments",
    isChart: true,
  },
  [CHART_LABELS.FRANCHISE]: {
    headerName: "Franchise",
    field: "franchise",
    cellDataType: "object",
  },
  [CHART_LABELS.PLATFORM]: {
    headerName: "Platform",
    field: "platform",
    cellDataType: "object",
  },
  [CHART_LABELS.GENRE]: {
    headerName: "Genre",
    field: "genre",
    cellDataType: "object",
  },
  [CHART_LABELS.THEME]: {
    headerName: "Themes",
    field: "themes",
    cellDataType: "object",
  },
  [CHART_LABELS.CUSTOM_TAGS]: {
    headerName: "Custom Tags",
    field: "cTags",
    cellDataType: "object",
  },
  [CHART_LABELS.DEVELOPER]: {
    headerName: "Developer",
    field: "developer",
    cellDataType: "object",
  },
  [CHART_LABELS.PUBLISHER]: {
    headerName: "Publisher",
    field: "publisher",
    cellDataType: "object",
  },
  [CHART_LABELS.AGE_RATINGS]: {
    headerName: "Age Rating",
    field: "age_ratings",
    cellDataType: "object",
  },
  [CHART_LABELS.COUNTRIES]: {
    headerName: "Countries",
    field: "countries",
    cellDataType: "object",  },
  [CHART_LABELS.PLAYER_PERSPECTIVE]: {
    headerName: "Player Perspective",
    field: "player_perspectives",
    cellDataType: "object",
  },
  [CHART_LABELS.GAME_MODE]: {
    headerName: "Game Mode",
    field: "game_modes",
    cellDataType: "object",
  },
  [CHART_LABELS.COUNTRIES]: {
    headerName: "Countries",
    field: "countries",
    cellDataType: "object",
  },
  [CHART_LABELS.DISTRIBUTORS]: {
    headerName: "Distributors",
    field: "distributors",
    cellDataType: "object",
  },
  [CHART_LABELS.PRODUCTION]: {
    headerName: "Production",
    field: "production",
    cellDataType: "object",
  },
  [CHART_LABELS.OTT]: {
    headerName: "OTT",
    field: "providers",
    cellDataType: "object",
  },
};

export const useGridConfig = ({
  weightageValues,
  additionalPlatformColumns,
  selectedOptions,
  sortField,
  sortOrder,
}) => {

  const defaultColDef = {
    sortable: true,
    flex: 1,
    suppressHeaderMenuButton: true,
    suppressHeaderFilterButton: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    minWidth: 100,
    suppressMovable: true,
  };

  const shouldRenderColumn = (field, weightageValues) => weightageValues[field] > 0;
  
  const getNormalScores = (field, headerName) => {
    return numberColumnDefinition(field, headerName);
  };

  const renderNormalScoreColumns = (weightageValues) => {
    const columns = [
      { key: "wiki", name: "Wikipedia Score" },
      { key: "youtube", name: "YouTube Score" },
      { key: "piracy", name: "Piracy Score" },
      { key: "twitch", name: "Twitch Score" },
      { key: "steam", name: "Steam Score" },
      { key: "imdb", name: "IMDb Score" },
      { key: "rt", name: "Rotten Tomatoes Score" },
    ];
  
    const result = [];
    columns.forEach(({ key, name }) => {
      if (shouldRenderColumn(key, weightageValues)) {
        result.push(getNormalScores(key, name));
      }
    });
  
    return result;
  };

  const columnDefs = useMemo(() => {
    const allColumns = [
      {
        field: "rank",
        headerName: "Rank",
        pinned: "left",
        width: 150,
        minWidth: 100,
        flex: 1,
        checkboxSelection: true,
        cellRenderer: (params) => {
          if (params.data.rank_change != null) {
            const rankTrend = params.data.rank_change > 0 ? "up" : params.data.rank_change < 0 ? "down" : "same";
            const newParams = { ...params, data: { ...params.data, rank_trend: rankTrend } };
            return renderTrend(newParams, "rank", "rank_change", "rank_trend");
          }
          return params.value;
        },
      },
      {
        ...numberColumnDefinition("score", "Score"),
        maxWidth: 100,
        pinned: "left",
        initialSort: sortField === "score" ? sortOrder : undefined,
      },
      {
        ...numberColumnDefinition("score_change", "Score Change"),
        maxWidth: 100,
        pinned: "left",
        hide: !selectedOptions.includes(CHART_LABELS.SCORE_CHANGE),
        initialSort: sortField === "score_change" ? sortOrder : undefined,
      },
      {
        field: "ip",
        headerName: "Title",
        minWidth: 250,
        filter: "agNumberColumnFilter",
        pinned: "left",
        cellRenderer: (params) => {
          let iconVariant;
          if (params.data.tracked === true) {
            iconVariant = "success";
          } else if (params.data.tracked === false) {
            iconVariant = "disabled";
          } else {
            iconVariant = "disabled";
          }
    
          if (!params.value) return null;
          const ipId = params.data.ip_id;
          const release_year = params.data.release_date ? new Date(Date.parse(params.data.release_date)).getFullYear() : "N/A";
          return (
            <div className="flex gap-2 mt-2.5 align-middle">
              <Icon
                name={
                  params.data.tracked != null
                    ? "status-positive"
                    : "status-negative"
                }
                variant={iconVariant}
              />
              <Link href={`/item/${ipId}`}>
                <span>{params.data.ip}</span>
              </Link>
              <span className="text-xs text-slate-500 mt-0.5">
                {release_year}
              </span>
            </div>
          );
        },
      },
      {
        field: "vertical",
        headerName: "Category",
        hide: true,
      },
      {
        field: "release_date",
        headerName: "Release Date",
        hide: true,
      },
    ];

    const normalScoreColumns = weightageValues ? renderNormalScoreColumns(weightageValues) : [];

    const additionalColumns = additionalPlatformColumns ? additionalPlatformColumns.filter(key => key !== CHART_LABELS.SCORE_CHANGE).map(key => {
      const { field, headerName, cellDataType } = chartMap[key];
      const hide = !selectedOptions.find(o => o === key);
      return getAdditionalColumnDefinition(
        field,
        headerName,
        cellDataType,
        hide,
      );
    }) : [];

    return [
      ...allColumns,
      ...normalScoreColumns,
      ...additionalColumns,
    ];
  }, [weightageValues, selectedOptions, additionalPlatformColumns]);

  const onGridReady = useCallback((params) => {
    const advancedFilterElement = document.getElementById(
      "advancedFilterParent",
    );

    if (advancedFilterElement) {
      params.api.setGridOption("advancedFilterParent", advancedFilterElement);
      params.api.setGridOption("includeHiddenColumnsInAdvancedFilter", true);
    }
  }, []);

  return {
    defaultColDef,
    columnDefs,
    onGridReady,
  };
};