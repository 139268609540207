import {
  Box,
  Button,
  Flashbar,
  FormField,
  Link,
  Modal,
  SpaceBetween,
  Spinner
} from "@cloudscape-design/components";
import React, { FC, useEffect, useState } from "react";
import { Controller, Resolver, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { QUERY_KEYS } from "../../../components/CommonSearch/api/constants";
import AutosuggestSelector from "../../../components/data-mapping/AutosuggestSelector";
import FormProvider from "../../../components/forms/FormProvider";
import { useCreateMedia } from "../api/hooks/useCreateMedia";
import { ID_TYPES, checkIdType } from "../utils/checkIdType";
import { MediaCard } from "./MediaCard";

type AddMediaModalprops = {
  visible: boolean;
  vertical: string;
  header: string;
  placeholder: string;
  onDismiss: () => void;
  autoCompletePlatform: string;
};

export const AddMediaModal: FC<AddMediaModalprops> = ({
  visible,
  vertical,
  header,
  placeholder,
  onDismiss,
  autoCompletePlatform,
}) => {
  const [showFlashbar, setShowFlashbar] = useState(false);
  const [showPoster, setShowPoster] = useState(false);
  const [items, setItems] = useState([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errorLink, setErrorLink] = useState<string | undefined>(undefined);
  const [errorLinkText, setErrorLinkText] = useState<string | undefined>(undefined);
  const [isAdded, setIsAdded] = useState(false);

  const queryClient = useQueryClient();

  const isInValidId = (id: string) => {
    if (vertical === "games") {
      return !(checkIdType(id) === ID_TYPES.IGDB);
    } else {
      return !(checkIdType(id) === ID_TYPES.IMDB);
    }
  };

  const resolver: Resolver<{ id: string }> = async (values) => {
    return {
      errors: {
        ...(!values.id
          ? { id: { type: "required", message: "ID is required" } }
          : isInValidId(values.id)
            ? { id: { type: "required", message: "Enter a valid ID format" } }
            : {}),
      },
      values,
    };
  };

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver,
  });

  const { control, formState, watch, reset, handleSubmit } = methods;

  const onSubmit = () => {
    try {
      createMedia(watch().id);
    } catch (e) { }
  };

  const closeModal = () => {
    setShowPoster(false);
    setShowFlashbar(false);
    setItems([]);
    setIsAdded(false);
    reset();
    onDismiss();
  };

  const getItems = (message: string = `${vertical} creation failed`) => [
    {
      type: "error",
      content: message,
      dismissible: true,
      dismissLabel: "Dismiss message",
      onDismiss: () => setItems([]),
      id: "message_1",
    },
  ];

  const getCallback = (message: string) => {
    const items = getItems(message);
    setItems(items);
    setShowFlashbar(true);

    setTimeout(() => {
      setShowFlashbar(false);
    }, 5000);
  };

  const onSuccess = () => {
    setErrorMessage(null);
    setIsAdded(true);
    queryClient.invalidateQueries({ queryKey: QUERY_KEYS.GET_TOP_SEARCH_DATA });
  };
  const onError = (error: { message: string; link?: string; linkText?: string; }) => {
    setErrorMessage(error.message);
    setErrorLink(error.link);
    setErrorLinkText(error.linkText);
    reset();
  };

  const {
    data: mediaData,
    mutate: createMedia,
    isLoading,
  } = useCreateMedia({
    vertical,
    onSuccess,
    onError,
  });

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (errorMessage) {
      timer = setTimeout(() => {
        setErrorMessage(null);
      }, 30000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [errorMessage]);

  return (
    <Modal onDismiss={closeModal} visible={visible} header={header}>
      <SpaceBetween direction="vertical" size="l">
        {isAdded ? (
          <>
            <MediaCard data={mediaData} />
            <Box float="right">
              <Button
                variant="primary"
                formAction="none"
                onClick={closeModal}
              >
                Dismiss
              </Button>
            </Box>
          </>
        ) : (
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <SpaceBetween direction="vertical" size="l">
              {showFlashbar && <Flashbar items={items} />}
              {errorMessage && (
                <Flashbar
                  items={[
                    {
                      type: "error",
                      content: errorMessage,
                      dismissible: true,
                      dismissLabel: "Dismiss message",
                      onDismiss: () => setErrorMessage(null),
                      id: "error_message",
                      action: errorLink && errorLinkText ? (
                        <Link href={errorLink} variant="secondary" external={true}>
                          <span className="text-white font-bold">{errorLinkText}</span>
                        </Link>
                      ) : undefined,
                    },
                  ]}
                />
              )}
              <Controller
                name={"id"}
                control={control}
                render={({ field }) => {
                  return (
                    <FormField
                      stretch={true}
                      errorText={formState.errors?.id?.message ?? ""}
                    >
                      <>
                        <AutosuggestSelector
                          value={field.value}
                          onValueSelected={(value) => field.onChange(value)}
                          platform={autoCompletePlatform}
                          placeholder={placeholder}
                          onPreview={null}
                        />
                      </>
                    </FormField>
                  );
                }}
              />
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button variant="link" formAction="none" onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    formAction="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <SpaceBetween direction="horizontal" size="xs">
                        <Spinner />
                        Adding...
                      </SpaceBetween>
                    ) : (
                      "Add"
                    )}
                  </Button>
                </SpaceBetween>
              </Box>
            </SpaceBetween>
          </FormProvider>
        )}
      </SpaceBetween>
    </Modal>
  );
};
