import { FilterControlType, FilterJoinOperation, FilterObject, FilterOperation, FilterType } from "../../../../../types/filterTypes";
import { Vertical } from "../../../../../types/verticalTypes";



export const DEFAULT_FILTER_OBJECTS: Record<string, FilterObject> = {
  [Vertical.Movies]: {
    category: Vertical.Movies,
    name: "Default movie filters",
    filters: [
      {
        label: "Title contains",
        field: "ip",
        filterType: FilterType.Text,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Text,
        },
      },
      {
        label: "Genre",
        field: "genre",
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: false,
          allowBlank: false,
          filterVertical: Vertical.Movies,
        },
      },
      { 
        label: "Franchise", 
        field: "franchise", 
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: true,
          allowBlank: true,
          filterVertical: Vertical.Movies,
        },
      },
      { 
        label: "Distributor", 
        field: "distributors", 
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: true,
          allowBlank: false,
          filterVertical: Vertical.Movies,
        },
      },
      { 
        label: "Production", 
        field: "production", 
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: true,
          allowBlank: false,
          filterVertical: Vertical.Movies,
        },
      },
    ],
  },
  [Vertical.Series]: {
    category: Vertical.Series,
    name: "Default series filters",
    filters: [
      {
        label: "Title contains",
        field: "ip",
        filterType: FilterType.Text,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Text,
        },
      },
      { 
        label: "Genre", 
        field: "genre", 
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: false,
          allowBlank: false,
          selectMultiple: true,
          filterVertical: Vertical.Series,
        },
      },
      { 
        label: "Franchise", 
        field: "franchise", 
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: true,
          allowBlank: false,
          selectMultiple: true,
          filterVertical: Vertical.Series,
        },
      },
      { 
        label: "Distributor", 
        field: "distributors", 
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: true,
          allowBlank: false,
          selectMultiple: true,
          filterVertical: Vertical.Series,
        },
      },
      { 
        label: "Production", 
        field: "production", 
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: true,
          allowBlank: false,
          selectMultiple: true,
          filterVertical: Vertical.Series,
        },
      },
    ],
  },
};