import { useEffect, useState } from "react";
import { useGetGenericPreference_V2 } from "../generic_v2/hooks/useGetGenericPreference_V2";
import { usePostGenericPreference_V2 } from "../generic_v2/hooks/usePostGenericPreference_V2";
import { usePutGenericPreference_V2 } from "../generic_v2/hooks/usePutGenericPreference_V2";
import { ApiURLParams } from "../generic_v2/requests"
import { invalidatePreferenceData } from "../generic_v2/utils/serviceUtils";
import { WeightObject } from "../../types/weightTypes";
import { convertWeightObjectsCompat } from "../../utils/weights/weightUtils";


interface UseWeightPreferencesProps {
  apiParams: ApiURLParams;
  defaultWeightObject: WeightObject;
}

export const useWeightPreferences = ({
  apiParams,
  defaultWeightObject,
}: UseWeightPreferencesProps) => {

  const [loadedWeights, setLoadedWeights] = useState<WeightObject[]>([]);
  const [loadedPrimaryWeights, setLoadedPrimaryWeights] = useState<WeightObject[]>([]);
  const [primaryWeight, setPrimaryWeight] = useState<WeightObject>(null);
  const [savePrefsPayload, setSavePrefsPayload] = useState({});


  const { data: rankingPreferenceData } = useGetGenericPreference_V2({
    apiParams,
  });
  const { mutate: updatePreference } = usePutGenericPreference_V2();
  const { mutate: postGenericPreference } = usePostGenericPreference_V2({
    apiParams,
    onSuccess: invalidatePreferenceData,
  });


  useEffect(() => {
    const pref = rankingPreferenceData?.[0]?.data;

    if (pref) {
      if (pref.weights) {
        // Compatibility with old weight format
        const convertedWeights = convertWeightObjectsCompat(pref.weights.filter(f => f != null));
        setLoadedWeights(convertedWeights);
      }
      if (pref.primaryWeight?.length > 0) {
        const convertedPrimaryWeights = convertWeightObjectsCompat(pref.primaryWeight.filter(f => f != null));
        const primaryWeight = convertedPrimaryWeights.find(f => f.category === defaultWeightObject.category) ?? null;
        setPrimaryWeight(primaryWeight);
        setLoadedPrimaryWeights(convertedPrimaryWeights);
      }
    }
  }, [rankingPreferenceData]);


  const savePreferences = (payload) => {
    const prefValue = rankingPreferenceData?.[0];

    if (Object.keys(prefValue?.data ?? {}).length > 0) {
      const { data } = prefValue;
      const finalPayload = { ...data, ...payload };

      updatePreference({ id: prefValue.id, payload: finalPayload });
      return;
    }

    postGenericPreference(payload);
  };

  const saveLoadedWeights = (weights: Array<WeightObject>) => {
    setSavePrefsPayload(prev => ({ ...prev, weights }));
    setLoadedWeights(weights);
  };

  const savePrimaryWeight = (weight: WeightObject) => {
    const newPrimaryWeights = Array.from(loadedPrimaryWeights.filter(f => f != null && f.category !== defaultWeightObject.category));
    if (weight) {
      newPrimaryWeights.push(weight);
    }
    setSavePrefsPayload(prev => ({ ...prev, primaryWeight: newPrimaryWeights }));
    setPrimaryWeight(weight);
    setLoadedPrimaryWeights(newPrimaryWeights);
  }


  useEffect(() => {
    if (Object.keys(savePrefsPayload).length > 0) {
      savePreferences(savePrefsPayload);
    }
  }, [savePrefsPayload]);


  return {
    loadedWeights,
    primaryWeight,
    saveLoadedWeights,
    savePrimaryWeight,
  };
};