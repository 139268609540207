import axiosInstance from "../../../utils/axios";

export const getTopSearchData = ({
  keyword="",
  fields=[],
  verticals=[],
}) => {
  if (!keyword || keyword?.length == 0) {
    return Promise.resolve({ data: null });
  }

  return axiosInstance.request({
    url: "/topsearch",
    method: "GET",
    params: {
      keyword,
      fields: fields.join(","),
      vertical: verticals.join(","),
    },
  });
};

export const getTopSearchDataV2 = ({
  q="",
  verticals=[],
  limit=20,
}) => {
  if (!q || q?.length == 0) {
    return Promise.resolve({ data: null });
  }

  return axiosInstance.request({
    url: "/topsearch/v2",
    method: "GET",
    params: {
      q,
      vertical: verticals.join(","),
      limit,
    },
  });
};
  