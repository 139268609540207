import { useEffect, useState } from "react";
import { useGetNewsRiverData } from "./useGetNewsRiverData";
import { useDeferredPromise } from "../../../../hooks/useDeferredPromise";


export const useGetNewsRiverDataPromise = () => {

  const [requestParams, setRequestParams] = useState({ skip: true });

  const { data, isLoading, error } = useGetNewsRiverData(requestParams);
  const { defer, deferRef } = useDeferredPromise();

  useEffect(() => {
    if (!isLoading && deferRef) {
      setRequestParams({ skip: true });
      if (error) {
        deferRef.reject(error);
      } else if (data) {
        deferRef.resolve(data);
      }
    }
  }, [data, isLoading, error]);

  const getNewsRiverData = async (params) => {
    setRequestParams(params);
    return defer().promise;
  };

  return { getNewsRiverData, isLoading, error };
};