import { useQuery } from "react-query";
import { YOUTUBE_TRENDS_QUERY_KEYS } from "../contants";
import { getYouTubeCategoryData } from "../request";

export const useGetYoutubeCategoryData = (startDate: number, endDate: number) => {
  return useQuery(
    [YOUTUBE_TRENDS_QUERY_KEYS.GET_YOUTUBE_CATEGORY_DATA, startDate, endDate],
    () => getYouTubeCategoryData(startDate, endDate),
    {
      enabled: !!startDate && !!endDate,
    }
  );
};
