import {
  Box,
  Container,
  Header,
  SpaceBetween,
  TextContent,
} from "@cloudscape-design/components";
import React from "react";
import CustomLoadingOverlay from "../../../../components/PantheonLoading";
import ReadMore from "../../../../components/ReadMore";
import { useGetItemWikipediaSummaryData } from "../../api/hooks/useGetItemWikipediaSummaryData";

function WikiSummaryBox({ id, summary, plotLong }) {
  const { data: wikipediaSummaryData, isLoading } = useGetItemWikipediaSummaryData(id);

  return (
    <>
      {isLoading ? (
        <Box textAlign="center" padding="l">
          <CustomLoadingOverlay />
        </Box>
      ) : (
        <SpaceBetween direction="vertical" size="xs">
          <Container header={<Header>Summary</Header>}>
            {wikipediaSummaryData?.summary ? (
              <TextContent>
                {wikipediaSummaryData.summary.split("\n").map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </TextContent>
            ) : (
              <div className="py-8 flex flex-1 flex-row justify-center items-center">
                <b>Summary not available</b>
              </div>
            )}
          </Container>
          <Container header={<Header>Short Description</Header>}>
            <ReadMore text={summary} maxLength={1000} />
          </Container>
          {plotLong && (
            <Container header={<Header>Plot</Header>}>
              <ReadMore text={plotLong} maxLength={5000} />
            </Container>
          )}
        </SpaceBetween>
      )}
    </>
  );
}

export default WikiSummaryBox;
