import { useEffect, useState } from "react";
import { useDeferredPromise } from "./useDeferredPromise";

export const useQueryPromise = (requestHook: any) => {

  const [requestParams, setRequestParams] = useState<any>({});

  const { data, isLoading, error } = requestHook(requestParams as any);
  const { defer, deferRef } = useDeferredPromise();

  useEffect(() => {
    if (!isLoading) {
      setRequestParams({});
      if (error) {
        deferRef.reject(error);
      } else if (data) {
        deferRef.resolve(data);
      }
    }
  }, [data, isLoading, error]);

  const getData = async (params: any) => {
    setRequestParams(params);
    return defer().promise;
  };

  return { getData, isLoading, error };
};