import {
  Box,
  Button,
  Header,
  Pagination,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { queryClient } from "../../../app/ReactQueryWrapper";
import { useAuthContext } from "../../../auth/useAuthContext";
import { CommonPreference } from "../../../components/CommonPreference";
import { TableFilter } from "../../../components/filters/TableFilter";
import { DEFAULT_FILTERING_QUERY } from "../../../config-global";
import { fetchGlobalPref } from "../../../modules/common/redux/global-pref-action";
import { usePostGenericPreference } from "../../../services/generic/hooks/usePostGenericPreference";
import {
  generateNestedObject,
  getNestedObjectValue,
} from "../../../utils/helpers";
import { isNone, isSome, isSomeOrElse } from "../../../utils/sugarUtils";
import CustomFlashBar from "../../common/CustomFlashBar";
import { YoutubeVideosContext } from "../Layout";
import { useUpdateVideos } from "../api/hooks/useUpdateVideos";
import { useFiltering } from "../hooks/useFiltering";
import { QUERY_KEYS } from "./api/constants";
import { useDeleteVideos } from "./api/hooks/useDeleteVideos";
import { useVideosListData } from "./api/hooks/useVideosListData";
import { DEFAULT_API_PARAMS } from "./api/request";
import { filteringProperties, getDefaultFilters } from "./filterConfig";
import {
  getColumnDefinitions,
  getVisibleContentPreference,
} from "./gridConfig";

const initialApiParams = { ...DEFAULT_API_PARAMS };

const getPathType = (type) => {
  let pathType;
  switch (type) {
    case "film":
      pathType = "movieTrailers";
      break;
    case "series":
      pathType = "tvShowTrailers";
      break;
    case "game":
      pathType = "gameTrailers";
      break;
    case "unmapped":
      pathType = "unmappedTrailers";
      break;
    case "unofficial":
      pathType = "unofficialTrailers";
      break;
    case "kids":
      pathType = "kidsTrailers";
      break;
    case "all":
      pathType = "allTrailers";
      break;
    default:
      pathType = type;
  }

  return pathType;
};

const defaultPreferences = {
  visibleContent: [
    "image",
    "title",
    "publishedAt",
    "views",
    "likes",
    "comments",
    "sentimentScore",
    "todayViews",
    "todayLikes",
    "todayComments",
    "lastUpdated",
  ],
};

export const VideosTable = ({ type, pageTitle }) => {
  const { user } = useAuthContext();
  const { modifiedData, setModifiedData, setAddMediaModalVisible, setAddMediaModalProps } = useContext(YoutubeVideosContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortingColumn, setSortingColumn] = useState(null);
  const [sortingDescending, setSortingDescending] = useState(false);
  const [filteringQuery, setFilteringQuery] = useState(DEFAULT_FILTERING_QUERY);
  const [apiParams, setApiParams] = useState({ ...initialApiParams });
  const [apiEnabled, setApiEnabled] = useState(false);
  const [count, setCount] = useState(0);
  const [preferences, setPreferences] = useState(defaultPreferences);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const pathType = getPathType(type);
  const PREFERENCE_PATH = `mediaCatalog.youtube.${pathType}.preferences`;
  const FILTERS_PATH = `mediaCatalog.youtube.${pathType}.filters`;

  useEffect(() => {
    (async () => {
      await fetchGlobalPref(user.username);
    })();
  }, [pathType, user.username]);

  const globalPref = useSelector(
    (state) => state?.globalPersonalPref?.preferences,
  );
  const hasApiCompleted = useSelector(
    (state) => state?.globalPersonalPref?.hasApiCompleted,
  );

  useEffect(() => {
    const pref = getNestedObjectValue(globalPref, PREFERENCE_PATH);

    setPreferences(isSome(pref) ? pref : defaultPreferences);
    if (hasApiCompleted) setApiEnabled(true);
  }, [globalPref, hasApiCompleted, PREFERENCE_PATH]);

  useFiltering({
    currentPage,
    preferences,
    initialApiParams,
    filteringQuery,
    sortingColumn,
    sortingDescending,
    setApiParams,
    setCurrentPage,
  });

  const { mutate: postGenericPreference } = usePostGenericPreference();
  const { mutateAsync: updateVideos, isLoading: isUpdatingVideos } =
    useUpdateVideos();
  const { mutateAsync: deleteVideos, isLoading: isDeletingVideos } =
    useDeleteVideos();
  const { data: videosListData, isLoading } = useVideosListData(
    {
      ...apiParams,
      size: preferences?.pageSize || apiParams.size,
      contentType: type,
    },
    apiEnabled,
  );

  useEffect(() => {
    if (videosListData?.count) setCount(videosListData.count);
  }, [videosListData, count]);

  const handlePreferenceChange = ({ detail }) => {
    const newGlobalPreferences = generateNestedObject(
      { ...globalPref },
      PREFERENCE_PATH,
      detail,
    );

    postGenericPreference(newGlobalPreferences);
  };

  const getPaginationCount = (count, preferences) =>
    Math.ceil(
      (count > 10000 ? 10000 : count) /
        isSomeOrElse(preferences?.pageSize, DEFAULT_API_PARAMS.size),
    );

  const updateSelectedRows = () => {
    const promises = Object.keys(modifiedData).map((key) =>
      updateVideos({
        videoId: key,
        payload: { ip: modifiedData[key].label, ip_id: modifiedData[key].value },
      }),
    );

    Promise.all(promises).then(() => {
      const queryKey = [
        QUERY_KEYS.GET_VIDEOS_LIST_DATA,
        apiParams.from,
        apiParams.size,
        apiParams.include,
        apiParams.exclude,
        apiParams.sort,
        apiParams.condition,
        type,
      ];
      queryClient.invalidateQueries(queryKey);
      clearSelectedRows();
    });
  };

  const clearSelectedRows = () => setModifiedData({});

  const deleteSelectedRows = () => {
    const videoIds = selectedItems.map((item) => item.videoId);

    deleteVideos(
      { payload: { videoIds } },
      {
        onSuccess: () => {
          setSelectedItems([]);
          queryClient.invalidateQueries([
            QUERY_KEYS.GET_VIDEOS_LIST_DATA,
            apiParams.from,
            apiParams.size,
            apiParams.include,
            apiParams.exclude,
            apiParams.sort,
            apiParams.condition,
            type,
          ]);
        },
      },
    );
  };

  const getPreferencesAfterDelete = (name) => {
    const filterPreference = isSomeOrElse(
      getNestedObjectValue(globalPref, FILTERS_PATH),
      {},
    );
    const currentFilters = { ...filterPreference };

    if (isNone(currentFilters[name])) return;

    delete currentFilters[name];

    return getExistingPreferences(currentFilters);
  };

  const getPreferencesAfterSaveOrUpdate = (name, prevName, isUpdate) => {
    const filterPreference = isSomeOrElse(
      getNestedObjectValue(globalPref, FILTERS_PATH),
      {},
    );
    const currentFilters = { ...filterPreference };
    currentFilters[name] = { ...filteringQuery };

    if (isUpdate) delete currentFilters[prevName];

    return getExistingPreferences(currentFilters);
  };

  const getExistingPreferences = (currentFilters) => {
    const newGlobalPreferences = generateNestedObject(
      { ...globalPref },
      FILTERS_PATH,
      currentFilters,
    );

    return newGlobalPreferences;
  };

  const multiselectProps =
    type === "unmapped"
      ? {
          selectionType: "multi",
          selectedItems: selectedItems,
          onSelectionChange: ({ detail }) =>
            setSelectedItems(detail.selectedItems),
        }
      : {};

  return (
    <>
      <Table
        columnDefinitions={getColumnDefinitions(type, setAddMediaModalVisible, setAddMediaModalProps)}
        sortingColumn={sortingColumn}
        sortingDescending={sortingDescending}
        columnDisplay={preferences.contentDisplay}
        onSortingChange={(event) => {
          setSortingDescending(event.detail.isDescending);
          setSortingColumn(event.detail.sortingColumn);
        }}
        trackBy="videoId"
        loading={isLoading}
        items={videosListData?.data}
        loadingText="Loading resources"
        stickyHeader
        resizableColumns={true}
        variant="full-page"
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
        contentDensity={preferences.contentDensity}
        stickyColumns={preferences.stickyColumns}
        empty={
          <Box textAlign="center" color="inherit">
            <b>No items</b>
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
              No items to display.
            </Box>
          </Box>
        }
        error={
          <Box textAlign="center" color="inherit">
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
              Error displaying the data.
            </Box>
          </Box>
        }
        header={
          <SpaceBetween direction="vertical" size="xs">
            <CustomFlashBar />
            <Header
              variant="h3"
              counter={<span>({videosListData?.count?.toLocaleString()})</span>}
              actions={
                <>
                  {Object.keys(selectedItems).length > 0 && (
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={deleteSelectedRows}
                        loading={isDeletingVideos}
                      >
                        Delete Selected
                      </Button>
                    </SpaceBetween>
                  )}
                  {Object.keys(modifiedData).length > 0 && (
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={updateSelectedRows}
                        loading={isUpdatingVideos}
                      >
                        Update Selected
                      </Button>
                      <Button
                        onClick={clearSelectedRows}
                        loading={isUpdatingVideos}
                      >
                        Clear Selected
                      </Button>
                    </SpaceBetween>
                  )}
                </>
              }
            >
              {pageTitle}
            </Header>
          </SpaceBetween>
        }
        preferences={
          <CommonPreference
            preferences={preferences}
            onConfirm={handlePreferenceChange}
            contentDisplayOptions={getVisibleContentPreference(type)}
          />
        }
        pagination={
          <Pagination
            currentPageIndex={currentPage}
            pagesCount={getPaginationCount(count, preferences)}
            onChange={(page) => {
              setCurrentPage(page.detail.currentPageIndex);
            }}
          />
        }
        filter={
          <TableFilter
            defaultFilters={getDefaultFilters(type)}
            filteringQuery={filteringQuery}
            filteringProperties={filteringProperties}
            setFilteringQuery={setFilteringQuery}
            getPreferencesAfterSaveOrUpdate={getPreferencesAfterSaveOrUpdate}
            getPreferencesAfterDelete={getPreferencesAfterDelete}
            filtersPath={FILTERS_PATH}
          />
        }
        {...multiselectProps}
      />
    </>
  );
};
