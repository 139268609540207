import { PLATFORMS } from "../../../config-global";
import { FilterControlType, FilterJoinOperation, FilterObject, FilterOperation, FilterType } from "../../../types/filterTypes";
import { Vertical } from "../../../types/verticalTypes";
import { WeightObject } from "../../../types/weightTypes";

export const DEFAULT_WEIGHTS_OBJECTS: Record<string, WeightObject> = {
  [Vertical.Movies]: {
    category: Vertical.Movies,
    name: "Default movie weights",
    weights: [
      {
        label: PLATFORMS.WIKIPEDIA.name,
        field: PLATFORMS.WIKIPEDIA.key,
        weight: 3.0,
        enabled: true,
      },
      {
        label: PLATFORMS.YOUTUBE.name,
        field: PLATFORMS.YOUTUBE.key,
        weight: 2.0,
        enabled: true,
      },
      {
        label: PLATFORMS.IMDB.name,
        field: PLATFORMS.IMDB.key,
        weight: 1.0,
        enabled: true,
      },
      {
        label: PLATFORMS.PIRACY.name,
        field: PLATFORMS.PIRACY.key,
        weight: 2.5,
        enabled: true,
      },
      {
        label: PLATFORMS.ROTTEN_TOMATOES.name,
        field: PLATFORMS.ROTTEN_TOMATOES.key,
        weight: 1.0,
        enabled: true,
      },
    ],
  },
  [Vertical.Series]: {
    category: Vertical.Series,
    name: "Default series weights",
    weights: [
      {
        label: PLATFORMS.WIKIPEDIA.name,
        field: PLATFORMS.WIKIPEDIA.key,
        weight: 3.0,
        enabled: true,
      },
      {
        label: PLATFORMS.YOUTUBE.name,
        field: PLATFORMS.YOUTUBE.key,
        weight: 2.0,
        enabled: true,
      },
      {
        label: PLATFORMS.IMDB.name,
        field: PLATFORMS.IMDB.key,
        weight: 1.0,
        enabled: true,
      },
      {
        label: PLATFORMS.PIRACY.name,
        field: PLATFORMS.PIRACY.key,
        weight: 2.5,
        enabled: true,
      },
      {
        label: PLATFORMS.ROTTEN_TOMATOES.name,
        field: PLATFORMS.ROTTEN_TOMATOES.key,
        weight: 1.0,
        enabled: true,
      },
    ],
  },
  [Vertical.Games]: {
    category: Vertical.Games,
    name: "Default game weights",
    weights: [
      {
        label: PLATFORMS.WIKIPEDIA.name,
        field: PLATFORMS.WIKIPEDIA.key,
        weight: 2.0,
        enabled: true,
      },
      {
        label: PLATFORMS.YOUTUBE.name,
        field: PLATFORMS.YOUTUBE.key,
        weight: 1.0,
        enabled: true,
      },
      {
        label: PLATFORMS.STEAM.name,
        field: PLATFORMS.STEAM.key,
        weight: 3.5,
        enabled: true,
      },
      {
        label: PLATFORMS.TWITCH.name,
        field: PLATFORMS.TWITCH.key,
        weight: 3.5,
        enabled: true,
      },
    ],
  },
};

export const DEFAULT_FILTER_OBJECTS: Record<string, FilterObject> = {
  [Vertical.Movies]: {
    category: Vertical.Movies,
    name: "Default movie filters",
    filters: [
      { 
        label: "Title contains", 
        field: "ip", 
        filterType: FilterType.Text,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Text,
        },
      },
      {
        label: "Genre",
        field: "genre",
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: false,
          allowBlank: false,
          selectMultiple: true,
          filterVertical: Vertical.Movies,
        },
      },
      { 
        label: "Franchise", 
        field: "franchise", 
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: true,
          allowBlank: true,
          selectMultiple: true,
          filterVertical: Vertical.Movies,
        },
      },
      { 
        label: "Provider", 
        field: "providers", 
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: true,
          allowBlank: true,
          selectMultiple: true,
          filterVertical: Vertical.Movies,
        },
      },
      { 
        label: "Distributor", 
        field: "distributors", 
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: true,
          allowBlank: false,
          selectMultiple: true,
          filterVertical: Vertical.Movies,
        },
      },
      { 
        label: "Production", 
        field: "production", 
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: true,
          allowBlank: false,
          selectMultiple: true,
          filterVertical: Vertical.Movies,
        },
      },
      { 
        label: "Release date", 
        field: "release_date", 
        filterType: FilterType.Date, 
        filterOperation: FilterOperation.LessThan,
        valueJoinOperation: FilterJoinOperation.And,
        controlOptions: {
          type: FilterControlType.Date,
        },
      },
    ],
  },
  [Vertical.Series]: {
    category: Vertical.Series,
    name: "Default series filters",
    filters: [
      { 
        label: "Title contains", 
        field: "ip", 
        filterType: FilterType.Text,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Text,
        },
      },
      { 
        label: "Genre", 
        field: "genre", 
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: false,
          allowBlank: false,
          selectMultiple: true,
          filterVertical: Vertical.Series,
        },
      },
      { 
        label: "Franchise", 
        field: "franchise", 
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: true,
          allowBlank: true,
          selectMultiple: true,
          filterVertical: Vertical.Series,
        },
      },
      { 
        label: "Provider", 
        field: "providers", 
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: true,
          allowBlank: true,
          selectMultiple: true,
          filterVertical: Vertical.Series,
        },
      },
      { 
        label: "Distributor", 
        field: "distributors", 
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: true,
          allowBlank: false,
          selectMultiple: true,
          filterVertical: Vertical.Series,
        },
      },
      { 
        label: "Production", 
        field: "production", 
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: true,
          allowBlank: false,
          selectMultiple: true,
          filterVertical: Vertical.Series,
        },
      },
      { 
        label: "Release date", 
        field: "release_date", 
        filterType: FilterType.Date,
        filterOperation: FilterOperation.LessThan,
        valueJoinOperation: FilterJoinOperation.And,
        controlOptions: {
          type: FilterControlType.Date,
        },
      },
    ],
  },
  [Vertical.Games]: {
    category: Vertical.Games,
    name: "Default game filters",
    filters: [
      { 
        label: "Title contains", 
        field: "ip", 
        filterType: FilterType.Text,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Text,
        },
      },
      { 
        label: "Genre", 
        field: "genre", 
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: false,
          allowBlank: false,
          selectMultiple: true,
          filterVertical: Vertical.Games,
        },
      },
      { 
        label: "Franchise", 
        field: "franchise", 
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: true,
          allowBlank: true,
          selectMultiple: true,
          filterVertical: Vertical.Games,
        },
      },
      { 
        label: "Platform", 
        field: "platform", 
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: true,
          allowBlank: false,
          selectMultiple: true,
          filterVertical: Vertical.Games,
        },
      },
      { 
        label: "Developer", 
        field: "developer", 
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: true,
          allowBlank: false,
          selectMultiple: true,
          filterVertical: Vertical.Games,
        },
      },
      { 
        label: "Publisher", 
        field: "publisher", 
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: true,
          allowBlank: false,
          selectMultiple: true,
          filterVertical: Vertical.Games,
        },
      },
      { 
        label: "Game Mode", 
        field: "game_modes", 
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: false,
          allowBlank: false,
          selectMultiple: true,
          filterVertical: Vertical.Games,
        },
      },
      { 
        label: "Player Perspective", 
        field: "player_perspectives", 
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: false,
          allowBlank: false,
          selectMultiple: true,
          filterVertical: Vertical.Games,
        },
      },
      { 
        label: "Theme", 
        field: "themes", 
        filterType: FilterType.Object,
        filterOperation: FilterOperation.Contains,
        valueJoinOperation: FilterJoinOperation.Or,
        controlOptions: {
          type: FilterControlType.Set,
          autoComplete: false,
          allowBlank: false,
          selectMultiple: true,
          filterVertical: Vertical.Games,
        },
      },
      { 
        label: "Release date", 
        field: "release_date", 
        filterType: FilterType.Date,
        filterOperation: FilterOperation.LessThan,
        valueJoinOperation: FilterJoinOperation.And,
        controlOptions: {
          type: FilterControlType.Date,
        },
      },
    ],
  },
};

export const PLATFORM_INDEX_MAP = {
  [PLATFORMS.WIKIPEDIA.key]: "wiki",
  [PLATFORMS.YOUTUBE.key]: "youtube",
  [PLATFORMS.IMDB.key]: "imdb",
  [PLATFORMS.PIRACY.key]: "piracy",
  [PLATFORMS.ROTTEN_TOMATOES.key]: "rt",
  [PLATFORMS.STEAM.key]: "steam",
  [PLATFORMS.TWITCH.key]: "twitch",
};