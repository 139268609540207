import axiosInstance from "../../../../utils/axios";
import {
  TalentResponse
} from "./types";


  export const getTalentData = async (
    id: string,
  ): Promise<TalentResponse> => {
    try {
      const response = await axiosInstance.get(
        `/talent/v1/${id}`,
      );
      const data = response.data;
      return data as TalentResponse;
    } catch (error) {
      console.error("Error fetching talent data:", error);
      throw error;
    }
  };
