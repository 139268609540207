import { Vertical } from "./verticalTypes";

export enum FilterType {
  Object = "object",
  Text = "text",
  Number = "number",
  Date = "date",
  DateString = "dateString",
  Boolean = "boolean",
  Join = "join",
};

export enum FilterOperation {
  Contains = "contains",
  NotContains = "notContains",
  Equals = "equals",
  NotEqual = "notEqual",
  StartsWith = "startsWith",
  EndsWith = "endsWith",
  Blank = "blank",
  NotBlank = "notBlank",
  GreaterThan = "greaterThan",
  GreaterThanOrEqual = "greaterThanOrEqual",
  LessThan = "lessThan",
  LessThanOrEqual = "lessThanOrEqual",
  True = "true",
  False = "false",
};

export enum FilterJoinOperation {
  And = "AND",
  Or = "OR",
};

export enum FilterControlType {
  Set = "set",
  Date = "date",
  Text = "text",
}

export type FilterControlOptions = {
  type: FilterControlType;
  autoComplete?: boolean;
  allowBlank?: boolean;
  setOptions?: Array<{ label: string; value: any }>;
  filterVertical?: Vertical;
  selectMultiple?: boolean;
};


export type Filter = {
  label: string;
  field: string;
  value?: any;
  filterType: FilterType;
  filterOperation: FilterOperation;
  valueJoinOperation: FilterJoinOperation;
  controlOptions: FilterControlOptions;
  group?: string;
  [x: string | number | symbol]: unknown;
};

export type FilterObject = {
  category: string;
  name: string;
  filters: Array<Filter>;
  advancedFilter?: any;
};